import moment from 'moment';
import { ActiveFilter } from "src/components/Filters";

export const toQuery = (filters: ActiveFilter[]) => {
  let params: any[] = [];
  const includes = new Map<string, string>();

  filters.forEach(filter => {
    if (filter.option.type === 'dateRange' || filter.option.type === 'date') {
      const fromDate = encodeURI(moment(filter.value[0]).format('YYYY-MM-DD'));
      const toDate = encodeURI(moment(filter.value[1]).format('YYYY-MM-DD'));
      params.push(`${filter.option.column}${filter.operator.id}=${fromDate},${toDate}`);
      return;
    }
    if (filter.option.type === 'list' && filter.operator.id === '__in') {
      const key = `${filter.option.id}${filter.operator.id}`;
      const values = includes.get(key)?.split(',') || [];
      values.push(filter.value as string);
      includes.set(key, values.join(','));
      return;
    }
    params.push(`${filter.option.id}${filter.operator.id}=${encodeURI(filter.value as string)}`);
  });

  Array.from(includes)
    .map(([key, value]) => params.push(`${key}=${value}`));

  return [...params].join('&');
};
