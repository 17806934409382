import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { createBrowserHistory } from "history";
import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";
import HttpClient, { HttpClientContext } from "./components/HttpClient";

import "./index.css";

createBrowserHistory();

const store = configureStore({});

const firebase = new Firebase(store);

const httpClient = new HttpClient(firebase);

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={firebase}>
      <HttpClientContext.Provider value={httpClient}>
        <App />
      </HttpClientContext.Provider>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root")
);
