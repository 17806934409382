import React, { useCallback, useState, FC, ReactElement, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import { Typography } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReportsApi from '../../api/Reports';
import _ from "lodash";

interface ILeadsClosingRatio {
  httpClient: any;
  metadata: any;
  title: string;
}

export const LeadsClosingRatio: FC<ILeadsClosingRatio> = ({ httpClient, metadata, title }): ReactElement => {
  const reports = httpClient.reports as ReportsApi;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const loadReports = useCallback(async () => {
    if (metadata?.users) {
      const apps = await reports.appsCurrentMonth();
      const leads = await reports.leadsCurrentMonth();
      console.log('apps', apps);
      console.log('leads', leads);
      setData(calculateRatio(apps, leads));
      setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]); 

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  const isSentToApi = (status: string): boolean => {
    return status.toLowerCase() === "sent to api";
  }

  const calculateRatio = (apps: any, leads: any): any => {
    const groupedApps = _.groupBy(apps, e => e.uid);
    console.log('groupedApps', groupedApps);
    return leads.reduce((prev: any, current: any) => {
      const user = metadata.users[current.uid];
      console.log('user', user);
      if (!current.uid) {
        return prev;
      }
      const app = groupedApps[user.id.toString()];
      console.log('app', app);
      if (!app) {
        return [
          ...prev,
          {
            user,
            submitted: 0,
            count: current.count,
            ratio: 0,
            closedRatio: 0
          }
        ];
      }
      const calculated = app.reduce(
        (p, c) => {
          if (isSentToApi(c.status)) {
            p.submitted += c.count;
          }
          return p;
        },
        { user, submitted: 0, count: current.count }
      );
      calculated.closedRatio = (
        (calculated.submitted / current.count) *
        100
      ).toFixed(2);
      return [...prev, calculated];
    }, []);
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="overline">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rep Name</TableCell>
              <TableCell># of Leads</TableCell>
              <TableCell>Submitted</TableCell>
              <TableCell>Closing Ratio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    <Avatar
                      alt={`${row.user.firstName} ${row.user.lastName}`}
                      src={row.user.photoURL}
                    />
                  </TableCell>
                  <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.submitted}</TableCell>
                  <TableCell>{`${row.closedRatio}%`}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}

export default compose<ILeadsClosingRatio, Element>(
  withHttpClient,
  connect((state: any) => ({
    metadata: state.metadata
  }))
)(LeadsClosingRatio);
