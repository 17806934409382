const styles = theme => ({
  root: {},
  titleBar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    borderTop: "1px solid rgba(0,0,0,0.13)",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    padding: theme.spacing(1)
  },
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 608px) !important"
  },
  toolbar: {
    paddingBottom: theme.spacing(1)
  },
  search: {
    display: "flex",
    flexDirection: "row"
  },
  filterDisposition: {
    width: 200,
    marginRight: theme.spacing(1)
  },
  filterReset: {
    marginRight: theme.spacing(1)
  },
  toggleButtonGroup: {
    marginRight: theme.spacing(1)
  },
  toggleButton: {
    height: 56
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  removeLink: {
    marginRight: theme.spacing(2)
  }
});

export default styles;
