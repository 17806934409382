import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FilterNoneIcon from "@material-ui/icons/FilterNone";

class ETableHead extends PureComponent {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  createCopyHandler = property => event => {
    this.props.onCopyButtonClick(event, property);
  };

  render() {
    const { columns, order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            if (!column.visible) {
              return null;
            }
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                    disabled={!column.sortable}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
                {column.showCopyButton && (
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<FilterNoneIcon />}
                    onClick={this.createCopyHandler(column.id)}
                  >
                    Copy
                  </Button>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

ETableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default ETableHead;
