export default class ReportsApi {
  constructor(axios) {
    this.axios = axios;
  }
  ratiosCurrentMonth = async () => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/ratios-current-month`
    );
    return response.data.results;
  };
  ratiosSelectedMonth = async (year, month) => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/ratios-selected-month?year=${year}&month=${month}`
    );
    return response.data.results;
  };
  appsByMonth = async () => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/apps-by-status`
    );
    return response.data.results;
  };
  appsCurrentMonth = async filter => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/apps-current-month`
    );
    return response.data.results;
  };
  appsByFilter = async query => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/apps-by-filter?${query}`
    );
    return response.data.results;
  };
  leadsCurrentMonth = async () => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/leads-current-month`
    );
    return response.data.results;
  };
  leadsCurrentYear = async () => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/leads-current-year`
    );
    return response.data.results;
  };
  leadsByFilter = async query => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/reports/leads-by-filter?${query}`
    );
    return response.data.results;
  };
}
