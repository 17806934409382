import React, { useState, FC, ReactElement, useEffect } from 'react';
import { ClassNameMap } from "@material-ui/styles";
import { Paper, IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { httpsCallable } from "@firebase/functions";

interface ISourcePanel {
    classes: ClassNameMap<any>;
    firebase: any;
    source: any;
    sources: any;
}

export const SourcePanel: FC<ISourcePanel> = ({ classes, firebase, source, sources }): ReactElement => {
    const [name, setName] = useState<string>('');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        setName(source.name);
        setIsEdit(false);
    }, [source]);

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({});
        set(event.target.value);
    }

    const handleEditClick = () => {
        setIsEdit(true);
    }

    const handleCancelClick = () => {
        setErrors({});
        setIsEdit(false);
    }

    const handleSaveClick = async () => {
        const alreadyExist = sources.find((s: any) => s.name === name && s.id !== source.id);

        if (alreadyExist) {
            setErrors({ name: 'Such name already exists!'});
            return;
        }

        try {
            await httpsCallable(firebase.functions, "updateSource")({ id: source.id, name, active: true });
        } catch (error) {
            console.error(error);
            return;
        }

        setIsEdit(false);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.sticky}>
                <div className={classes.panel}>
                    <div className={classes.button}>
                        {isEdit ? (
                            <div>
                                <IconButton onClick={handleSaveClick}>
                                    <SaveIcon />
                                </IconButton>
                                <IconButton onClick={handleCancelClick}>
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        ) : (
                            <IconButton onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        id="name"
                        name="name"
                        label="Name"
                        value={name}  
                        onChange={handleTextChange(setName)}
                        fullWidth
                        disabled={!isEdit}
                        helperText={errors.name}
                        error={Boolean(errors.name)}     
                    />
                </div>       
            </div>
        </Paper>
    );
}
