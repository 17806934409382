import React, { Component } from "react";
import classNames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import { withLayout } from "../Layout";
import { Typography, Button, Popover, Grid } from "@material-ui/core";
import {
  Scheduler,
  DayView,
  Appointments
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import LeadPanel from "../LeadPanel";
import _ from "lodash";
import styles from "./styles";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  setDoc,
  writeBatch
} from "@firebase/firestore";

const AppointmentCell = (event, metadata, onClick) => ({ ...restProps }) => {
  const selected = event ? event.id === restProps.data.id : false;
  return (
    <Appointments.Appointment
      style={{
        backgroundColor: selected
          ? null
          : metadata.dispositions[restProps.data.lead.disposition]
              .backgroundColor,
        color: metadata.dispositions[restProps.data.lead.disposition].color
      }}
      {...restProps}
      onClick={onClick}
    />
  );
};

const AppointmentContent = (event, metadata) => ({ ...restProps }) => {
  const selected = event ? event.id === restProps.data.id : false;
  return (
    <Appointments.AppointmentContent
      style={{
        color: selected
          ? null
          : metadata.dispositions[restProps.data.lead.disposition].color
      }}
      {...restProps}
    />
  );
};

class CalendarPage extends Component {
  state = {
    events: [],
    event: null,
    date: new Date(),
    anchorEl: null,
    errors: {}
  };

  componentDidMount = async () => {
    this.registerEventsSnapshotListener();
  };

  componentWillUnmount = () => {
    this.unregisterListeners();
  };

  registerEventsSnapshotListener = () => {
    const { date } = this.state;
    const firestore = this.props.firebase.firestore;
  
    console.log("Registering events snapshot listener");
    console.log("Current date state:", date);
  
    let q = query(
      collection(firestore, "events"),
      where("uid", "==", this.props.user.uid)
    );
  
    console.log("Initial query:", q);
  
    if (date) {
      const start = moment(date).startOf("day");
      const end = moment(date).endOf("day");
  
      console.log("Filtering events between:", start.toDate(), "and", end.toDate());
  
      q = query(
        q,
        where("scheduledAt", ">", start.toDate()),
        where("scheduledAt", "<", end.toDate())
      );
  
      console.log("Updated query with date filters:", q);
    }
  
    this.unregisterEventsSnapshotListener = onSnapshot(q, async (snapshot) => {
      console.log("Snapshot received, number of documents:", snapshot.size);
  
      if (snapshot.empty) {
        console.log("No events found for the current query.");
        this.setState({ events: [] });
        return;
      }
  
      const firestore = this.props.firebase.firestore;
      const events = await Promise.all(
        snapshot.docs.map(async (e) => {
          const data = e.data();
          console.log("Event data:", data);
  
          const leadRef = doc(firestore, "leads", data.leadId);
          console.log("Fetching lead data for leadId:", data.leadId, "with reference:", leadRef);
  
          const leadSnapshot = await getDoc(leadRef);
  
          if (!leadSnapshot.exists()) {
            console.error("Lead not found for leadId:", data.leadId);
            return null;
          }
  
          console.log("Lead snapshot received for leadId:", data.leadId);
          const lead = leadSnapshot.data();
          console.log("Lead data:", lead);
  
          const titleText = `DBA: ${lead.dba}, Name: ${lead.name}, Phone: ${lead.phone}`;
  
          return {
            id: e.id,
            startDate: data.scheduledAt.toDate(),
            endDate: data.scheduledAt.toDate(),
            lead: {
              id: leadSnapshot.id,
              ...lead
            },
            ...data,
            title: data.title ? `${data.title} - ${titleText}` : titleText
          };
        })
      );
  
      const validEvents = events.filter(event => event !== null);
      console.log("Final events array:", validEvents);
  
      this.setState({ events: validEvents });
    });
  };
  

  unregisterListeners = () => {
    if (typeof this.unregisterEventsSnapshotListener === "function") {
      this.unregisterEventsSnapshotListener();
    }
  };

  handleEventClick = event => {
    const selected = this.state.event
      ? this.state.event.id === event.data.id
      : false;
    this.setState({ event: selected ? null : event.data });
  };

  handleMoveClick = group => date => {
    group.times.forEach(event => {
      const firestore = this.props.firebase.firestore;
      const scheduledAt = moment(event.scheduledAt.toDate());
      scheduledAt.set({
        date: date.get("date"),
        month: date.get("month"),
        year: date.get("year")
      });
      setDoc(
        doc(firestore, "events", event.id),
        { scheduledAt: scheduledAt.toDate() },
        { merge: true }
      );
    });
  };

  handleEventToggle = event => (e, checked) => {
    setDoc(
      doc(this.props.firebase.firestore, "events", event.id),
      { complete: checked },
      { merge: true }
    );
  };

  handleLeadUpdate = event => lead => {
    const { events } = this.state;
    var index = _.findIndex(events, { id: event.id });
    events.splice(index, 1, { ...event, lead: lead });
    this.setState({ events });
  };

  handleNextClick = () => {
    this.unregisterListeners();
    this.setState(
      { date: moment(this.state.date || new Date()).add(1, "days") },
      () => {
        this.registerEventsSnapshotListener();
      }
    );
  };

  handleBackClick = () => {
    this.unregisterListeners();
    this.setState(
      { date: moment(this.state.date || new Date()).subtract(1, "days") },
      () => {
        this.registerEventsSnapshotListener();
      }
    );
  };

  handleTodayClick = () => {
    this.unregisterListeners();
    this.setState({ date: new Date() }, () => {
      this.registerEventsSnapshotListener();
    });
  };

  handlePopoverClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleRescheduleClick = async () => {
    const { rescheduleDate, events } = this.state;
    const firestore = this.props.firebase.firestore;
    const batch = writeBatch(firestore);
    for (const event of events) {
      let scheduledAt = moment(event.scheduledAt.toDate())
        .year(rescheduleDate.year())
        .month(rescheduleDate.month())
        .date(rescheduleDate.date());
      const eventRef = doc(firestore, "events", event.id);
      batch.update(eventRef, { scheduledAt: scheduledAt.toDate() });
    }
    await batch.commit();
    this.unregisterListeners();
    this.setState({ date: rescheduleDate, anchorEl: null }, () => {
      this.registerEventsSnapshotListener();
    });
  };

  handleDateChange = date => {
    this.setState({ rescheduleDate: date });
  };

  render() {
    const { metadata, classes } = this.props;
    const {
      date,
      event,
      events,
      anchorEl,
      rescheduleDate,
      errors
    } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.root}>
          <div className={classes.page}>
            <div className={classes.titleBar}>
              <Typography variant="h4">Calendar</Typography>
            </div>
            <div className={classes.container}>
              <div
                className={classNames(classes.calendarContainer, {
                  [classes.calendarContainerZoom]: Boolean(event)
                })}
              >
                <div className={classes.actions}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    className={classes.button}
                    onClick={this.handleBackClick}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleTodayClick}
                  >
                    Today
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    endIcon={<ChevronRightIcon />}
                    className={classes.button}
                    onClick={this.handleNextClick}
                  >
                    Next
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.button}
                    onClick={this.handlePopoverClick}
                  >
                    Reschedule Day
                  </Button>
                  <Popover
                    id={id}
                    className={classes.popover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <div className={classes.popoverContent}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            margin="normal"
                            id="rescheduleDate"
                            name="rescheduleDate"
                            label="Date to Reschedule Events"
                            format="MM/DD/YYYY"
                            value={rescheduleDate}
                            onChange={this.handleDateChange}
                            error={!!errors.rescheduleDate}
                            helperText={errors.rescheduleDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={this.handleRescheduleClick}
                            fullWidth
                            disabled={!Boolean(rescheduleDate)}
                          >
                            Reschedule All Events
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Popover>
                </div>

                <Scheduler data={events}>
                  <ViewState currentDate={moment(date).format("MM-DD-YYYY")} />
                  <DayView startDayHour={7} endDayHour={17} />
                  <Appointments
                    appointmentComponent={AppointmentCell(
                      event,
                      metadata,
                      this.handleEventClick
                    )}
                    appointmentContentComponent={AppointmentContent(
                      event,
                      metadata
                    )}
                  />
                </Scheduler>

                {/* <Events
                date={date}
                events={events}
                onSelectEvent={this.handleEventClick}
                onEventToggle={this.handleEventToggle}
                onMoveClick={this.handleMoveClick}
                metadata={metadata}
             />*/}
              </div>
              {event && (
                <LeadPanel
                  lead={event.lead}
                  onLeadUpdate={this.handleLeadUpdate(event)}
                />
              )}
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default compose(
  withLayout,
  withStyles(styles),
  connect(state => ({
    user: state.user,
    metadata: state.metadata
  })),
  withFirebase
)(CalendarPage);
