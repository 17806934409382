const mapping = [
    {
        AppValue: 'dateOfBirth',
        Regex: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/g
    },
    {
        AppValue: 'doingBusinessAs',
        Regex: /^[\s!@#$~&*\.,;\(\)\-=\+\/:"'\?(a-zA-Z0-9]+$/g
    },
    {
        AppValue: 'federalTaxId',
        Regex: /^\d{9}$|^\d{2}\-\d{7}$/g
    },
    {
        AppValue: 'firstName',
        Regex: /^[A-Za-z]+[A-Za-z'\.\-\s]*$/g
    },
    {
        AppValue: 'city',
        Regex: /^[A-Za-z]+[A-Za-z'\.\-\s]*$/g
    },
    {
        AppValue: 'phone',
        Regex: /^[2-9]\d{9}$|^[2-9]\d{2}\-\d{3}\-\d{4}$/g
    },
    {
        AppValue: 'zip',
        Regex: /^\d{5}(?:[-\s]\d{4})?$/g
    },
    {
        AppValue: 'lastName',
        Regex: /^[A-Za-z]+[A-Za-z'\.\-\s]*$/g
    },
    {
        AppValue: 'legalBusinessName',
        Regex: /^[\s!@#$~&*\.,;\(\)\-=\+\/:"'\?(a-zA-Z0-9]+$/g
    },
    {
        AppValue: 'state',
        Regex: /^(AL|AK|AZ|AR|AS|CA|CO|CT|DE|DC|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|TT|UT|VT|VA|VI|WA|WV|WI|WY|MP)$/gi
    },
    {
        AppValue: 'website',
        Regex: /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    },
    {
        AppValue: 'email',
        Regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
    }

];

const getFieldRegexp = (appValue: string) : RegExp | null => {
    for (let item of mapping) {
        if (item.AppValue === appValue && item.Regex) {
            return item.Regex;
        }
    }
    return null;
}

export { getFieldRegexp }