import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import styles from "./styles";

class Title extends PureComponent {
  render() {
    const { h1, h2, classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography
          className={classes.h1}
          variant="h3"
          component="h1"
          gutterBottom
        >
          {h1}
        </Typography>
        <Typography className={classes.h2} variant="h5" component="h2">
          {h2}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Title);
