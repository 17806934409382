import React, { useState, FC, ReactElement, useEffect } from 'react';
import { ClassNameMap } from "@material-ui/styles";
import { Paper, IconButton, TextField, MenuItem, FormGroup, FormControlLabel, Checkbox, Chip, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { httpsCallable } from "@firebase/functions";
import { collection, getDocs, query, orderBy } from '@firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';

interface ICampaignPanel {
    classes: ClassNameMap<any>;
    firebase: any;
    campaign: any;
    campaigns: any;
}

const useStyles = makeStyles({
  sourcesContainer: {
    maxHeight: 200,
    overflowY: 'auto',
    marginTop: 10,
    marginBottom: 10,
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px'
  },
  sectionTitle: {
    marginTop: 10,
    color: 'gray',
  }
});

export const CampaignPanel: FC<ICampaignPanel> = ({ classes, firebase, campaign, campaigns }): ReactElement => {
    const localClasses = useStyles();
    const [name, setName] = useState<string>('');
    const [campaignType, setCampaignType] = useState<string>('');
    const [campaignTypes, setCampaignTypes] = useState<any[]>([]);
    const [description, setDescription] = useState<string>('');
    const [sources, setSources] = useState<string[]>([]);
    const [allSources, setAllSources] = useState<any[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        setName(campaign.name);
        setCampaignType(campaign.campaignType);
        setDescription(campaign.description || '');
        setSources(campaign.sources || []);
        setIsEdit(false);
        loadCampaignTypes();
        loadSources();
    }, [campaign]);

    const loadCampaignTypes = async () => {
        let q = query(collection(firebase.firestore, "campaignTypes"));
        q = query(q, orderBy('name'));
        const docs = await getDocs(q);
        const results = docs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setCampaignTypes(results);
    }

    const loadSources = async () => {
        let q = query(collection(firebase.firestore, "sources"));
        q = query(q, orderBy('name'));
        const docs = await getDocs(q);
        const results = docs.docs.map(doc => ({ ...doc.data(), id: doc.id, name: doc.data().name }));
        setAllSources(results);
    }

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({});
        set(event.target.value);
    }

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const updatedSources = event.target.checked
            ? [...sources, name]
            : sources.filter(source => source !== name);
        setSources(updatedSources);
    }

    const handleDeleteSource = (name: string) => {
        setSources(sources.filter(source => source !== name));
    }

    const handleEditClick = () => {
        setIsEdit(true);
    }

    const handleCancelClick = () => {
        setErrors({});
        setIsEdit(false);
    }

    const handleSaveClick = async () => {
        const alreadyExist = campaigns.find((c: any) => c.name === name && c.id !== campaign.id);

        if (alreadyExist) {
            setErrors({ name: 'Such name already exists!'});
            return;
        }

        try {
            await httpsCallable(firebase.functions, "updateCampaign")({ id: campaign.id, name, campaignType, description, sources, active: true });
        } catch (error) {
            console.error(error);
            return;
        }

        setIsEdit(false);
    }

    const currentSources = sources.filter(source => allSources.some(s => s.name === source));
    const missingSources = sources.filter(source => !allSources.some(s => s.name === source));

    return (
        <Paper className={classes.paper}>
            <div className={classes.sticky}>
                <div className={classes.panel}>
                    <div className={classes.button}>
                        {isEdit ? (
                            <div>
                                <IconButton onClick={handleSaveClick}>
                                    <SaveIcon />
                                </IconButton>
                                <IconButton onClick={handleCancelClick}>
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        ) : (
                            <IconButton onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        id="name"
                        name="name"
                        label="Name"
                        value={name}  
                        onChange={handleTextChange(setName)}
                        fullWidth
                        disabled={!isEdit}
                        helperText={errors.name}
                        error={Boolean(errors.name)}     
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        id="campaignType"
                        name="campaignType"
                        label="Campaign Type"
                        value={campaignType}  
                        onChange={handleTextChange(setCampaignType)}
                        fullWidth
                        disabled={!isEdit}
                        select
                    >
                        {campaignTypes.map((ct: any) => (
                            <MenuItem key={ct.id} value={ct.name}>{ct.name}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        id="description"
                        name="description"
                        label="Description"
                        value={description}  
                        onChange={handleTextChange(setDescription)}
                        fullWidth
                        disabled={!isEdit}
                    />
                    <Typography className={localClasses.sectionTitle}>Current Sources</Typography>
                    <div className={localClasses.sourcesContainer}>
                        <FormGroup>
                            {allSources.map(source => (
                                <FormControlLabel
                                    key={source.id}
                                    control={
                                        <Checkbox
                                            checked={sources.includes(source.name)}
                                            onChange={(e) => handleSourceChange(e, source.name)}
                                            name={source.name}
                                            disabled={!isEdit}
                                        />
                                    }
                                    label={source.name}
                                />
                            ))}
                        </FormGroup>
                    </div>
                    {missingSources.length > 0 && (
                        <>
                            <Typography className={localClasses.sectionTitle}>Missing Sources</Typography>
                            <div className={localClasses.chipContainer}>
                                {missingSources.map(source => (
                                    <Chip
                                        key={source}
                                        label={source}
                                        onDelete={isEdit ? () => handleDeleteSource(source) : undefined}
                                        color="secondary"
                                        disabled={!isEdit}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>       
            </div>
        </Paper>
    );
}
