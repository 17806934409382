const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
});

export default styles;
