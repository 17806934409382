import React, { PureComponent } from "react";
import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import moment from "moment";
import { addDoc, collection, doc } from "@firebase/firestore";

const styles = theme => ({});

class Quote extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customerName: props.lead ? props.lead.name : "",
      businessName: props.lead ? props.lead.dba : "",
      softwareFee: "0",
      expiresAt: moment().add(1, "days"),
      enableTraditional: true,
      enableInterchange: true,
      enableCashDiscount: true,
      traditional: {
        debitPercent: "0",
        creditPercent: "0",
        keyedPercent: "0",
        govCorpPercent: "0",
        monthlyFee: "0",
        transactionFee: "0"
      },
      interchange: {
        discountRatePercent: "0",
        monthlyFee: "0",
        transactionFee: "0"
      },
      cashDiscount: {
        discountRatePercent: "4",
        monthlyFee: "5",
        transactionFee: "0"
      },
      errors: {
        traditional: {},
        interchange: {},
        cashDiscount: {}
      }
    };
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTierChange = name => event => {
    let tier = this.state[name];
    tier = { ...tier, [event.target.name]: event.target.value };
    this.setState({ [name]: tier });
  };

  handleDateChange = date => {
    this.setState({ expiresAt: date });
  };

  handleSwitchChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSave = async () => {
    const { user } = this.props;
    const state = { ...this.state };
    const quote = {
      businessName: state.businessName,
      customerName: state.customerName,
      softwareFee: parseFloat(state.softwareFee),
      expiresAt: state.expiresAt.toDate(),
      linkRef: doc(this.props.firebase.firestore, "links", this.props.linkId),
      uid: user.uid
    };
    if (state.enableTraditional) {
      quote.traditional = Object.keys(state.traditional).reduce(
        (prev, current) => {
          prev[current] = parseFloat(state.traditional[current]);
          return prev;
        },
        {}
      );
    }
    if (state.enableInterchange) {
      quote.interchange = Object.keys(state.interchange).reduce(
        (prev, current) => {
          prev[current] = parseFloat(state.interchange[current]);
          return prev;
        },
        {}
      );
    }
    if (state.enableCashDiscount) {
      quote.cashDiscount = Object.keys(state.cashDiscount).reduce(
        (prev, current) => {
          prev[current] = parseFloat(state.cashDiscount[current]);
          return prev;
        },
        {}
      );
    }
    const { id } = await addDoc(
      collection(this.props.firebase.firestore, "quotes"),
      quote
    );
    this.props.onQuoteSave(id);
  };

  render() {
    const { errors } = this.state;
    const { quoteId } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Processing Pricing for Quote Page
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="customerName"
                name="customerName"
                label="Customer Name"
                variant="outlined"
                value={this.state.customerName}
                onChange={this.handleTextChange}
                error={!!errors.customerName}
                helperText={errors.customerName}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="businessName"
                name="businessName"
                label="Business Name"
                variant="outlined"
                value={this.state.businessName}
                onChange={this.handleTextChange}
                error={!!errors.businessName}
                helperText={errors.businessName}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="softwareFee"
                name="softwareFee"
                label="Software Fee"
                type="number"
                variant="outlined"
                value={this.state.softwareFee}
                placeholder={"0.00"}
                onChange={this.handleTextChange}
                error={!!errors.softwareFee}
                helperText={errors.softwareFee}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                required
                margin="normal"
                id="date"
                label="Expiration Date"
                value={this.state.expiresAt}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.enableTraditional}
                          onChange={this.handleSwitchChange}
                          name="enableTraditional"
                        />
                      }
                      label="Traditional"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="debitPercent"
                    name="debitPercent"
                    label="Debit Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.debitPercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.debitPercent}
                    helperText={errors.traditional.debitPercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="creditPercent"
                    name="creditPercent"
                    label="Credit Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.creditPercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.creditPercent}
                    helperText={errors.traditional.creditPercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="keyedPercent"
                    name="keyedPercent"
                    label="Keyed Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.keyedPercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.keyedPercent}
                    helperText={errors.traditional.keyedPercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="govCorpPercent"
                    name="govCorpPercent"
                    label="Gov/Corp Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.govCorpPercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.govCorpPercent}
                    helperText={errors.traditional.govCorpPercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="monthlyFee"
                    name="monthlyFee"
                    label="Monthly Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.monthlyFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.monthlyFee}
                    helperText={errors.traditional.monthlyFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="transactionFee"
                    name="transactionFee"
                    label="Transaction Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.traditional.transactionFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("traditional")}
                    error={!!errors.traditional.transactionFee}
                    helperText={errors.traditional.transactionFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableTraditional}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.enableInterchange}
                          onChange={this.handleSwitchChange}
                          name="enableInterchange"
                        />
                      }
                      label="Interchange"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="discountRatePercent"
                    name="discountRatePercent"
                    label="Discount Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.interchange.discountRatePercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("interchange")}
                    error={!!errors.interchange.discountRatePercent}
                    helperText={errors.interchange.discountRatePercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableInterchange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="monthlyFee"
                    name="monthlyFee"
                    label="Monthly Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.interchange.monthlyFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("interchange")}
                    error={!!errors.interchange.monthlyFee}
                    helperText={errors.interchange.monthlyFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableInterchange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="transactionFee"
                    name="transactionFee"
                    label="Transaction Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.interchange.transactionFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("interchange")}
                    error={!!errors.interchange.transactionFee}
                    helperText={errors.interchange.transactionFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableInterchange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.enableCashDiscount}
                          onChange={this.handleSwitchChange}
                          name="enableCashDiscount"
                        />
                      }
                      label="Cash Discount"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="discountRatePercent"
                    name="discountRatePercent"
                    label="Discount Rate"
                    type="number"
                    variant="outlined"
                    value={this.state.cashDiscount.discountRatePercent}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("cashDiscount")}
                    error={!!errors.cashDiscount.discountRatePercent}
                    helperText={errors.cashDiscount.discountRatePercent}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableCashDiscount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="monthlyFee"
                    name="monthlyFee"
                    label="Monthly Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.cashDiscount.monthlyFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("cashDiscount")}
                    error={!!errors.cashDiscount.monthlyFee}
                    helperText={errors.cashDiscount.monthlyFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableCashDiscount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="transactionFee"
                    name="transactionFee"
                    label="Transaction Fee"
                    type="number"
                    variant="outlined"
                    value={this.state.cashDiscount.transactionFee}
                    placeholder={"0.0"}
                    onChange={this.handleTierChange("cashDiscount")}
                    error={!!errors.cashDiscount.transactionFee}
                    helperText={errors.cashDiscount.transactionFee}
                    margin="normal"
                    fullWidth
                    disabled={!this.state.enableCashDiscount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                fullWidth
                onClick={this.handleSave}
              >
                Save Quote
              </Button>
            </Grid>
            {quoteId && (
              <Grid item xs={12}>
                <Button
                  target="_blank"
                  href={`https://app.posbrokers.com/quote/${quoteId}`}
                  fullWidth
                >
                  Preview
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default compose(withStyles(styles), withFirebase)(Quote);
