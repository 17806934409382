import React, { FC, Fragment, ReactElement } from 'react';
import { IconButton, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import AutoComplete from "../../AutoComplete";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from '../styles';

interface ILinkLead {
    application: any;
    classes: any;
    linking: boolean;
    leadId: string;
    metadata: any;
    user: any;
    cancelClick: () => void;
    editLinkClick: () => void;
    saveLinkClick: () => void;
    textChange: (leadId: string) => void;
}

const ReversedListItemText = (props: any) => {
    return <>
      <ListItemText
        className={props.className}
        primary={props.primary}
        secondary={props.secondary}
      />
    </>
}

const LinkLead: FC<ILinkLead> = ({
    application, classes, linking, leadId, metadata, user,
    cancelClick, editLinkClick, saveLinkClick, textChange
}): ReactElement => {
    
    const handleAutoCompleteChange = (event: any, value: any) => {
        if (value) {
            textChange(value.id);
        }
    }

    const handleSaveLinkClick = () => {
        saveLinkClick();
    }
    
    const handleCancelLinking = () => {
        cancelClick();
    }

    const handleLinkClick = () => {
        editLinkClick();
    }

    return (
      <div>
        <List
            component="div"
            className={classes.list}
            subheader={
                <ListSubheader
                    className={classes.listSubheader}
                    component="div"
                >
                    Link Lead
                    {linking ? (
                        <div>
                          <IconButton onClick={handleSaveLinkClick}>
                            <SaveIcon />
                          </IconButton>
                          <IconButton onClick={handleCancelLinking}>
                            <CancelIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <IconButton onClick={handleLinkClick}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </ListSubheader>
                  }
                >
                  {!linking && (
                    <Fragment>
                      <ListItem>
                        <ReversedListItemText
                            className={classes.reverseListItemText}
                            primary={application.leadId || "-"}
                            secondary={"Lead ID"}
                        />
                      </ListItem>
                    </Fragment>
                  )}
                </List>

                {linking && (
                  <Fragment>
                    <div className={classes.field}>
                        <AutoComplete
                            index={process.env.REACT_APP_ALGOLIA_LEADS_INDEX}
                            publicKey={user.algoliaPublicKey}
                            onChange={handleAutoCompleteChange}
                            metadata={metadata}
                        />
                    </div>
                  </Fragment>
                )}
      </div>
    );
}

export default compose<ILinkLead, Element>(
    withStyles(styles as any),
    connect((state: any) => ({
      metadata: state.metadata,
      user: state.user
    }))
)(LinkLead);
