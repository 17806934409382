import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Theme from "../theme";

import * as ROUTES from "../../constants/routes";
import Admin from "../Admin";
import Application from "../Application";
import Cart from "../Application/Cart";
import Confirmation from "../Application/Confirmation";
import Applications from "../Applications";
import ApplicationDetails from "../Applications/Details";
import Calendar from "../Calendar";
import Dashboard from "../Dashboard";
import Leads from "../Leads";
import Links from "../Links";
import { Deals, Ratios } from "../Reports";
import Settings from "../Settings";
import SignIn from "../SignIn";
import Sources from "../Sources";
import Campaigns from "../Campaigns";
import Users from "../Users";
import "./index.css";

class App extends Component {
  render() {
    const host = window.location.hostname;
    return (
      <MuiThemeProvider theme={Theme}>
        <Router>
          {process.env.REACT_APP_IS_CRM === "false" ||
          host === "app.posbrokers.com" ||
          host === "pos-brokers-production.web.app" ||
          host === "pos-brokers-staging.web.app"? (
            <div>
              <Route exact path={ROUTES.HOME} component={Application} />
              <Route
                exact
                path={ROUTES.CONFIRMATION}
                component={Confirmation}
              />
              <Route exact path={ROUTES.ADMIN} component={Admin} />
              <Route exact path={ROUTES.CART} component={Cart} />
            </div>
          ) : (
            <div>
              <Route exact path={ROUTES.APP} component={Application} />
              <Route
                exact
                path={ROUTES.CONFIRMATION}
                component={Confirmation}
              />
              <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
              <Route exact path={ROUTES.LINKS} component={Links} />
              <Route exact path={ROUTES.CALENDAR} component={Calendar} />
              <Route exact path={ROUTES.HOME} component={Dashboard} />
              <Route exact path={ROUTES.LEADS} component={Leads} />
              <Route
                exact
                path={ROUTES.APPLICATIONS}
                component={Applications}
              />
              <Route
                exact
                path={`${ROUTES.APPLICATIONS}/:id`}
                component={ApplicationDetails}
              />
              <Route exact path={ROUTES.SOURCES} component={Sources} />
              <Route exact path={ROUTES.CAMPAIGNS} component={Campaigns} />
              <Route exact path={ROUTES.USERS} component={Users} />
              <Route exact path={ROUTES.CART} component={Cart} />
              <Route exact path={ROUTES.SETTINGS} component={Settings} />
              <Route
                exact
                path={`${ROUTES.REPORTS}/lead-source-closing-ratio`}
                component={Ratios}
              />
              <Route
                exact
                path={`${ROUTES.REPORTS}/deals-count`}
                component={Deals}
              />
            </div>
          )}
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
