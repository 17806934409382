import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.campaigns, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_CAMPAIGNS_SNAPSHOT:
      return {
        ...state,
        data: action.campaigns,
        endBefore: action.endBefore,
        startAfter: action.startAfter
      };
    default:
      return state;
  }
};
