import React, { Component, Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import { Paper, TextField, MenuItem, InputAdornment } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import SendIcon from "@material-ui/icons/Send";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import IdentityIcon from "@material-ui/icons/PermIdentity";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { createLog } from "../../util/ActivityUtil";
import { doc, setDoc } from "@firebase/firestore";
import ResetPasswordDialog from "../UserDialog/resetPasswordDialog";
import styles from "./styles";

const ReversedListItemText = withStyles(() => ({
  reverseListItemText: {
    display: "flex",
    flexDirection: "column-reverse"
  }
}))(({ classes, ...other }) => {
  return <ListItemText className={classes.reverseListItemText} {...other} />;
});

class UserPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      user: props.user,
      resetPasswordDialog: false
    };
    this.originalUser = props.user;
    this.log = createLog(props.firebase, props.authUser);
  }

  componentDidUpdate = prevProps => {
    if (this.props.user.id !== prevProps.user.id) {
      this.setState({
        editing: false,
        user: this.props.user
      });
      this.originalUser = this.props.user;
    }
  };

  handleTextChange = event => {
    this.setState({
      user: { ...this.state.user, [event.target.name]: event.target.value }
    });
  };

  handleEditClick = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleSaveClick = () => {
    this.handleSave(this.state.user);
    this.setState({ editing: false });
  };

  handleCancelEdit = () => {
    this.setState({ user: this.props.user, editing: false });
  };

  handleRoleChange = user => async event => {
    this.log("User Role Updated", {
      oldRole: user.role,
      newRole: event.target.value
    });
    const updated = { ...user, role: event.target.value };
    this.setState({ user: updated });
    await this.updateUser(user.id, { role: event.target.value });
  };

  handleActiveToggle = async (event, checked) => {
    const { user } = this.state;
    const updated = { ...user, active: checked };
    this.setState({ user: updated });
    await this.updateUser(user.id, { active: checked });
    this.log("User Active Updated", { active: checked });
  };

  handleMailEnabledToggle = async (event, checked) => {
    const { user } = this.state;
    const updated = { ...user, mailEnabled: checked };
    this.setState({ user: updated });
    await this.updateUser(user.id, { mailEnabled: checked });
    this.log("User Mail Enabled Updated", { mailEnabled: checked });
  };

  handleAllowRotationToggle = async (event, checked) => {
    const { user } = this.state;
    const updated = { ...user, allowRotation: checked, onRotation: false };
    this.setState({ user: updated });
    await this.updateUser(user.id, {
      allowRotation: checked,
      onRotation: false
    });
    this.log("User Allow Rotation Updated", { allowRotation: checked });
  };

  handleOnRotationToggle = async (event, checked) => {
    const { user } = this.state;
    const updated = { ...user, onRotation: checked };
    this.setState({ user: updated });
    await this.updateUser(user.id, { onRotation: checked });
    this.log("User On Rotation Updated", { onRotation: checked });
  };

  handleSave = async user => {
    const updated = { ...user };
    if (updated.irisUserId) {
      updated.irisUserId = parseInt(updated.irisUserId);
    }
    await this.updateUser(user.id, updated);
    this.log("User Updated", { oldUser: this.originalUser, newUser: updated });
  };

  updateUser = async (id, data) => {
    const firestore = this.props.firebase.firestore;
    await setDoc(doc(firestore, "users", id), data, { merge: true });
  };

  openResetPasswordDialog = open => () => {
    this.setState({ resetPasswordDialog: open });
  };

  render() {
    const { authUser, classes } = this.props;
    const { user, editing } = this.state;
    return (
      <Paper className={classes.paper}>
        {authUser.isAdmin && (
          <div className={classes.field}>
            <TextField
              id="role"
              label="Role"
              variant="outlined"
              margin="normal"
              value={user.role}
              onChange={this.handleRoleChange(user)}
              select
              fullWidth
            >
              <MenuItem value={"support"}>Support</MenuItem>
              <MenuItem value={"sales"}>Sales</MenuItem>
              <MenuItem value={"manager"}>Manager</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"super"}>Super Admin</MenuItem>
            </TextField>
          </div>
        )}
        <List
          component="div"
          className={classes.list}
          subheader={
            <ListSubheader className={classes.listSubheader} component="div">
              User Info
              {editing ? (
                <div>
                  <IconButton onClick={this.handleSaveClick}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={this.handleCancelEdit}>
                    <CancelIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton onClick={this.handleEditClick}>
                  <EditIcon />
                </IconButton>
              )}
            </ListSubheader>
          }
        >
          {!editing && (
            <Fragment>
              {user.firstName && (
                <ListItem>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ReversedListItemText
                    primary={`${user.firstName} ${user.lastName}`}
                    secondary={"First Name"}
                  />
                </ListItem>
              )}
              {user.irisUserId && (
                <ListItem>
                  <ListItemIcon>
                    <IdentityIcon />
                  </ListItemIcon>
                  <ReversedListItemText
                    primary={user.irisUserId}
                    secondary={"IRIS User ID"}
                  />
                </ListItem>
              )}
              {user.phone && (
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ReversedListItemText
                    primary={user.phone}
                    secondary={"Phone"}
                  />
                </ListItem>
              )}
              {user.email && (
                <ListItem>
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ReversedListItemText
                    primary={user.email}
                    secondary={"Email"}
                  />
                </ListItem>
              )}
            </Fragment>
          )}
        </List>
        {editing && (
          <Fragment>
            <div className={classes.field}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                margin="normal"
                variant="outlined"
                value={user.firstName}
                onChange={this.handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                margin="normal"
                variant="outlined"
                value={user.lastName}
                onChange={this.handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <TextField
                id="irisUserId"
                name="irisUserId"
                label="IRIS User ID"
                margin="normal"
                variant="outlined"
                value={user.irisUserId}
                onChange={this.handleTextChange}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IdentityIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                margin="normal"
                variant="outlined"
                value={user.phone}
                onChange={this.handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <TextField
                id="email"
                name="email"
                label="Email"
                margin="normal"
                variant="outlined"
                value={user.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
                disabled
              />
            </div>
          </Fragment>
        )}
        <List
          component="div"
          className={classes.list}
          subheader={
            <ListSubheader className={classes.listSubheader} component="div">
              Settings
            </ListSubheader>
          }
        >
          <ListItem>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Active" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.handleActiveToggle}
                checked={user.active}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Auto Mail" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.handleMailEnabledToggle}
                checked={user.mailEnabled}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
          {(user.role === "sales" || user.email === "jeff@odle.me") && (
            <ListItem>
              <ListItemIcon>
                <RotateRightIcon />
              </ListItemIcon>
              <ListItemText primary="Allow Rotation" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={this.handleAllowRotationToggle}
                  checked={user.allowRotation}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
          {user.allowRotation && (
            <ListItem>
              <ListItemIcon>
                <RotateLeftIcon />
              </ListItemIcon>
              <ListItemText primary="On Rotation" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={this.handleOnRotationToggle}
                  checked={user.onRotation}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
        {authUser.isAdmin && (
          <div className={classes.resetPassword}>
            <Button
              color="default"
              variant="outlined"
              size="small"
              fullWidth
              startIcon={<LinkIcon />}
              onClick={this.openResetPasswordDialog(true)}
            >
              Reset Password
            </Button>
            <ResetPasswordDialog
              open={this.state.resetPasswordDialog}
              userId={user.id}
              handleClose={this.openResetPasswordDialog(false)}
            />
          </div>
        )}
      </Paper>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(styles),
  connect(state => ({
    authUser: state.user,
    metadata: state.metadata
  }))
)(UserPanel);
