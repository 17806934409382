import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import styles from "./styles";

class Footer extends PureComponent {
  render() {
    const { application, classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.line1} variant="body1" gutterBottom>
          Need Help? Call <a href="tel:888-241-8393">888-241-8393</a>
        </Typography>
        {application.id && (
          <Typography className={classes.line2} variant="body1">
            Application Number: {application.id}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
