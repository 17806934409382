import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import { Typography, Divider } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class RatiosSelectedMonth extends Component {
  state = {
    chart: [],
    expanded: false
  };
  componentDidMount = async () => {
    await this.getChart();
  };
  getChart = async () => {
    const numbers = await this.props.httpClient.reports.ratiosSelectedMonth(
      "2021",
      "06"
    );

    const data = {};

    numbers.forEach(e => {
      const user = this.props.metadata.users[e.uid];

      if (!user) {
        return;
      }

      if (!data[e.uid]) {
        data[e.uid] = {
          user: user,
          leads: 0,
          booked: 0,
          closed: 0,
          closedRatio: 0,
          bookedRatio: 0
        };
      }

      if (e.status === "Leads") {
        data[e.uid].leads = e.count;
      }

      if (e.status === "Closed") {
        data[e.uid].closed = e.count;
      }

      if (e.status === "Booked") {
        data[e.uid].booked = e.count;
      }

      data[e.uid].bookedRatio = (data[e.uid].booked / data[e.uid].leads) * 100;

      data[e.uid].closedRatio =
        ((data[e.uid].booked + data[e.uid].closed) / data[e.uid].leads) * 100;
    });

    const array = Object.keys(data).map(e => ({ ...data[e] }));

    this.setState({ data: array, expanded: false });
  };
  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { data, expanded } = this.state;
    return (
      <Accordion expanded={expanded} onChange={this.handleExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="overline">Last Month Ratios</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Rep Name</TableCell>
                <TableCell># of Leads</TableCell>
                <TableCell>Submitted</TableCell>
                <TableCell>Booked</TableCell>
                <TableCell>Closing Ratio</TableCell>
                <TableCell>Booked Ratio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      <Avatar
                        alt={`${row.user.firstName} ${row.user.lastName}`}
                        src={row.user.photoURL}
                      />
                    </TableCell>
                    <TableCell>
                      {row.user.firstName
                        ? `${row.user.firstName} ${row.user.lastName}`
                        : row.user.email}
                    </TableCell>
                    <TableCell>{row.leads}</TableCell>
                    <TableCell>{row.closed + row.booked}</TableCell>
                    <TableCell>{row.booked}</TableCell>
                    <TableCell>{`${row.closedRatio.toFixed(2)}%`}</TableCell>
                    <TableCell>{`${row.bookedRatio.toFixed(2)}%`}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(
  withHttpClient,
  connect(
    state => ({
      metadata: state.metadata
    }),
    {}
  )
)(RatiosSelectedMonth);
