export default class PdfService {
  constructor(axios) {
    this.axios = axios;
  }
  downloadPDF = async (applicationId, fileType, fileName) => {
    const url = `${process.env.REACT_APP_API_HOST}/echo?applicationId=${applicationId}&document=${fileType}`;
  
    try {
      const response = await this.axios.get(url, { responseType: 'blob' });
      const pdfBlob = response.data;
      const downloadUrl = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName.endsWith('.pdf') ? fileName : fileName + '.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error calling ', error);
    }
  };
  

  getPackages = async () => {
    const url = `${process.env.REACT_APP_API_HOST}/echo/packages`;
    try {
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error calling ', error);
    }
  };
}
