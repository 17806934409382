import React, { Component } from "react";
import classNames from "classnames";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../Session";
import { withLayout } from "../Layout";
import DataTable from "../DataTable";
import { withListener } from "../Listener";
import { Typography } from "@material-ui/core";
import {
  setSearchCriteria,
  setColumnVisibility,
  receiveUsersSnapshot,
  receiveUsersTotalCountSnapshot
} from "../../actions/usersActions";

import styles from "./styles";
import UserPanel from "../UserPanel";
import UsersDialog from "../UserDialog";

class UsersPage extends Component {
  state = {
    user: null,
    usersDialog: false
  };

  handleSearchTextChange = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  handleRowClick = (row, isAlreadySelected) => {
    this.setState({ user: isAlreadySelected ? null : row });
  };

  openUsersDialog = open => () => {
    this.setState({ usersDialog: open });
  };

  render() {
    const { users, metadata, classes } = this.props;
    const { user } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.page}>
          <div className={classes.titleBar}>
            <Typography variant="h4">Users</Typography>
            <div className={classes.actions}>
              {this.props.user.isAdmin && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={this.openUsersDialog(true)}
                >
                  Create User
                </Button>
              )}
            </div>
            {this.state.usersDialog && (
              <UsersDialog
                open={this.state.usersDialog}
                handleClose={this.openUsersDialog(false)}
              />
            )}
          </div>
          <div className={classes.container}>
            <div
              className={classNames(classes.tableContainer, {
                [classes.tableContainerZoom]: Boolean(user)
              })}
            >
              <DataTable
                title="Users"
                data={users}
                onSearchTextChange={this.handleSearchTextChange}
                onFilterMenuItemClick={() => {}}
                onRetrieveData={this.props.handleRetrieveData}
                onRowClick={this.handleRowClick}
                metadata={metadata}
              />
            </div>
            {user && <UserPanel user={user} />}
          </div>
        </div>
      </div>
    );
  }
}

const condition = user => !!user;

export default compose(
  withLayout,
  withStyles(styles),
  withAuthorization(condition),
  withListener("users", {
    setSearchCriteria,
    setColumnVisibility,
    receiveSnapshot: receiveUsersSnapshot,
    receiveTotalCountSnapshot: receiveUsersTotalCountSnapshot
  })
)(UsersPage);
