import React, { PureComponent, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./styles";

class Actions extends PureComponent {
  render() {
    const { activeStep, steps, onNext, onBack, loading, classes } = this.props;
    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          fullWidth
          className={classes.backButton}
          onClick={onBack}
          disabled={activeStep === 0}
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          className={classes.nextButton}
          onClick={onNext}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={24}
              color="inherit"
              className={classes.buttonProgress}
            />
          ) : (
            <Fragment>
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Fragment>
          )}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(Actions);
