export const getContent = (application, defaultEquipment) => {
    let type_mobile = false;
    let type_standard = false;
    let type_advanced = false;
    let type_clover = false;
    let type_point = false;

    let terminals = [
        { BrandName: "SwipeSimple", ModelName: "Swift B200 (SS Register)", Type: "mobile" },
        { BrandName: "Clover", ModelName: "Station Solo Bundle with Cash Drawer", Type: "point" },
        { BrandName: "PAX", ModelName: "A920 (4G)", Type: "standard" },
        { BrandName: "Clover", ModelName: "Mini Gen 3", Type: "clover" },
        { BrandName: "Clover", ModelName: "Go Gen 3", Type: "mobile" },
        { BrandName: "Clover", ModelName: "Flex Gen 3", Type: "clover" },
        { BrandName: "SwipeSimple", ModelName: "Swift B250 (SS Payments - mPOS)", Type: "mobile" },
        { BrandName: "Clover", ModelName: "Station Duo", Type: "point" },
        { BrandName: "Clover", ModelName: "Station Solo", Type: "point" }
    ];

    application.link && application.link.equipment && application.link.equipment.forEach((equipment, index) => {
        terminals.forEach(terminal => {
            if (equipment?.BrandName === terminal.BrandName && equipment?.ModelName === terminal.ModelName) {
                switch (terminal.Type) {
                    case "mobile":
                        type_mobile = true;
                        break;
                    case "standard":
                        type_standard = true;
                        break;
                    case "clover":
                        type_clover = true;
                        break;
                    case "point":
                        type_point = true;
                        break;
                }
            }
        });
    });

    if (!application.link) {
        const selectedTerminal = terminals.find(
            e => e.ModelName.toLowerCase().includes(defaultEquipment?.name.toLowerCase())
          ) || terminals[0];

          terminals.forEach(terminal => {
            if (selectedTerminal?.BrandName === terminal.BrandName && selectedTerminal?.ModelName === terminal.ModelName) {
                switch (terminal.Type) {
                    case "mobile":
                        type_mobile = true;
                        break;
                    case "standard":
                        type_standard = true;
                        break;
                    case "clover":
                        type_clover = true;
                        break;
                    case "point":
                        type_point = true;
                        break;
                }
            }
        });
    }

    const formattedDate = application.createdAt
    ? application.createdAt.seconds
        ? new Date(application.createdAt.seconds * 1000).toLocaleDateString()
        : new Date(application.createdAt).toLocaleDateString()
    : '';

	return `
<style type="text/css">
<!--
	p {margin: 0; padding: 0;}	.ft10{font-size:10px;font-family:Times;color:#000000;}
	.ft11{font-size:10px;font-family:Times;color:#000000;}
	.ft12{font-size:10px;font-family:Times;color:#ffffff;}
	.ft13{font-size:10px;font-family:Times;color:#000000;}
	.ft14{font-size:10px;font-family:Times;color:#0461c1;}
	.ft15{font-size:10px;font-family:Times;color:#0460be;}
	.ft16{font-size:14px;font-family:Times;color:#000000;}
	.ft17{font-size:10px;line-height:14px;font-family:Times;color:#000000;}
	.ft18{font-size:14px;line-height:18px;font-family:Times;color:#000000;}
-->
</style>
<div id="page1-div" style="position:relative;width:800px;height:850px;">
<p style="position:absolute;top:57px;left:381px;white-space:nowrap" class="ft10"><b>Equipment Placement Program</b></p>
<p style="position:absolute;top:71px;left:370px;white-space:nowrap" class="ft10"><b>Addendum to Merchant Agreement</b></p>
<p style="position:absolute;top:86px;left:54px;white-space:nowrap" class="ft17">Subject to the terms and conditions below, including the minimum processing and equipment return requirements, POS Brokers will provide to your business the&#160;<br/>terminal described below at no additional charge to you.&#160;</p>
<p style="position:absolute;top:115px;left:368px;white-space:nowrap" class="ft12"><b>PROGRAM TERMS AND CONDITIONS</b></p>
<p style="position:absolute;top:130px;left:54px;white-space:nowrap" class="ft17">This Equipment Placement Program Addendum to Merchant Agreement (this “<i><b>Addendum</b></i>”) modifies the Merchant Payment Card Application/Agreement (the&#160;<br/>“Merchant Agreement”) by and among "${application.doingBusinessAs}" (“<i><b>Company</b></i>”) and the Merchant named below (“<i><b>Merchant</b></i>”). Capitalized terms&#160;<br/>used but not otherwise defined in this Addendum have the respective meanings as contained in the Merchant Agreement. The specific Bank referenced in the&#160;<br/>Merchant Agreement is not a party to this Addendum.&#160;</p>

<table border=1 style="position: absolute;
top: 195px;
left: 48px;
font-size: 9px;
width: 654px;
color: black;
">
        <thead>
            <tr>
                <th>Select Tier</th>
                <th>Terminal type</th>
                <th>Combined monthly Visa, Mastercard and Discover Volume Requirement</th>
                <th>Monthly Fee (if applicable)</th>
                <th>Amount (if applicable)</th>
                <th>Amount Paid</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" type="checkbox"></td>
                <td>No Equipment</td>
                <td>$0</td>
                <td>$0</td>
                <td>$0</td>
                <td></td>
            </tr>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" ${type_mobile ? 'checked="checked"' : ''} type="checkbox"></td>
                <td>Mobile reader</td>
                <td>$1,000</td>
                <td>$20</td>
                <td>$199</td>
                <td></td>
            </tr>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" ${type_standard ? 'checked="checked"' : ''} type="checkbox"></td>
                <td>Standard Desk Top Terminal</td>
                <td>$2,500</td>
                <td>$30</td>
                <td>$499</td>
                <td></td>
            </tr>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" ${type_advanced ? 'checked="checked"' : ''} type="checkbox"></td>
                <td>Advanced Desktop Terminal</td>
                <td>$5,000</td>
                <td>$40</td>
                <td>$699</td>
                <td></td>
            </tr>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" ${type_clover ? 'checked="checked"' : ''} type="checkbox"></td>
                <td>Clover Mini, Clover Flex</td>
                <td>$8,000</td>
                <td>$50</td>
                <td>$1,299</td>
                <td></td>
            </tr>
            <tr>
                <td class="checkbox-cell"><input disabled="disabled" ${type_point ? 'checked="checked"' : ''} type="checkbox"></td>
                <td>Point of Sale Station</td>
                <td>$15,000</td>
                <td>$120</td>
                <td>$2,299</td>
                <td></td>
            </tr>
        </tbody>
    </table>

<p style="position:absolute;top:421px;left:54px;white-space:nowrap" class="ft17">Subject to the minimum processing requirements referenced below, the Equipment shall be provided to Merchant at a discount or no charge; provided, however,&#160;<br/>that Merchant shall be responsible for paying applicable shipping and handling charges referenced below. Merchant shall be responsible for obtaining and paying for&#160;<br/>all incidental Equipment supplies, including but not limited to weight scale, bar code scanner, paper, ribbons, cartridges, necessary equipment repairs, etc.&#160;</p>
<p style="position:absolute;top:479px;left:54px;white-space:nowrap" class="ft17">Merchant acknowledges that the Equipment may only be compatible with a merchant account provided by Company. The Equipment shall be used for commercial&#160;<br/>purposes only in conjunction with and pursuant to the Merchant Agreement. Merchant is responsible for safeguarding the Equipment from loss, damage,&#160;<br/>unauthorized use, misuse or theft. Merchant shall comply with all governmental laws, rules and regulations relating to the use of the Equipment, as well as all Card&#160;<br/>Organization Rules, including the Payment Card Industry Data Security Standards (PCI-DSS).&#160;</p>
<p style="position:absolute;top:552px;left:54px;white-space:nowrap" class="ft17">During the term of the Merchant Agreement, beginning the first month after the date of this Addendum, in addition to any other fees charged to Merchant pursuant&#160;<br/>to the Merchant Agreement, including any minimum fees, should Merchant process less than the combined Monthly Visa, MasterCard and Discover Volume&#160;<br/>Requirements described in Table 1 above (excluding PIN Debit activity) in any full month, Merchant will be charged the specified Monthly Fee referenced in Table 1&#160;<br/>for the applicable Equipment for each month said transaction volume requirement is not met. Such amount, if applicable, shall be debited via ACH from Merchant’s&#160;<br/>bank account.&#160;</p>
<p style="position:absolute;top:640px;left:54px;white-space:nowrap" class="ft17">The Merchant agrees to be charged a one-time ground shipping and handling fee of $25.00 prior to Company shipping the Equipment to the Merchant. Merchant&#160;<br/>agrees that the Equipment is the property of Company. Merchant shall call Company at 888-702-3405 or email admin@posbrokers.com and obtain a “Return</p>

<p style="position:absolute;top:670px;left:54px;white-space:nowrap" class="ft17">Merchandise Authorization” number and shipping instructions and return the Equipment pursuant to said shipping instructions by no later than ten (10) days&#160;<br/>following the termination or expiration of the Agreement. Should Merchant fail to return the Equipment as aforesaid, Merchant shall pay to Company the&#160;<br/>Replacement Amount referenced in Table 1 above (“Failure to Return Charge”). The Failure to Return Charge, if applicable, shall be debited via ACH from Merchant’s&#160;<br/>bank account.&#160;<br/>Merchant shall return such Equipment in the same operating order, repair, condition, and appearance that such Equipment had at the time it was delivered to&#160;<br/>Merchant, except for ordinary “wear and tear”.&#160;</p>
<p style="position:absolute;top:772px;left:54px;white-space:nowrap" class="ft17">No cross-outs, changes or modifications to this Addendum will be accepted or approved by Company unless confirmed in writing by Company. Except as expressly&#160;<br/>modified by this Addendum, the terms and provisions of the Merchant Agreement are hereby affirmed and ratified. Hereafter, all references to the Merchant&#160;<br/>Agreement shall mean the Merchant Agreement as modified by this Addendum.&#160;</p>
<p style="position:absolute;top:831px;left:382px;white-space:nowrap" class="ft12"><b>AGREED TO AND ACCEPTED BY</b></p>
<p style="position:absolute;top:845px;left:54px;white-space:nowrap" class="ft17">By signing below, Merchant hereby agrees to the foregoing Program Terms and Conditions and Authorizes Company to debit Merchant’s checking account as&#160;<br/>designated in the Merchant Agreement, or as such checking account may by changed from time-to-time, for any amounts that may be owed by Merchant pursuant to&#160;<br/>this Addendum.&#160;</p>
<p style="position:absolute;top:909px;left:54px;white-space:nowrap" class="ft16">Bank Name: ${application.bankName}&#160;</p>
<p style="position:absolute;top:909px;left:453px;white-space:nowrap" class="ft16">Routing #: ${application.routingNumber}&#160;</p>
<p style="position:absolute;top:909px;left:617px;white-space:nowrap" class="ft16">Account #: ${application.bankAccountNumber}&#160;</p>
<p style="position:absolute;top:977px;left:54px;white-space:nowrap" class="ft16">Billing Address: ${application.billingAddress}&#160;</p>
<p style="position:absolute;top:977px;left:453px;white-space:nowrap" class="ft16">State: ${application.billingState}&#160;</p>
<p style="position:absolute;top:977px;left:617px;white-space:nowrap" class="ft16">Zip Code: ${application.billingZip}&#160;</p>
<p style="position:absolute;top:1007px;left:54px;white-space:nowrap" class="ft16">Phone #: ${application.businessPhone}&#160;</p>
<p style="position:absolute;top:1027px;left:54px;white-space:nowrap" class="ft18">Signor Name as shown on the merchant&#160;<br/>Agreement: ${application.firstName} ${application.lastName}&#160;</p>
<p style="position:absolute;top:1027px;left:453px;white-space:nowrap" class="ft18">Business Name as shown on the Merchant&#160;<br/>Agreement: ${application.legalBusinessName}&#160;</p>
<p style="position:absolute;top:1079px;left:54px;white-space:nowrap" class="ft16">Signature: ${application.signatureUrl ? `<img src="${application.signatureUrl}" alt="Signature" />` : ''}&#160;</p>
<p style="position:absolute;top:1079px;left:453px;white-space:nowrap" class="ft16">Date: ${formattedDate}&#160;</p>
<p style="position:absolute;top:1109px;left:54px;white-space:nowrap" class="ft16">Mid#: ${application.firstName} ${application.lastName}&#160;</p>
<p style="position:absolute;top:1109px;left:453px;white-space:nowrap" class="ft16">Email: ${application.email}&#160;</p>

</div>
`;
}
