const styles = theme => ({
  paper: {
    marginLeft: theme.spacing(1),
    width: 300 - theme.spacing(1)
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  headingIcon: {
    marginRight: theme.spacing(2)
  },
  headingLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  field: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  inline: {
    display: "inline"
  },
  list: {
    padding: 0
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[50],
    paddingRight: 4,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    borderTop: "1px solid rgba(0,0,0,0.13)"
  },
  resetPassword: {
    padding: 10
  }
});

export default styles;
