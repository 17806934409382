import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { compose } from "recompose";
import { collection, getDocs, query, orderBy } from '@firebase/firestore';
import { withFirebase } from '../Firebase';
import _ from "lodash";

export const AutoCompleteCampaignType = (props: any) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<string[]>([]);

    let loading = open && options.length === 0;

    const fetchCampaignTypes = useCallback(async () => {
        const campaignTypesDocuments = await getDocs(query(
            collection(props.firebase.firestore, "campaignTypes"),
            orderBy('name')));
            
        const campaignTypes = _.uniq(campaignTypesDocuments.docs.map(d => d.data().name as string));
        setOptions(campaignTypes);
    }, [props.firebase.firestore]);

    useEffect(() => {
        fetchCampaignTypes();
    }, [fetchCampaignTypes]);

    const handleChange = (evnt: any, value: string | null, reason: any) => {
        if (props.value !== value) {
            props.onChange(value);
        }
    }

    return (
        <div>
            <Autocomplete
                id="campaign-type"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                options={options}
                loading={loading}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option}
                onChange={handleChange}
                value={props.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Campaign Type"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                style={{ marginRight: 8, minWidth: 200 }}
            />
        </div>
    )
}

export default compose(withFirebase)(AutoCompleteCampaignType)
