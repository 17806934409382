import { initializeApp } from "firebase/app";
import { getAuth, Auth, GoogleAuthProvider,
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  sendPasswordResetEmail,
  updatePassword,
  updateProfile
} from "firebase/auth";
import { initializeFirestore, Firestore, doc, updateDoc } from "firebase/firestore";
import { getStorage, FirebaseStorage } from "firebase/storage";
import { getFunctions, Functions } from "firebase/functions";
import { signInError, signOutSuccess } from "../../actions/userActions";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {

  store: any;
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;

  constructor (store: any) {
    const firebaseApp = initializeApp(config);
    this.store = store;
    this.auth = getAuth(firebaseApp);
    this.firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
    this.functions = getFunctions(firebaseApp);
    this.storage = getStorage(firebaseApp);
  }

  doCreateUserWithEmailAndPassword = async (email: any, password: any) =>
    await createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = async (email: any, password: any) => {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      if (error instanceof Error) {
        this.store.dispatch(signInError(error.message));
      }
    }
  };

  doSignInWithGoogleProvider = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://mail.google.com/");
      return await signInWithPopup(this.auth, provider);
    } catch (error) {
      if (error instanceof Error) {
        this.store.dispatch(signInError(error.message));
      }
    }
  };

  doSignOut = async () => {
    await this.auth.signOut();
    this.store.dispatch(signOutSuccess());
  };

  doPasswordReset = (email: any) =>
    sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = (password: any) => 
    this.auth.currentUser && updatePassword(this.auth.currentUser, password);

  doProfileImageUpdate = async (photoUrl: string) => {
    if (this && this.auth && this.auth.currentUser) {
      const uid = this.auth.currentUser.uid;
      const userRef = doc(this.firestore, "users", uid);
      await updateDoc(userRef, { photoURL: photoUrl });
      await updateProfile(this.auth.currentUser, { photoURL: photoUrl });
    }
  };
}

export default Firebase;
