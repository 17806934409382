import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createLog } from "../../util/ActivityUtil";

import styles from "./styles";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc
} from "@firebase/firestore";

class NoteDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.note.id,
      text: props.note.text || ""
    };
    this.originalNote = {
      id: props.note.id,
      text: props.note.text || ""
    };
    this.log = createLog(props.firebase, props.user);
  }

  handleSubmit = async () => {
    const note = {
      uid: this.props.user.uid,
      leadId: this.props.lead.id,
      text: this.state.text,
      createdAt: new Date()
    };
    const firestore = this.props.firebase.firestore;
    if (this.state.id) {
      await setDoc(doc(firestore, "notes", this.state.id), note, {
        merge: true
      });
      this.log("Event Updated", { oldNote: this.originalNote, newNote: note });
    } else {
      await addDoc(collection(firestore, "notes"), note);
    }
    this.handleClose();
  };

  handleDelete = async () => {
    if (this.state.id) {
      await deleteDoc(
        doc(this.props.firebase.firestore, "notes", this.state.id)
      );
      this.log("Note Deleted", { noteId: this.state.id });
    }
    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { id, text } = this.state;
    const { open, classes } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="note-dialog-title"
        open={open}
      >
        <DialogTitle id="note-dialog-title">
          {id ? "Editing" : "Creating"} Note
        </DialogTitle>
        <div className={classes.content}>
          <TextField
            id="text"
            name="text"
            label="Note"
            multiline
            rows="4"
            component="pre"
            value={text}
            onChange={this.handleTextChange}
            variant="outlined"
            fullWidth
            className={classes.textField}
          />
        </div>
        <DialogActions className={classes.actions}>
          <Button
            onClick={this.handleDelete}
            variant="outlined"
            size="small"
            color="default"
          >
            Delete
          </Button>
          <Button
            onClick={this.handleClose}
            variant="outlined"
            size="small"
            color="default"
          >
            Close
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="outlined"
            size="small"
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    user: state.user
  })),
  withFirebase
)(NoteDialog);
