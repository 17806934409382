import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../Session";
import { withLayout } from "../Layout";
import { LeadsClosingRatio } from "../Charts";
import { LeadsClosingRatioWithFilter } from "../Charts";
import styles from "./styles";
import OverallClosingRatioWithFilter from "../Charts/overallClosingRatioWithFilter";

class Dashboard extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <OverallClosingRatioWithFilter
          displayFilter={true}
          title="Overall Closing Ratio"
        />
        <LeadsClosingRatioWithFilter
          displayFilter={true}
          title="Lead Source Closing Ratio"
        />
      </div>
    );
  }
}

const condition = user => !!user;

export default compose(
  withLayout,
  withStyles(styles),
  withAuthorization(condition)
)(Dashboard);
