import React, { Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import moment from "moment";

import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Avatar,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Message from "../Message";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import NoteIcon from "@material-ui/icons/Note";
import TodayIcon from "@material-ui/icons/Today";
import SettingsIcon from "@material-ui/icons/Settings";
import LinkIcon from "@material-ui/icons/Link";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FlagIcon from "@material-ui/icons/Flag";

import { setSearchCriteria } from "../../actions/leadActions";
import * as ROUTES from "../../constants/routes";
import Logo from "../../static/images/odlecrm.png";
import styles from "./styles";
import { doc, setDoc } from "@firebase/firestore";

const withLayout = Component => {
  class WithLayout extends React.Component {
    state = {
      avatarAnchorEl: null,
      eventAnchorEl: null,
      open: false,
      reportsAnchor: null,
      reportsSubMenuOpen: false,
      time: new Date()
    };

    componentDidMount() {
      // create the interval once component is mounted
      this.update = setInterval(() => {
        this.setState({ time: new Date() });
      }, 1 * 1000); // every 1 seconds
    }

    componentWillUnmount() {
      // delete the interval just before component is removed
      clearInterval(this.update);
    }

    handleDrawerToggle = () => {
      this.setState({ open: !this.state.open });
    };

    handleAvatarClick = event => {
      this.setState({ avatarAnchorEl: event.currentTarget });
    };

    handleAvatarMenuClose = () => {
      this.setState({ avatarAnchorEl: null });
    };

    handleEventButtonClick = event => {
      this.setState({ eventAnchorEl: event.currentTarget });
    };

    handleEventMenuClose = () => {
      this.setState({ eventAnchorEl: null });
    };

    handleRotationToggle = async () => {
      const { user, firebase } = this.props;
      const firestore = firebase.firestore;
      await setDoc(
        doc(firestore, "users", user.uid),
        { onRotation: !user.onRotation },
        { merge: true }
      );
    };

    handleEventClick = leadId => () => {
      const {
        leads: { page, order, orderBy, rowsPerPage, where },
        setSearchCriteria
      } = this.props;
      const newWhere = { ...where, id: leadId };
      const searchCriteria = {
        order,
        orderBy,
        page,
        rowsPerPage,
        where: newWhere
      };
      setSearchCriteria(searchCriteria);
      this.props.history.push({
        pathname: `${ROUTES.LEADS}`,
        state: { forceRefresh: true }
      });
    };

    handleReportsClick = event => {
      this.setState({
        reportsAnchor: event.currentTarget,
        reportsSubMenuOpen: !this.state.reportsSubMenuOpen
      });
    };

    handleReportsSubMenuClose = () => {
      this.setState({
        reportsAnchor: null,
        reportsSubMenuOpen: false
      });
    };

    handleReportsSubMenuClick = reportName => {
      this.props.history.push(`${ROUTES.REPORTS}/${reportName}`);
      this.handleReportsSubMenuClose();
    };

    render() {
      const { avatarAnchorEl, eventAnchorEl, open, time } = this.state;
      const { user, events, classes, history, firebase } = this.props;
      const componentProps = { ...this.props };
      delete componentProps.classes;
      return user && user.isLoaded ? (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.toolbarContent}>
                <div className={classes.leftSide}>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open
                    })}
                  >
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                  </IconButton>
                  <img src={Logo} className={classes.logo} alt="POS Pros" />
                </div>
              </div>
              <div className={classes.center}></div>
              <div className={classes.rightSide}>
                {user.allowRotation && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.onRotation}
                          onChange={this.handleRotationToggle}
                          name="onRotation"
                          color="primary"
                        />
                      }
                      label="Rotation"
                    />
                  </FormGroup>
                )}
                <Chip
                  color="primary"
                  variant="outlined"
                  className={classes.time}
                  icon={<AccessTimeIcon />}
                  label={time.toLocaleTimeString()}
                />
                <IconButton
                  color="primary"
                  aria-label="avatar"
                  onClick={this.handleEventButtonClick}
                  className={classes.eventButton}
                >
                  <TodayIcon />
                </IconButton>
                <Menu
                  id="event-menu"
                  anchorEl={eventAnchorEl}
                  keepMounted
                  open={Boolean(eventAnchorEl)}
                  onClose={this.handleEventMenuClose}
                >
                  <ListSubheader component="div">
                    {events && events.length > 0
                      ? "Upcoming Events"
                      : "No Upcoming Events"}
                  </ListSubheader>

                  {events &&
                    events.map(e => {
                      return (
                        <MenuItem
                          key={e.id}
                          alignItems="flex-start"
                          onClick={this.handleEventClick(e.leadId)}
                        >
                          <ListItemAvatar>
                            <ListItemIcon>
                              <TodayIcon />
                            </ListItemIcon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${e.title} - ${moment(
                              e.scheduledAt.toDate()
                            ).fromNow()}`}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                ></Typography>
                                {moment(e.scheduledAt.toDate()).format("LLL")}
                              </React.Fragment>
                            }
                          />
                        </MenuItem>
                      );
                    })}
                </Menu>
                <IconButton
                  aria-label="avatar"
                  onClick={this.handleAvatarClick}
                  className={classes.avatarButton}
                >
                  {user.photoURL ? (
                    <Avatar
                      alt={`${user.email}`}
                      src={user.photoURL}
                      className={classes.avatar}
                    />
                  ) : (
                    <Avatar alt={`${user.email}`} className={classes.avatar}>
                      {user.displayName?.substring(0, 1)}
                    </Avatar>
                  )}
                </IconButton>
                <Menu
                  id="avatar-menu"
                  anchorEl={avatarAnchorEl}
                  keepMounted
                  open={Boolean(avatarAnchorEl)}
                  onClose={this.handleAvatarMenuClose}
                >
                  <MenuItem onClick={firebase.doSignOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
          >
            <div className={classes.toolbar} />
            <List>
              <ListItem button onClick={() => history.push(ROUTES.HOME)}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem
                button
                onClick={() => history.push(ROUTES.APPLICATIONS)}
              >
                <ListItemIcon>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary="Applications" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.LEADS)}>
                <ListItemIcon>
                  <ContactPhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Leads" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.CALENDAR)}>
                <ListItemIcon>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary="Calendar" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.LINKS)}>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Links" />
              </ListItem>
              {(user.role === "super" || user.role === "admin") && (
                <ListItem button onClick={() => history.push(ROUTES.SOURCES)}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sources" />
                </ListItem>
              )}
              {(user.role === "super" || user.role === "admin") && (
                <ListItem button onClick={() => history.push(ROUTES.CAMPAIGNS)}>
                  <ListItemIcon>
                    <FlagIcon />
                  </ListItemIcon>
                  <ListItemText primary="Campaigns" />
                </ListItem>
              )}
              {(user.role === "super" || user.role === "admin") && (
                <ListItem button onClick={() => history.push(ROUTES.USERS)}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              )}
              {user.role === "super" && (
                <ListItem button disabled>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activity" />
                </ListItem>
              )}
              <ListItem button onClick={() => history.push(ROUTES.SETTINGS)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Account Settings" />
              </ListItem>
              <ListItem button onClick={this.handleReportsClick}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
              <Menu
                id="simple-menu"
                anchorEl={this.state.reportsAnchor}
                keepMounted
                open={Boolean(this.state.reportsSubMenuOpen)}
                onClose={this.handleReportsSubMenuClose}
              >
                <MenuItem
                  onClick={() =>
                    this.handleReportsSubMenuClick("lead-source-closing-ratio")
                  }
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <Typography>Lead Source Closing Ratio</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => this.handleReportsSubMenuClick("deals-count")}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <Typography>Deal Count</Typography>
                </MenuItem>
              </Menu>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Component {...componentProps} />
          </main>
          <Message />
        </div>
      ) : (
        <Fragment>
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Fragment>
      );
    }
  }
  return compose(
    withAuthentication,
    withStyles(styles),
    withFirebase,
    connect(
      state => ({
        events: state.events,
        leads: state.leads
      }),
      {
        setSearchCriteria
      }
    )
  )(WithLayout);
};

export default withLayout;
