const styles = theme => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 300
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(2)
  }
});

export default styles;
