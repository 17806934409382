export const SIGN_IN = "/sign-in";
export const HOME = "/";
export const APP = "/app";
export const CONFIRMATION = "/confirmation";
export const LINKS = "/links";
export const ADMIN = "/admin";
export const LEADS = "/leads";
export const SOURCES = "/sources";
export const USERS = "/users";
export const CALENDAR = "/calendar";
export const APPLICATIONS = "/applications";
export const CART = "/cart";
export const SETTINGS = "/settings";
export const REPORTS = "/reports";
export const CAMPAIGNS = "/campaigns";
