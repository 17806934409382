import { FilterOption } from './index';

export const LeadClosingRatioReportFilters: FilterOption[] = [
    {
      id: 'createdAt',
      column: 'createdAt',
      label: 'Date Range',
      type: 'dateRange',
      operators: [
        {
          id: '__between',
          label: 'is between',
        },
      ],
    },
    {
      id: 'campaignType',
      column: 'campaignType',
      label: 'Campaign Type',
      type: 'list',
      options: [],
      operators: [
        {
          id: '__in',
          label: 'includes',
        },
      ],
    },
    {
      id: 'campaign',
      column: 'campaign',
      label: 'Campaign',
      type: 'list',
      options: [],
      operators: [
        {
          id: '__in',
          label: 'includes',
        },
      ],
    },
    {
      id: 'source',
      column: 'source',
      label: 'Source',
      type: 'list',
      options: [],
      operators: [
        {
          id: '__in',
          label: 'includes',
        },
      ],
    },
  ];
