import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import PhoneIcon from "@material-ui/icons/Phone";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../Firebase";
import URLSearchParams from "url-search-params";
import { getDoc, doc, onSnapshot } from "@firebase/firestore";

import Logo from "../../../static/images/MAS-Powered-by-Logo-02.png";

// https://github.com/mui-org/material-ui/tree/master/docs/src/pages/premium-themes/paperbase

const styles = theme => ({
  logo: {
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: 48
    }
  },
  appBar: {
    padding: `${theme.spacing(2)}px 10%`,
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.divider,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`
    }
  },
  button: {
    fontSize: "1rem",
    textDecoration: "none !important",
    color: "rgba(0, 0, 0, 0.87) !important"
  },
  content: {
    padding: `${theme.spacing(2)}px 20%`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    }
  },
  tablePaper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  checkoutButton: {
    marginTop: theme.spacing(4)
  },
  footer: {
    //backgroundColor: "rgb(49, 50, 54);",
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "solid 1px",
    borderTopColor: theme.palette.divider
  },
  band: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  bottomLogo: {
    height: 40
  },
  legal: {
    padding: `0 10%`,
    textAlign: "center"
  }
});

class Cart extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const landingPageId = params.get("lpid");
    this.state = { landingPageId };
  }
  componentDidMount = async () => {
    const firestore = this.props.firebase.firestore;
    if (this.state.landingPageId) {
      const landingPageSnapshot = await getDoc(
        doc(firestore, "landingPages", this.state.landingPageId)
      );
      if (landingPageSnapshot.exists) {
        const linkId = landingPageSnapshot.data().linkId;
        onSnapshot(doc(firestore, "links", linkId), snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data();
            this.setState({ link: { ...data, id: snapshot.id } });
          }
        });
      }
    }
  };
  render = () => {
    const { classes } = this.props;
    const { link } = this.state;
    return (
      <div>
        <AppBar
          className={classes.appBar}
          color="inherit"
          position="sticky"
          elevation={0}
        >
          <Toolbar>
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <img
                  className={classes.logo}
                  src={Logo}
                  alt="Merchant Account Solutions"
                />
              </Grid>
              {/*<Hidden smDown>
                <Grid item xs>
                  <Tabs
                    centered
                    value={0}
                    textColor="inherit"
                    indicatorColor="white"
                  >
                    <Tab textColor="inherit" label="Point of Sale" />
                    <Tab textColor="inherit" label="Credit Card Terminals" />
                    <Tab textColor="inherit" label="Software" />
                    <Tab textColor="inherit" label="Rates" />
                  </Tabs>
                </Grid>
                              </Hidden>*/}
              <Grid item>
                <Button
                  className={classes.button}
                  startIcon={<PhoneIcon color="primary" />}
                >
                  888-241-8393
                </Button>
              </Grid>
              {/*<Hidden smUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>*/}
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Typography variant="h4" gutterBottom>
            Shopping Cart
          </Typography>

          {link && (
            <Alert severity="success">
              {link.equipment.length} items have been added to your cart.
            </Alert>
          )}

          <TableContainer className={classes.tablePaper} component={Paper}>
            <Hidden smDown>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {link &&
                    link.equipment.map(item => (
                      <TableRow>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>${item.price.toFixed(2)}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          <strike>${item.price.toFixed(2)}</strike>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Hidden>
            <Hidden mdUp>
              <Fragment>
                {link &&
                  link.equipment.map(item => (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell>{item.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Price</TableCell>
                          <TableCell>${item.price.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell>
                            <strike>${item.price.toFixed(2)}</strike>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
              </Fragment>
            </Hidden>
          </TableContainer>

          <Grid container>
            <Grid item xs={0} md={4} />
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom>
                Cart Totals
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  {link && (
                    <TableBody>
                      <TableRow>
                        <TableCell>Discount</TableCell>
                        <TableCell>
                          ${link.pricing.discount.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Subtotal</TableCell>
                        <TableCell>
                          ${link.pricing.subtotal.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Shipping</TableCell>
                        <TableCell>
                          ${parseInt(link.shipping).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell>${link.pricing.total}</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Button
                className={classes.checkoutButton}
                color="primary"
                variant="contained"
                href={`/?checkout=${this.state.landingPageId}&term=${"1"}`}
                fullWidth
              >
                Proceed to Checkout
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.footer}>
          <div className={classes.band}>
            <img
              className={classes.bottomLogo}
              src={Logo}
              alt="Merchant Account Solutions"
            />
          </div>
          <div className={classes.legal}>
            <Typography variant="caption">
              International Card Service LLC, DBA Merchant Account Solutions is
              a registered ISO of Wells Fargo Bank, N.A., Concord, CA Copyright
              © 2019 Merchant Account Solutions, Inc. | All Rights Reserved. The
              Clover trademark and logo are owned by Clover Network, Inc., a
              First Data company. All other trademarks, service marks and trade
              names referenced in this material are the property of their
              respective owners. *Limit of one (1) Discounted Clover Mini, or
              one (1) other Clover POS system. New Customers only. Buyer must
              open a Merchant Account through us for use with the Clover POS
              System. Ask your Sales Representative for complete details.
            </Typography>
          </div>
        </div>
      </div>
    );
  };
}

Cart.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withFirebase(withStyles(styles)(Cart));
