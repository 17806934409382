const styles = theme => ({
  root: {},
  titleBar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    borderTop: "1px solid rgba(0,0,0,0.13)",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    padding: theme.spacing(1)
  },
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 300px) !important"
  }
});

export default styles;
