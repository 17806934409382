const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  backButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  nextButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
});

export default styles;
