const styles = theme => ({
  paper: {
    marginTop: 0,
    marginLeft: theme.spacing(1),
    width: 600 + theme.spacing(1)
  },
  sticky: {
    display: "flex",
    flexDirection: "row",
    position: "sticky",
    top: 70
  },
  left: {
    width: 300
  },
  right: {
    borderLeft: "solid 1px",
    borderLeftColor: theme.palette.grey[200],
    width: 300
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  headingIcon: {
    marginRight: theme.spacing(2)
  },
  headingLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  field: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  inline: {
    display: "inline"
  },
  list: {
    padding: 0
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[50],
    paddingRight: 4,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    borderTop: "1px solid rgba(0,0,0,0.13)"
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18
    }
  },
  pricingButtonContainer: {
    width: "100%",
    padding: theme.spacing(1)
  },
  pricingUrl: {
    textOverflow: "ellipsis"
  }
});

export default styles;
