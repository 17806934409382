import VMasker from "vanilla-masker";

export const mask = (field, value) => {
  if (field === "businessYear") {
    return VMasker.toPattern(value, "9999");
  }
  if (field === "businessZip") {
    return VMasker.toPattern(value, "99999");
  }
  if (field === "shippingZip") {
    return VMasker.toPattern(value, "99999");
  }
  if (field === "dateOfBirth") {
    return VMasker.toPattern(value, "99/99/9999");
  }
  if (field === "federalTaxId") {
    return VMasker.toPattern(value, "99-9999999");
  }
  if (field === "socialSecurityNumber") {
    return VMasker.toPattern(value, "999-99-9999");
  }
  if (field === "startPhone") {
    return VMasker.toPattern(value, "999-999-9999");
  }
  if (field === "businessPhone") {
    return VMasker.toPattern(value, "999-999-9999");
  }
  if (field === "homePhone") {
    return VMasker.toPattern(value, "999-999-9999");
  }
  if (field === "homeZip") {
    return VMasker.toPattern(value, "99999");
  }
  if (field === "creditCardNumber") {
    return VMasker.toPattern(value, "9999 9999 9999 9999");
  }
  if (field === "creditCardExpireDate") {
    return VMasker.toPattern(value, "99/99");
  }
  if (field === "averageSale") {
    return VMasker.toPattern(value, "999999");
  }
  if (field === "highestSale") {
    return VMasker.toPattern(value, "999999");
  }
  if (field === "cardSales") {
    return VMasker.toPattern(value, "999999");
  }

  if (field === "phone") {
    return VMasker.toPattern(value, "+19999999999");
  }
  if (field === "phone2") {
    return VMasker.toPattern(value, "+19999999999");
  }

  return value;
};
