const styles = theme => ({
  root: {},
  tableContainer: {
    width: "100%"
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4)
  }
});

export default styles;
