import React, { useState, useEffect, FC } from "react";
import Firebase, { withFirebase } from "../../../Firebase";
import { doc, onSnapshot, updateDoc } from "@firebase/firestore";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Title from "../../Title";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { deleteObject } from "firebase/storage";

// Enum for Document Status
const DocumentStatus = {
  InQueue: "InQueue",
  InProgress: "InProgress",
  Complete: "Complete",
};

// CSS Styles
const styles = () => ({
  root: {
    "& .MuiTextField-root": {
      margin: "1rem",
    },
  },
  table: {
    minWidth: 650,
  },
  uploadLink: {
    textDecoration: "none",
    color: "#3581c9",
    cursor: "pointer",
  },
});

interface IApplicationTrackerProps {
  firebase: Firebase;
  applicationId: string;
  classes: any;
}

interface LocalDocument {
  documentId: string;
  fileName: string;
  checked?: boolean;
  updated: string;
}

interface UserFile {
  id: string;
  filename: string;
  link: string;
  uploadedAt: string;
}

interface ApplicationData {
  id: string;
  agent?: string;
  userFiles?: UserFile[];
  localDocuments?: { [key: string]: LocalDocument };
  localDocumentStatus?: string;
  busy: boolean;
}

const ApplicationTracker: FC<IApplicationTrackerProps> = ({
  firebase,
  applicationId,
  classes,
}) => {
  const [application, setApplication] = useState<ApplicationData>();
  const [checkedDocuments, setCheckedDocuments] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const disabledDocuments = ['epp', 'credit_card_auth'];

  useEffect(() => {
    if (!applicationId) return;

    const unsubscribe = onSnapshot(
      doc(firebase.firestore, "applications", applicationId),
      (document) => {
        if (document.exists()) {
          const data = document.data() as ApplicationData;
          setApplication(data);
          const initialCheckedState: { [key: string]: boolean } = {};
          Object.keys(data.localDocuments || {}).forEach((key) => {
            initialCheckedState[key] = !disabledDocuments.includes(key) && !!data.localDocuments![key].checked;
          });
          setCheckedDocuments(initialCheckedState);
        }
      }
    );

    return () => unsubscribe();
  }, [applicationId, firebase.firestore]);

  const handleGeneratePDFs = async () => {
    setLoading(true);
    const documentRef = doc(firebase.firestore, "applications", applicationId);
    await updateDoc(documentRef, {
      localDocumentStatus: DocumentStatus.InQueue,
    });
    setLoading(false);
  };

  const handleCheckboxChange = async (key: string, checked: boolean) => {
    setCheckedDocuments((prevState) => ({ ...prevState, [key]: checked }));
    const documentRef = doc(firebase.firestore, "applications", applicationId);
    await updateDoc(documentRef, {
      [`localDocuments.${key}.checked`]: checked,
    });
  };

  // Sort documents by fileName
  const sortDocumentsByName = (documents: { [key: string]: LocalDocument }) => {
    return Object.entries(documents).sort((a, b) => {
      const nameA = a[1].fileName.toUpperCase();
      const nameB = b[1].fileName.toUpperCase();
      return nameA.localeCompare(nameB);
    });
  };

  const handleReplacePDF = async (key: string, file: File) => {
    setLoading(true);
    setLoadingType(key);
    const storageRef = ref(
      firebase.storage,
      `applications/${applicationId}/${file.name}`
    );
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      const documentRef = doc(
        firebase.firestore,
        "applications",
        applicationId
      );
      await updateDoc(documentRef, {
        [`localDocuments.${key}.documentId`]: downloadURL,
        [`localDocuments.${key}.updated`]: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
    setLoading(false);
    setLoadingType("");
  };

  const handleFileSelect = async (key: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = async (e) => {
      if (
        e?.target instanceof HTMLInputElement &&
        e.target.files &&
        e.target.files.length
      ) {
        const file = e.target.files[0];
        if (file) {
          await handleReplacePDF(key, file);
        }
      }
    };
    input.click();
  };

  const renderGenerateButton = (regenerate = false) => (
    <Button
      variant="contained"
      color="primary"
      onClick={handleGeneratePDFs}
      disabled={loading}
    >
      {loading && !loadingType ? (
        <CircularProgress size={24} color="inherit" />
      ) : regenerate ? (
        "Re-generate PDFs (override all)"
      ) : (
        "Generate PDFs"
      )}
    </Button>
  );

  const generateUID = () => {
    const timestamp = Date.now().toString(36);
    const random = Math.random()
      .toString(36)
      .substr(2);
    return timestamp + random;
  };

  const renderStatusMessage = (statusMessage: string) => (
    <h3>
      <CircularProgress size={24} color="inherit" />&nbsp;
      {statusMessage}
    </h3>
  );

  const handleUploadFile = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = async (e) => {
        const newId = generateUID();
      if (
        e?.target instanceof HTMLInputElement &&
        e.target.files &&
        e.target.files.length
      ) {
        const file = e.target.files[0];
        const storageRef = ref(
          firebase.storage,
          `userFiles/${applicationId}/${newId}-${file.name}`
        );
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        // Update application's user files in Firestore
        const documentRef = doc(
          firebase.firestore,
          "applications",
          applicationId
        );
        const newFile = {
          id: newId,
          filename: file.name,
          link: downloadURL,
          uploadedAt: new Date().toISOString(),
        };
        let files = application?.userFiles || [];
        files.push(newFile);
        await updateDoc(documentRef, {
          userFiles: files,
        });
      }
    };
    input.click();
  };

  const handleRemoveFile = async (id: string) => {
    // Find the file to be removed
    const fileToRemove = application?.userFiles?.find(file => file.id === id);
    if (!fileToRemove) {
      console.error('File not found');
      return;
    }
  
    // Remove file from Firebase storage
    const storageRef = ref(firebase.storage, `userFiles/${applicationId}/${id}-${fileToRemove.filename}`);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error("Error removing file from storage:", error);
      return;
    }
  
    // Update application's user files in Firestore
    const updatedFiles = application?.userFiles?.filter(file => file.id !== id);
    const documentRef = doc(firebase.firestore, "applications", applicationId);
    await updateDoc(documentRef, {
      userFiles: updatedFiles,
    });
  };
  

  return (
    <div className={classes.root}>
      <Title h1={"Addendums"} />
      <div>Application id: {application?.id}</div>
      <h2>Choose Auto-Generated PDFs:</h2>
      {!application?.localDocumentStatus && renderGenerateButton()}
      {application?.localDocumentStatus == DocumentStatus.Complete &&
        renderGenerateButton(true)}
      {!application?.localDocumentStatus &&
        !application?.localDocuments?.length && (
          <h3>You don't have any documents.</h3>
        )}
      {application?.localDocumentStatus === DocumentStatus.InQueue &&
        !application?.localDocuments?.length &&
        renderStatusMessage(
          "Your documents will be generated soon. Please wait..."
        )}
      {application?.localDocumentStatus === DocumentStatus.InProgress &&
        renderStatusMessage("Your documents are being generated...")}
      {application?.localDocuments &&
        Object.keys(application.localDocuments).length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Name</TableCell>
                  <TableCell>Download Link</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Uploaded</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortDocumentsByName(application.localDocuments).map(
                  ([key, value]) => (
                    <TableRow key={key}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={checkedDocuments[key] || false}
                          onChange={(e) =>
                            handleCheckboxChange(key, e.target.checked)
                          }
                          color="primary"
                          disabled={disabledDocuments.includes(key)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {value.fileName}
                      </TableCell>
                      <TableCell>
                        <a
                          href={value.documentId}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          className={classes.uploadLink}
                          onClick={() => handleFileSelect(key)}
                        >
                          Replace PDF
                        </a>
                      </TableCell>
                      <TableCell>
                        {loading && loadingType === key ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : value.updated ? (
                          new Date(value.updated).toLocaleString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        ) : (
                          "Auto-generated"
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    
      <h2>Add more documents:</h2>
      <Button variant="contained" color="primary" onClick={handleUploadFile}>
        Upload PDF
      </Button>
      {application?.userFiles?.length ? (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="user files table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Download Link</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Uploaded</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {application?.userFiles?.map((file) => (
              <TableRow key={file.id}>
                <TableCell component="th" scope="row">
                  {file.filename}
                </TableCell>
                <TableCell>
                  <a
                    href={file.link}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    className={classes.uploadLink}
                    onClick={() => handleRemoveFile(file.id)}
                  >
                    Remove
                  </a>
                </TableCell>
                <TableCell>
                  {new Date(file.uploadedAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ):
        <h3>You don't have any documents.</h3>
      }
    </div>
  );
};

export default withFirebase(withStyles(styles)(ApplicationTracker));
