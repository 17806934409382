import { CustomPricingType } from './pricingType';

const toCamelCase = (str: string) => {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      .replace(/^[^a-zA-Z]+/, "");
  };
  
function determineValueType(defaultValue: any) {
if (typeof defaultValue === 'string') {
    if (defaultValue.includes('$')) {
    return '$';
    } else if (defaultValue.includes('%')) {
    return '%';
    }
}
return '';
}

function cleanUpDefaultValue(defaultValue: any) {
if (typeof defaultValue === 'string') {
    const numbers = defaultValue.match(/[0-9.]+/g);
    if (numbers && numbers.length > 0) {
    return numbers[0];
    }
    return '';
}
return defaultValue;
}

const mapFields = (arr : any) => {
    return arr.map((item: any) => ({
      defaultValue: cleanUpDefaultValue(item.defaultValue),
      category: item.category.replace("Fee Information / ", ""),
      name: item.field,
      camelCaseName: toCamelCase(item.field),
      hidden: false,
      valueType: determineValueType(item.defaultValue),
      camelCaseCategory: toCamelCase(item.category),
      inputName: `${toCamelCase(item.category)}-${toCamelCase(item.field)}`,
      order: item.order
    }));
  }

let flatFields = mapFields([
    { field: "Credit Card Discount Rate", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "3.85%", order: 2 },
    { field: "Offline-Debit Discount Rate", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "3.85%", order: 1 },
    { field: "Discount Rate", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "3.85%", order: 3 },
    { field: "Debit Access Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$0.00", order: 0 },
    { field: "Debit Card Tran Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$0.35", order: 5 },
    { field: "Network Fee Plus", category: "Fee Information / Miscellaneous Fees", defaultValue: "0", order: 0 },
    { field: "Regulatory Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$4.95", order: 7 },
    { field: "ESP Monthly Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$0.00", order: 0 },
    { field: "PCI Non-Action Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$59.95", order: 9 },
    { field: "Annual Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$99.95", order: 10 },
    { field: "Online Access Fee (per month)", category: "Fee Information / Monthly Fees", defaultValue: "$0.00", order: 0 },
    { field: "Customer Service / Statement Fee", category: "Fee Information / Monthly Fees", defaultValue: "$9.95", order: 6 },
    { field: "Visa/MC/Disc Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.00", order: 4 },
    { field: "AVS Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.00", order: 0 },
    { field: "Batch Header Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.30", order: 8 },
  ]);
  
  let flatHiddenFields = mapFields([
    { field: "ACH Return Item Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$35.00", order: 0 },
    { field: "ACH Change Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$10.00", order: 0 },
    { field: "Security Research Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25.00", order: 0 },
    // became visible
    /*
    { field: "Early Termination Fee - Year 1", category: "Fee Information / Miscellaneous Fees", defaultValue: "$350.00", order: 0 },
    { field: "Early Termination Fee - Year 2", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
    { field: "Early Termination Fee - Year 3", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
     */
    { field: "Retrieval Request Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$15.00", order: 0 },
    { field: "Chargeback Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25.00", order: 0 },
    { field: "Monthly Minimum Discount Fee", category: "Fee Information / Monthly Fees", defaultValue: "$25.00", order: 0 },
    { field: "Voice Auth Fee", category: "Fee Information / Processing Fees", defaultValue: "$2.00", order: 0 },
    
    { field: "Other Item Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.00", order: 0 },
    { field: "System Processing Fee", category: "Fee Information / American Express Opt Blue", defaultValue: "0", order: 0 },
    { field: "Other Item Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.00", order: 0 },
    { field: "Regulatory Non-Compliance Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "30", order: 0 },
    { field: "Interchange Clearing Fee", category: "Fee Information / Processing Fees", defaultValue: "0", order: 0 },

    { field: "Amex Direct Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "0.00", order: 0 },
  ]);
  
  let tierFields = mapFields([
    { field: "Qualified Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "1.69%", order: 4 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "$0.10", order: 14 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "$0.10", order: 15 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "2%", order: 5 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "2.75%", order: 6 },
    { field: "Qualified Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "1.69%", order: 1 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.10", order: 12 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.10", order: 13 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "2.75%", order: 3 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "2%", order: 2 },
    { field: "System Processing Fee", category: "Fee Information / American Express Opt Blue", defaultValue: "0.70%", order: 10 },
    { field: "Qualified Discount Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "1.69%", order: 7 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.10", order: 16 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.10", order: 17 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "2%", order: 8 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "2.75%", order: 9 },
    { field: "Interchange Clearing Fee", category: "Fee Information / Processing Fees", defaultValue: "0.10%", order: 0 },
    { field: "Debit Card Tran Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$0.35", order: 18 },
    { field: "Regulatory Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$4.95", order: 20 },
    { field: "PCI Non-Action Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$59.95", order: 22 },
    { field: "Annual Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$99.95", order: 23 },
    { field: "Customer Service / Statement Fee", category: "Fee Information / Monthly Fees", defaultValue: "$9.95", order: 19 },
    { field: "Visa/MC/Disc Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.20", order: 11 },
    { field: "Batch Header Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.30", order: 21 },
  ]);
  
  let tierHiddenFields = mapFields([
    { field: "Other Item Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "$0.00", order: 0 },
    { field: "Other Item Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.00", order: 0 },
    { field: "Other Item Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.00", order: 0 },
    { field: "Chargeback Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25.00", order: 0 },
    { field: "Monthly Minimum Discount Fee", category: "Fee Information / Monthly Fees", defaultValue: "$25.00", order: 0 },
    { field: "AVS Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.05", order: 0 },
    { field: "Online Access Fee (per month)", category: "Fee Information / Miscellaneous Fees", defaultValue: "0", order: 0 },
    { field: "MasterCard Location Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "1.25", order: 0 },

    { field: "Voice Auth Fee", category: "Fee Information / Processing Fees", defaultValue: "$2", order: 0 },
    { field: "ACH Return Item Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$35", order: 0 },
    { field: "ACH Change Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$10", order: 0 },
    { field: "Security Research Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25", order: 0 },
    { field: "Regulatory Non-Compliance Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "30", order: 0 },
    // became visible
    /*
    { field: "Early Termination Fee - Year 1", category: "Fee Information / Miscellaneous Fees", defaultValue: "$350.00", order: 0 },
    { field: "Early Termination Fee - Year 2", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
    { field: "Early Termination Fee - Year 3", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
     */

    { field: "Retrieval Request Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$15.00", order: 0 },
    { field: "Amex Direct Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "0.00", order: 0 },
  ]);

  let icFields = mapFields([
    { field: "System Processing Fee", category: "Fee Information / American Express Opt Blue", defaultValue: "0.70%", order: 0 },
    { field: "Pass-Through Pricing Plus", category: "Fee Information / Amex Interchange Pass-Through Rates", defaultValue: "1.00%", order: 3 },
    { field: "Offline-Debit Additional Basis Point", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "1.00%", order: 1 },
    { field: "Credit Additional Basis Point", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "1.00%", order: 2 },
    { field: "Interchange Clearing Fee", category: "Fee Information / Processing Fees", defaultValue: "0.10%", order: 0 },
    { field: "Debit Card Tran Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$0.35", order: 5 },
    { field: "Regulatory Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$4.95", order: 7 },
    { field: "PCI Non-Action Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$59.95", order: 9 },
    { field: "Annual Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$99.95", order: 10 },
    { field: "Customer Service / Statement Fee", category: "Fee Information / Monthly Fees", defaultValue: "$9.95", order: 6 },
    { field: "Visa/MC/Disc Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.20", order: 4 },
    { field: "Batch Header Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.30", order: 8 },
    { field: "Other Item Fee", category: "Fee Information / Amex Interchange Pass-Through Rates", defaultValue: "0.00%", order: 3 },
    { field: "Credit Other Item Fee", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "0.00%", order: 3 },
    { field: "Offline-Debit Other Item Fee", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "0.00%", order: 3 },
  ]);

  let icHiddenFields = mapFields([
    // became visible
    /*
    { field: "Other Item Fee", category: "Fee Information / Amex Interchange Pass-Through Rates", defaultValue: "$0.00", order: 0 },
    { field: "Credit Other Item Fee", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "$0.00", order: 0 },
    { field: "Offline-Debit Other Item Fee", category: "Fee Information / Interchange Pass-Through Rates", defaultValue: "$0.00", order: 0 },
     */
    { field: "Chargeback Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25.00", order: 0 },
    { field: "Monthly Minimum Discount Fee", category: "Fee Information / Monthly Fees", defaultValue: "$25.00", order: 0 },
    { field: "AVS Fee", category: "Fee Information / Processing Fees", defaultValue: "$0.05", order: 0 },
    { field: "Online Access Fee (per month)", category: "Fee Information / Miscellaneous Fees", defaultValue: "0", order: 0 },
    { field: "MasterCard Location Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "1.25", order: 0 },
    { field: "Retrieval Request Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$15.00", order: 0 },
    //additional required
    { field: "Qualified Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "1.69%", order: 4 },
    { field: "Qualified Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "1.69%", order: 1 },
    { field: "Voice Auth Fee", category: "Fee Information / Processing Fees", defaultValue: "$2.00", order: 0 },
    { field: "ACH Return Item Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$35.00", order: 0 },
    { field: "ACH Change Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$10", order: 0 },
    { field: "Security Research Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "$25.00", order: 0 },
    { field: "Regulatory Non-Compliance Fee", category: "Fee Information / Miscellaneous Fees", defaultValue: "30", order: 0 },
    // became visible
    /*
    { field: "Early Termination Fee - Year 1", category: "Fee Information / Miscellaneous Fees", defaultValue: "$350.00", order: 0 },
    { field: "Early Termination Fee - Year 2", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
    { field: "Early Termination Fee - Year 3", category: "Fee Information / Miscellaneous Fees", defaultValue: "$250.00", order: 0 },
     */
    { field: "Qualified Discount Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "1.69%", order: 7 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "$0.10", order: 14 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.10", order: 12 },
    { field: "Mid-Qualified Transaction Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.10", order: 16 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "$0.10", order: 15 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "$0.10", order: 13 },
    { field: "Non-Qualified Transaction Fee", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "$0.10", order: 17 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "2.75%", order: 6 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "2.75%", order: 3 },
    { field: "Non-Qualified Fee: Qual+", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "2.75%", order: 9 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Credit Card Discount Fees", defaultValue: "2%", order: 5 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / 3-Tier Offline-Debit Discount Fees", defaultValue: "2%", order: 2 },
    { field: "Mid-Qualified Fee: Qual+", category: "Fee Information / Amex 3-Tier Rates", defaultValue: "2%", order: 8 },  
    { field: "Amex Direct Tran Fee", category: "Fee Information / Processing Fees", defaultValue: "0.00", order: 0 },
  ]);

  let commonFields = mapFields([
    { field: "Retail Swiped/EMV", category: "Business Information / Processing Breakdown", defaultValue: "95%", order: 1 },
    { field: "Retail Keyed %", category: "Business Information / Processing Breakdown", defaultValue: "5%", order: 2 },
    { field: "Internet %", category: "Business Information / Processing Breakdown", defaultValue: "0%", order: 3 },
    { field: "MOTO %", category: "Business Information / Processing Breakdown", defaultValue: "0%", order: 4 },
  ]);

  const fields = {
    [CustomPricingType.Flat]: flatFields,
    [CustomPricingType.ThreeTier]: tierFields,
    [CustomPricingType.ICPlus]: icFields,    
  };

  const getFields = (fieldType: CustomPricingType, customCategory: any) => {
    if (fieldType) {
      const fieldsByType = fields[fieldType];
      return fieldsByType
        .filter((f: { order: number; }) => f.order >= customCategory.min && f.order <= customCategory.max)
        .sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);
    }
    else {
      return [];
    }
  }

  const customCategories = {
    [CustomPricingType.Flat]: [
      {
        name: 'Rates',
        min: 1,
        max: 4,
        order: 1,
      },
      {
        name: 'Trans Fees',
        min: 5,
        max: 6,
        order: 2,
      },
      {
        name: 'Misc. Fees',
        min: 7,
        max: 11,
        order: 3,
      },
      {
        name: 'Other',
        min: 0,
        max: 0,
        order: 4,
      },
    ],
    [CustomPricingType.ThreeTier]: [
      {
        name: 'Debit Rates',
        min: 1,
        max: 3,
        order: 1,
      },
      {
        name: 'Credit Rates',
        min: 4,
        max: 6,
        order: 2,
      },
      {
        name: 'Amex Rates',
        min: 7,
        max: 10,
        order: 3,
      },
      {
        name: 'Trans Fees',
        min: 11,
        max: 18,
        order: 4,
      },
      {
        name: 'Misc. Fees',
        min: 19,
        max: 23,
        order: 5,
      },
      {
        name: 'Other',
        min: 0,
        max: 0,
        order: 6,
      },
    ],
    [CustomPricingType.ICPlus]: [
      {
        name: 'Rates',
        min: 1,
        max: 3,
        order: 1,
      },
      {
        name: 'Trans Fees',
        min: 4,
        max: 5,
        order: 2,
      },
      {
        name: 'Misc. Fees',
        min: 6,
        max: 10,
        order: 3,
      },
      {
        name: 'Other',
        min: 0,
        max: 0,
        order: 4,
      },
    ]
  };

  const setHiddenPricingFields = (application: any) => {
    if (!application.customPricing) {
      application.customPricing = {};
    } else {
      const allHiddenFields = [...flatHiddenFields, ...tierHiddenFields, ...icHiddenFields];
      const excludeFields = fields[application.customPricingType as CustomPricingType].map((f: { inputName: string; }) => f.inputName);
  
      allHiddenFields
        .filter(hiddenField => !excludeFields.includes(hiddenField.inputName))
        .forEach((field: any) => {
          delete application.customPricing[field.field];
        });
    }

    if (application.customPricingType === CustomPricingType.Flat) {
        flatHiddenFields.forEach((field: any) => {
            application.customPricing[field.inputName] = field.defaultValue;
        });
    }
    if (application.customPricingType === CustomPricingType.ThreeTier) {
      tierHiddenFields.forEach((field: any) => {
          application.customPricing[field.inputName] = field.defaultValue;
      });
    }
    if (application.customPricingType === CustomPricingType.ICPlus) {
      icHiddenFields.forEach((field: any) => {
          application.customPricing[field.inputName] = field.defaultValue;
      });
    }
  }

  export default {flatFields, icFields, tierFields, commonFields, setHiddenPricingFields, customCategories, getFields}