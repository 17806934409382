const styles = theme => ({
  jsonPaper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  jsonView: {
    margin: theme.spacing(1),
    height: 384,
    overflow: "scroll"
  },
  statusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  check: {
    height: 24,
    width: 24
  },
  taskTime: {
    marginRight: theme.spacing(4)
  },
  expansionDetails: {
    display: "flex",
    flexDirection: "column"
  }
});

export default styles;
