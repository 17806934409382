import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import Title from "../../Title";

import styles from "../styles";

class Account extends PureComponent {
  render() {
    const {
      application,
      onApplicationTextChange,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        <Title
          h1={"Account Info"}
          h2={"Please enter details on how your business operates."}
        />
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="averageSale"
              label="Average Sales Amount Per Customer"
              variant="outlined"
              className={classes.sideMargins}
              type="number"
              value={application.averageSale || ""}
              onChange={onApplicationTextChange("averageSale")}
              error={!!errors.averageSale}
              helperText={errors.averageSale}
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="highestSale"
              label="Highest Estimated Sales Amount Per Customer"
              variant="outlined"
              className={classes.sideMargins}
              type="number"
              value={application.highestSale || ""}
              onChange={onApplicationTextChange("highestSale")}
              error={!!errors.highestSale}
              helperText={errors.highestSale}
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="cardSales"
              label="Estimated Monthly Credit and Debit Card Sales"
              variant="outlined"
              className={classes.sideMargins}
              type="number"
              value={application.cardSales || ""}
              onChange={onApplicationTextChange("cardSales")}
              error={!!errors.cardSales}
              helperText={errors.cardSales}
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Account);
