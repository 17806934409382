import axios from "axios";

export default class ZipTaxApi {
  static async getTaxes(zipCode) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/zipTax/?zipCode=${zipCode}`
    );
    return Promise.resolve(response.data);
  }
}
