import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import Title from "../../Title";

import styles from "../styles";

class Financial extends PureComponent {
  render() {
    const {
      application,
      onApplicationTextChange,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        {application.isCheckout ? (
          <Title h2={"Where do you want your daily deposits?"} />
        ) : (
          <Title
            h1={"Financial Info"}
            h2={
              "Please enter your financial information so we can deposit money into your account."
            }
          />
        )}
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="routingNumber"
              label="Routing Number"
              placeholder="000000000"
              variant="outlined"
              className={classes.sideMargins}
              value={application.routingNumber || ""}
              onChange={onApplicationTextChange("routingNumber")}
              error={!!errors.routingNumber}
              helperText={errors.routingNumber}
              margin="normal"
              fullWidth
            />
            <TextField
              id="bankName"
              label="Bank Name"
              variant="outlined"
              className={classes.sideMargins}
              value={application.bankName || ""}
              onChange={onApplicationTextChange("bankName")}
              error={!!errors.bankName}
              helperText={errors.bankName}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="bankAccountNumber"
              label="Bank Account Number"
              placeholder="000000000"
              variant="outlined"
              className={classes.sideMargins}
              value={application.bankAccountNumber || ""}
              onChange={onApplicationTextChange("bankAccountNumber")}
              error={!!errors.bankAccountNumber}
              helperText={errors.bankAccountNumber}
              margin="normal"
              fullWidth
            />
            <TextField
              id="confirmBankAccountNumber"
              label="Re-enter Bank Account Number"
              placeholder="000000000"
              variant="outlined"
              className={classes.sideMargins}
              value={application.confirmBankAccountNumber || ""}
              onChange={onApplicationTextChange("confirmBankAccountNumber")}
              error={!!errors.confirmBankAccountNumber}
              helperText={errors.confirmBankAccountNumber}
              margin="normal"
              fullWidth
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Financial);
