import React from "react";

const HttpClientContext = React.createContext<any|null>(null);

export const withHttpClient = (Component: React.ComponentClass) => (props: any) => (
  <HttpClientContext.Consumer>
    {/* @ts-ignore */}
    {httpClient => <Component {...props} httpClient={httpClient} />}
  </HttpClientContext.Consumer>
);

export default HttpClientContext;
