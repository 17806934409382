const styles = theme => ({
  root: {
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    }
  },
  line1: {},
  line2: {
    fontSize: ".8em"
  }
});

export default styles;
