import React, { useState, FC, ReactElement } from 'react';
import { compose } from "recompose";
import { connect } from 'react-redux';
import { httpsCallable } from "@firebase/functions";
import Firebase, { withFirebase } from '../Firebase';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Snackbar } from "@material-ui/core";

interface IAuthUser {
    displayName: string;
    email: string;
    uid: string;
    metadata: any;
    isAdmin: boolean;
    algoliaPublicKey: string;
    role: string;
}
  
interface IResetPasswordDialog {
    authUser: IAuthUser;
    firebase: Firebase;
    open: boolean;
    userId: string;
    handleClose: () => void;
}

const ResetPasswordDialog: FC<IResetPasswordDialog> = ({ authUser, firebase, open, userId, handleClose }): ReactElement => {
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [message, setMessage] = useState('');

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        set(event.target.value);
    };

    const handleSubmit = async () => {
        if (authUser.isAdmin) {
            try {
              await httpsCallable(firebase.functions, "resetUserPassword")({ userId, password });
              setMessage("The new password successfully set.");
            } catch (error) {
              setErrors({ email: error });
              return;
            }
            handleClose();
        }
    }

    const handleSnackbarClose = () => {
        setMessage('');
    }

    return (<>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
            <DialogTitle id="form-dialog">Set New Password</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    margin="normal"
                    id="password"
                    name="password"
                    label="Password"
                    value={password}
                    helperText={errors.password}
                    error={Boolean(errors.password)}
                    onChange={handleTextChange(setPassword)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size="small">
                Cancel
                </Button>
                <Button onClick={handleSubmit} size="small" color="primary">
                Set Password
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            autoHideDuration={3000}
            open={!!message}
            onClose={handleSnackbarClose}
            message={message}
        />
    </>)
}

export default compose<IResetPasswordDialog, Element>(
    withFirebase,
    connect((state: any) => ({
        authUser: state.user,
        metadata: state.metadata
    }))
)(ResetPasswordDialog);
