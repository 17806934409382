import { combineReducers } from "redux";
import userReducer from "./userReducer";
import applicationReducer from "./applicationReducer";
import leadReducer from "./leadReducer";
import usersReducer from "./usersReducer";
import messageReducer from "./messageReducer";
import metadataReducer from "./metadataReducer";
import eventsReducer from "./eventsReducer";
import sourcesReducer from "./sourcesReducer";
import campaignsReducer from "./campaignsReducer";

const rootReducer = combineReducers({
  user: userReducer,
  messages: messageReducer,
  events: eventsReducer,
  applications: applicationReducer,
  leads: leadReducer,
  metadata: metadataReducer,
  users: usersReducer,
  sources: sourcesReducer,
  campaigns: campaignsReducer
});

export default rootReducer;
