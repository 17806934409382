import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3581c9"
    },
    secondary: {
      main: "#51b045",
      contrastText: "#ffffff"
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif;"
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontFamily: "'Roboto Slab', serif;",
        fontWeight: 300
      },
      h4: {
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif;",
        fontWeight: 200
      },
      body2: {
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif;",
        fontWeight: 700
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Open Sans', sans-serif;",
        fontSize: "22px"
      }
    },
    MuiAppBar: {
      colorPrimary: {
        color: "#586069"
      },
      root: {
        color: "#000",
        fill: "#000",
        backgroundColor: "#fff !important",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "none"
      }
    }
  }
});

export default theme;
