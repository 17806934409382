import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_USER_STATE_CHANGE:
    case ActionTypes.SIGN_IN_SUCCESS:
      const {
        user: {
          displayName,
          email,
          uid,
          metadata,
          isAdmin,
          algoliaPublicKey,
          role
        }
      } = action;
      return {
        ...state,
        creationTime: metadata.creationTime,
        displayName,
        email,
        uid,
        isAdmin,
        role,
        algoliaPublicKey
      };
    case ActionTypes.SIGN_OUT_SUCCESS: {
      return null;
    }
    case ActionTypes.SET_FIRESTORE_USER: {
      return { ...state, ...action.user, isLoaded: true };
    }
    default:
      return state;
  }
};
