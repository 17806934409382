import React, { useCallback, useState, FC, ReactElement, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import { Typography, Divider, Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ActiveFilter, FilterOption, Filters } from '../Filters';
import { FiltersPreview } from '../Filters/preview';
import { LeadClosingRatioReportFilters } from '../Filters/types';
import { toQuery } from '../../util/FilterUtil';
import DateFnsUtils from '@date-io/date-fns';
import ReportsApi from '../../api/Reports';
import _ from "lodash";
import moment from 'moment';

interface ILeadsClosingRatioWithFilter {
  displayFilter: boolean;
  httpClient: any;
  metadata: any;
  title: string;
}

export const LeadsClosingRatioWithFilter: FC<ILeadsClosingRatioWithFilter> = ({ displayFilter, httpClient, metadata, title }): ReactElement => {
  const reports = httpClient.reports as ReportsApi;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] = useState<ActiveFilter[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>(LeadClosingRatioReportFilters);

  const initFilterOptions = useCallback(() => {
    if (metadata?.sources) {
      const sourcesMap = new Map();
      metadata.sources.map((s: any) => sourcesMap.set(s.name, s.name));
      const sources = Array.from(sourcesMap).map(([key, value]) => ({ key, value }));
      const options = [...filterOptions];
      const sourceFilter = options.find(o => o.id === 'source');
      if (sourceFilter) {
        sourceFilter.options = sources;
        setFilterOptions(options);
      }
    }
    if (metadata?.campaigns) {
      const campaignsMap = new Map();
      metadata.campaigns.map((c: any) => campaignsMap.set(c.name, c.name));
      const campaigns = Array.from(campaignsMap).map(([key, value]) => ({ key, value }));
      const options = [...filterOptions];
      const campaignFilter = options.find(o => o.id === 'campaign');
      if (campaignFilter) {
        campaignFilter.options = campaigns;
        setFilterOptions(options);
      }
    }
    if (metadata?.campaignTypes) {
      const campaignTypesMap = new Map();
      metadata.campaignTypes.map((c: any) => campaignTypesMap.set(c.name, c.name));
      const campaignTypes = Array.from(campaignTypesMap).map(([key, value]) => ({ key, value }));
      const options = [...filterOptions];
      const campaignTypeFilter = options.find(o => o.id === 'campaignType');
      if (campaignTypeFilter) {
        campaignTypeFilter.options = campaignTypes;
        setFilterOptions(options);
      }
    }
  }, [metadata]);

  const loadReports = useCallback(async () => {
    if (metadata?.users) {
      const queryParams = toQuery(filters);
      const apps = await loadAppsReport(queryParams);
      const leads = await loadLeadsReport(queryParams);
      setData(calculateRatio(apps, leads));
      setExpanded(true);
    }
  }, [metadata, filters]);

  useEffect(() => {
    initFilterOptions();
  }, [initFilterOptions]);

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters([...filters.filter(f => f.option.type !== 'dateRange'), filter]);
  }

  const handleDeleteFilter = (index: number) => {
    const _filters = [...filters];
    _filters.splice(index, 1);
    setFilters(_filters);
  }

  const loadLeadsReport = async (query: string) => query.length
      ? await reports.leadsByFilter(query)
      : await reports.leadsByFilter(getDefaultQuery());

  const loadAppsReport = async (query: string) => query.length
    ? await reports.appsByFilter(query)
    : await reports.appsByFilter(getDefaultQuery());

  const getDefaultQuery = () => {
      const from = moment(new Date()).startOf('month').format('YYYY-MM-DD');
      const to = moment(new Date()).endOf('month').format('YYYY-MM-DD');
      return `createdAt__between=${from},${to}`;
  }

  const isSentToApi = (status: string): boolean => {
    return status.toLowerCase() === "sent to api";
  }

  const calculateRatio = (apps: any, leads: any): any => {
    const groupedApps = _.groupBy(apps, e => e.uid);
    console.log('groupedApps', groupedApps);
    const result = leads.reduce((prev: any, current: any) => {
      const user = current.uid ? metadata.users[current.uid] : { firstName: "Unknown", lastName: "", photoURL: "" };
      const app = groupedApps[user.id?.toString() || ''];
      if (!app) {
        return [
          ...prev,
          {
            user,
            submitted: 0,
            count: current.count,
            ratio: 0,
            closedRatio: 0
          }
        ];
      }
      const calculated = app.reduce(
        (p, c) => {
          if (isSentToApi(c.status)) {
            p.submitted += c.count;
          }
          return p;
        },
        { user, submitted: 0, count: current.count }
      );
      calculated.closedRatio = (
        (calculated.submitted / current.count) *
        100
      ).toFixed(2);
      return [...prev, calculated];
    }, []);

    return result;
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="overline">{title}</Typography>
      </AccordionSummary>
      {displayFilter &&
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <FiltersPreview filters={filters} onDeleteFilter={handleDeleteFilter} />
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Filters options={filterOptions} onApplyFilter={handleApplyFilter} />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      }
      {displayFilter &&
        <Divider />
      }
      <AccordionDetails>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rep Name</TableCell>
              <TableCell># of Leads</TableCell>
              <TableCell>Submitted</TableCell>
              <TableCell>Closing Ratio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    <Avatar
                      alt={`${row.user.firstName} ${row.user.lastName}`}
                      src={row.user.photoURL}
                    />
                  </TableCell>
                  <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.submitted}</TableCell>
                  <TableCell>{`${row.closedRatio}%`}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}

export default compose<ILeadsClosingRatioWithFilter, Element>(
  withHttpClient,
  connect((state: any) => {
    return ({
    metadata: state.metadata
  })})
)(LeadsClosingRatioWithFilter);
