import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import { Typography, Divider } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Chart } from "react-google-charts";
import moment from "moment";
import _ from "lodash";

const tooltip = (yearMonth, column) => {
  return ReactDOMServer.renderToStaticMarkup(
    <div style={{ padding: 8, width: 200 }}>
      <Typography variant="body2" align={"center"} gutterBottom>
        {moment(`${yearMonth}01`).format("MMMM YYYY")}
      </Typography>
      {Object.keys(column).map(e => {
        return (
          <p key={e}>
            {e}: <b>{column[e]}</b>
          </p>
        );
      })}
    </div>
  );
};

class AppsByMonth extends Component {
  state = {
    chart: [],
    expanded: false
  };
  componentDidMount = async () => {
    await this.getChart();
  };
  getChart = async () => {
    const data = await this.props.httpClient.reports.appsByMonth();
    const statuses = _.uniq(data.map(e => e.activationStatus));
    const grouped = _.groupBy(data, e => e.createdAt);
    const chart = Object.keys(grouped).map(e => {
      const column = {};
      grouped[e].forEach(c => (column[c.activationStatus] = c.count));
      const countArray = statuses.map(s => (column[s] ? column[s] : 0));
      return [
        moment(`${e}01`).format("MMM YYYY"),
        _.sum(countArray),
        tooltip(e, column)
      ];
    });
    const columns = [
      {
        type: "string",
        label: "Month"
      },
      {
        type: "number",
        label: "Total"
      },
      { role: "tooltip", type: "string", p: { html: true } }
    ];
    this.setState({ chart, columns, expanded: true });
  };
  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { chart, columns, expanded } = this.state;
    return (
      <Accordion expanded={expanded} onChange={this.handleExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="overline">Application Count</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Chart
            width={"100%"}
            height={"250px"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            columns={columns}
            rows={chart}
            options={{
              tooltip: { isHtml: true, trigger: "visible" },
              legend: { position: "none" }
            }}
            rootProps={{ "data-testid": "1" }}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {}
              }
            ]}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(withHttpClient)(AppsByMonth);
