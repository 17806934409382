import React, { useState, FC, ReactElement, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from 'react-redux';
import Firebase, { withFirebase } from '../Firebase';
import { collection, query, orderBy, onSnapshot } from '@firebase/firestore';
import { withLayout } from "../Layout";
import { withAuthorization } from "../Session";
import DataTable from "../DataTableInfiniteScroll";
import { ClassNameMap } from "@material-ui/styles";
import { Button, TextField, Typography, withStyles } from "@material-ui/core";
import _ from 'lodash';
import styles from "./styles";
import { CreateSourceDialog } from './CreateSourceDialog';
import { SourcePanel } from './SourcePanel';

interface ISourcesPage {
    classes: ClassNameMap<any>;
    firebase: Firebase;
    sourcesProps: any;
    user: any;
}

const SourcesPage: FC<ISourcesPage> = ({ classes, firebase, sourcesProps, user }): ReactElement => {
    const [allSources, setAllSources] = useState<any>([]);
    const [sources, setSources] = useState<any>(sourcesProps);
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [row, setRow] = useState<any>(null);

    console.log('sourcesProps', sourcesProps)

    useEffect(() => {
        loadSources();
    }, []); // eslint-disable-line

    useEffect(() => {
        filterSources();
    }, [sources.order, sources.page, sources.where]); // eslint-disable-line

    const loadSources = async () => {
        let q = query(collection(firebase.firestore, "sources"));
        q = query(q, orderBy('name'));

        onSnapshot(q, snapshot => {
            if (snapshot.docs.length) {
                const results = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setAllSources(results);
                setSources({ ...sourcesProps, data: results.slice(0, sources.rowsPerPage) });
            }
        });
    };

    const filterSources = () => {
        let data = allSources;

        if (sources.where) {
            data = _.filter(allSources, o => 
                (o.name && o.name.toUpperCase().indexOf(sources.where.toUpperCase()) !== -1) ||
                (o.campaign && o.campaign.toUpperCase().indexOf(sources.where.toUpperCase()) !== -1)
            );
        }

        data = _.orderBy(data, sources.orderBy, sources.order);
        data = data.slice(0, sources.rowsPerPage * (sources.page + 1));

        setSources({ ...sources, data });
    }

    const handleSearchTextChanged = (event: any) => {
        setSources({
            ...sources,
            page: 0,
            where: event.target.value || null
        });
    }

    const handleRetrieveData = (searchCriteria: any) => {
        setSources({
            ...sources,
            order: searchCriteria.order,
            page: 0
        });
    }
    
    const handleNextPageLoad = () => {
        setSources({
            ...sources,
            page: sources.page + 1
        });
    }

    const handleReset = () => {
        setSources({
            ...sources,
            page: 0,
            where: null
        });
    }

    const handleSourceCreate = (open: boolean) => () => {
        setCreateDialog(open);
    }

    const handleSourceCreated = async () => {
        setCreateDialog(false);
    }

    const handleRowClick = (selectedRow: any, isAlreadySelected: boolean) => {
        setRow(isAlreadySelected ? null : selectedRow);
    }

    return (
        <div>
            <div className={classes.titleBar}>
                <Typography variant="h4">Sources</Typography>
                <div className={classes.actions}>
                    {user.isAdmin && (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleSourceCreate(true)}
                        >
                            Create
                        </Button>
                    )}
                </div>
                {createDialog && (
                    <CreateSourceDialog
                        authUser={user}
                        firebase={firebase}
                        open={createDialog}
                        handleClose={handleSourceCreate(false)}
                        handleRefresh={handleSourceCreated}
                    />
                )}
            </div>
            <div className={classes.toolbar}>
                <div className={classes.search}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        onChange={handleSearchTextChanged}
                        value={sources.where || ''}
                    />
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={handleReset}
                        disabled={!sources.where}
                        style={{ marginLeft: '8px' }}
                    >
                        Reset Filters
                    </Button>
                </div>
            </div>            
            <div className={classes.container}>
                {/* @ts-ignore */}
                <DataTable
                    title="Sources"
                    data={sources}
                    emptyMessage="empty"
                    onFilterMenuItemClick={() => {}}
                    onRetrieveData={handleRetrieveData}
                    onRowClick={handleRowClick}
                    onNext={handleNextPageLoad}
                    hasMore={true}
                    loading={false}
                    distanceBottom={1500}
                    disableFilters={true}
                />
                {user.isAdmin && row && (
                    <SourcePanel
                        classes={classes}
                        firebase={firebase}
                        source={row}
                        sources={allSources}
                    />
                )}
            </div>
        </div>
    );
}

const condition = (user: any) => !!user;

export default compose<ISourcesPage, Element>(
    withLayout,
    withFirebase,
    withAuthorization(condition),
    withStyles(styles as any),
    connect((state: any) => ({
      sourcesProps: state.sources
    }))
)(SourcesPage);
