import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Radio, Grid } from "@material-ui/core";
import Title from "../../Title";
import styles from "../styles";

class Plan extends PureComponent {
  render() {
    const { application, onCheckChange, classes } = this.props;
    return (
      <div className={classes.root}>
        <Title h1={"Select Pricing Plan"} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Traditional Pricing</Typography>
            <div className={classes.option}>
              <Radio
                checked={application.plan === "0"}
                onChange={onCheckChange}
                value="0"
                color="primary"
              />
              <div className={classes.rate}>
                <Typography variant="body1" gutterBottom>
                  Swiped Rate: <b>1.69%</b>, Keyed Rate: <b>2.45%</b>
                </Typography>
                <Typography variant="body1">
                  $0.19 cents per transaction, $9.95 per month.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Cash Discount & Surcharging
            </Typography>
            <div className={classes.option}>
              <Radio
                checked={application.plan === "1"}
                onChange={onCheckChange}
                value="1"
                color="primary"
              />
              <div className={classes.rate}>
                <Typography variant="body1" gutterBottom>
                  4% is passed to your customer.
                </Typography>
                <Typography variant="body1">$29.95 per month.</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Plan);
