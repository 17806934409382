import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../Session";

import { Typography } from "@material-ui/core";
import { httpsCallable } from "@firebase/functions";
import Stepper from "./Stepper";
import Actions from "./RepActions";
import Start from "./steps/Start";
import Business from "./steps/Business";
import Principal from "./steps/Principal";
import Account from "./steps/Account";
import Financial from "./steps/Financial";
import { mask } from "../../util/MaskUtil";
import { validateStep } from "../../util/ValidationUtil";
import Cart from "./steps/Cart";

import { withFirebase } from "../Firebase";

import IpAddressApi from "../../api/IpAddress";

import styles from "./styles";
import {
  addDoc,
  onSnapshot,
  setDoc,
  doc,
  collection
} from "@firebase/firestore";

class Application extends Component {
  constructor(props) {
    super(props);
    let application = {
      repStarted: true,
      activeStep: 0,
      status: "Incomplete",
      shippingDifferent: false,
      residenceDifferent: false,
    };

    this.state = {
      activeStep: 0,
      loading: false,
      savingNewApplication: false,
      promptResume: null,
      user: null,
      application,
      transaction: undefined,
      linkId: props.linkId,
      errors: {}
    };
  }

  componentDidMount = async () => {
    const { linkId } = this.props;
    const firestore = this.props.firebase.firestore;
    if (linkId) {
      onSnapshot(doc(firestore, "links", linkId), snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data();
          this.setState({
            application: {
              ...this.state.application,
              agent: 0, //parseInt(data.agent.irisUserId),
              link: { ...data, id: snapshot.id },
              uid: data.uid,
              processor: data.processor || "paysafe"
            }
          });
        }
      });
    }
  };

  handleResetSsn = () => {
    const application = { ...this.state.application };
    application.encryptedSocialSecurityNumber = "";
    this.setState({ application });
  };

  createApplication = async application => {
    this.setState({ savingNewApplication: true, loading: true });

    const ipAddressResults = await IpAddressApi.getIpAddress();

    const newApplication = {
      ...application,
      activeStep: 1,
      createdAt: new Date(),
      repIpAddress: ipAddressResults.ipAddress
    };

    const firestore = this.props.firebase.firestore;
    const d = await addDoc(
      collection(firestore, "repApplications"),
      newApplication
    );

    this.setState({
      savingNewApplication: false,
      loading: false,
      application: {
        ...newApplication,
        id: d.id
      }
    });
    return d.id;
  };

  saveApplication = async application => {
    const firestore = this.props.firebase.firestore;
    return await setDoc(
      doc(firestore, "repApplications", application.id),
      application,
      { merge: true }
    );
  };

  handleNext = async () => {
    if (this.isValid()) {
      const steps = this.getSteps();
      if (this.state.activeStep === steps.length - 1) {
        this.handleSubmit();
      } else {
        let nextStep = this.state.activeStep + 1;
        let application = { ...this.state.application };
        if (nextStep > application.activeStep) {
          application.activeStep = nextStep;
        }
        if (this.state.activeStep === 0) {
          const id = await this.createApplication(application);
          application.id = id;
        } else {
          if (nextStep === 2) {
            application.dateBusinessStarted = "01/01/" + application.businessYear;
            if (!application.homePhone) {
              application.homePhone = application.businessPhone;
            }
            if (application.ownershipType === "Sole Proprietorship") {
              application.federalTaxId = "00-0000000";
            }

            if (!application.mailingDifferent) {
              application.mailingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.mailingAddress2 = application.businessAddress2;
              }
              application.mailingCity = application.businessCity;
              application.mailingState = application.businessState;
              application.mailingZip = application.businessZip;
            }
            if (!application.shippingDifferent) {
              application.shippingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.shippingAddress2 = application.businessAddress2;
              }
              application.shippingCity = application.businessCity;
              application.shippingState = application.businessState;
              application.shippingZip = application.businessZip;
            }
          }
          if (nextStep === 3) {
            const socialSecurityNumber = application.socialSecurityNumber;

            try {
              const {
                data: { encryptedText, salt }
              } = await httpsCallable(
                this.props.firebase.functions,
                "encryptField"
              )({
                plainText: socialSecurityNumber
              });
              application.encryptedSocialSecurityNumber = encryptedText;
              application.encryptedSocialSecurityNumberSalt = salt;
            } catch (err) {
              console.error(
                "error encrypting sensitive data. plain text will not be saved."
              );
            } finally {
              delete application.socialSecurityNumber;
            }

            if (!application.residenceDifferent) {
              application.homeAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.homeAddress2 = application.businessAddress2;
              }
              application.homeCity = application.businessCity;
              application.homeState = application.businessState;
              application.homeZip = application.businessZip;
            }
            if (application.ownershipType === "Sole Proprietorship") {
              application.federalTaxId = "00-0000000";
            }
          }
          if (nextStep === 4) {
            application.monthlyAmexVolume = Math.round(application.cardSales * 0.1);
            application.cardSalesYear = Math.round(application.cardSales * 12);
          }
          if (nextStep === 6) {
            if (application.link && application.link.paymentType === "ACH") {
              application.paymentBankName = application.bankName;
              application.paymentAccountNumber = application.bankAccountNumber;
              application.paymentRoutingNumber = application.routingNumber;
            }
            if (application.paymentType === "Free") {
              nextStep += 1;
            }
          }
          await this.saveApplication(application);
        }
        this.setState({ application, activeStep: nextStep });
      }
    }
    window.scrollTo(0, 0);
  };

  handleBack = () => {
    const previousStep = this.state.activeStep - 1;
    if (this.state.activeStep > 0) {
      this.setState({ activeStep: previousStep });
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleSaveAndSend = async () => {
    try {
        const { application } = this.state;
        await this.saveApplication(application);
        await httpsCallable(
            this.props.firebase.functions,
            "sendRepApplication"
        )({
          data: {
            repApplicationId: application.id
        }});
        this.setState({ sent: true });
        this.props.onSave && this.props.onSave(application.id);
    } catch (error) {
        console.error("Error saving and sending application:", error);
    }
};


  handleSubmit = async () => {
    this.setState({ loading: true });
  };

  handleApplicationTextChange = name => event => {
    const application = { ...this.state.application };
    application[name] = mask(name, event.target.value);
    if (
      name === "ownershipType" &&
      event.target.value === "Sole Proprietorship"
    ) {
      application.federalTaxId = "";
    }
    this.setState({ application });
  };

  handleCheckChange = name => event => {
    let application = { ...this.state.application };
    application[name] = event.target.checked;
    if (name === "shippingDifferent" && event.target.checked) {
      application.shippingAddress1 = "";
      application.shippingAddress2 = "";
      application.shippingCity = "";
      application.shippingState = "";
      application.shippingZip = "";
    }
    if (name === "residenceDifferent" && event.target.checked) {
      application.homeAddress1 = "";
      application.homeAddress2 = "";
      application.homeCity = "";
      application.homeState = "";
      application.homeZip = "";
    }
    this.setState({ application });
  };

  handlePlanChange = event => {
    let application = { ...this.state.application };
    application.plan = event.target.value;
    this.setState({ application });
  };

  setApplicationPricing = pricing => {
    let application = { ...this.state.application };
    application.pricing = pricing;
    this.setState({ application });
  };

  getSteps = () => {
    return [
      "Email Address",
      "Business Info",
      "Principal Info",
      "Account Info",
      "Financial Info",
      "Payment Info",
      "Done"
    ];
  };

  getStepContent = (stepIndex, application, errors) => {
    switch (stepIndex) {
      case 0:
        return (
          <Start
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 1:
        return (
          <Business
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            onCheckChange={this.handleCheckChange}
            errors={errors}
          />
        );
      case 2:
        return (
          <Principal
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            onCheckChange={this.handleCheckChange}
            errors={errors}
            isSsnEncrypted={
              application.encryptedSocialSecurityNumber
            }
            resetSsn={this.handleResetSsn}
          />
        );
      case 3:
        return (
          <Account
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 4:
        return (
          <Financial
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 5:
        return (
          <Cart
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            setApplicationPricing={this.setApplicationPricing}
            onCheckChange={this.handleCheckChange}
            onBack={this.handleBack}
            onNext={this.handleNext}
            errors={errors}
            transaction={this.state.transaction}
          />
        );
      case 6:
        return (
          <div className={this.props.classes.done}>
            {this.state.sent ? (
              <Typography>Sent.</Typography>
            ) : (
              <Typography>
                Click <b>SAVE & SEND</b> to send an email to the customer.
              </Typography>
            )}
          </div>
        );
      default:
        throw RangeError("Invalid step");
    }
  };

  isValid = () => {
    const { activeStep, application } = this.state;
    const validationResult = validateStep(activeStep, application);
    if (!validationResult.isValid) {
      this.setState({ errors: validationResult.errors });
    } else {
      this.setState({ errors: {} });
    }
    return validationResult.isValid;
  };

  render() {
    const { classes } = this.props;
    const { activeStep, application, loading, errors } = this.state;
    const steps = this.getSteps();
    const stepIndex = activeStep;

    return (
      <div className={classes.root}>
        <Stepper steps={steps} activeStep={stepIndex} />
        {this.getStepContent(activeStep, application, errors)}
        <Actions
          application={application}
          steps={steps}
          activeStep={activeStep}
          onNext={this.handleNext}
          onSave={this.handleSaveAndSend}
          onBack={this.handleBack}
          loading={loading}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withAuthorization(user => !!user),
  withFirebase
)(Application);
