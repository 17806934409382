// user
export const RECEIVE_USER_STATE_CHANGE = "RECEIVE_USER_STATE_CHANGE";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SET_FIRESTORE_USER = "SET_FIRESTORE_USER";

// messages
export const PUSH_MESSAGE = "PUSH_MESSAGE";
export const POP_MESSAGE = "POP_MESSAGE";
export const RECEIVE_MESSAGES_SNAPSHOT = "RECEIVE_MESSAGES_SNAPSHOT";

// metadata
export const RECEIVE_METADATA_SNAPSHOT = "RECEIVE_METADATA_SNAPSHOT";

// leads
export const RECEIVE_LEADS_SNAPSHOT = "RECEIVE_LEADS_SNAPSHOT";
export const RECEIVE_LEADS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_LEADS_TOTAL_COUNT_SNAPSHOT";
export const SET_LEADS_SEARCH_CRITERIA = "SET_LEADS_SEARCH_CRITERIA";
export const SET_LEADS_COLUMN_VISIBILITY = "SET_LEADS_COLUMN_VISIBILITY";

// applications
export const RECEIVE_APPLICATIONS_SNAPSHOT = "RECEIVE_APPLICATIONS_SNAPSHOT";
export const RECEIVE_APPLICATIONS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_APPLICATIONS_TOTAL_COUNT_SNAPSHOT";
export const SET_APPLICATIONS_SEARCH_CRITERIA =
  "SET_APPLICATIONS_SEARCH_CRITERIA";
export const SET_APPLICATIONS_COLUMN_VISIBILITY =
  "SET_APPLICATIONS_COLUMN_VISIBILITY";

// sources
export const RECEIVE_SOURCES_SNAPSHOT = "RECEIVE_SOURCES_SNAPSHOT";

// campaigns
export const RECEIVE_CAMPAIGNS_SNAPSHOT = "RECEIVE_CAMPAIGNS_SNAPSHOT";

// users
export const RECEIVE_USERS_SNAPSHOT = "RECEIVE_USERS_SNAPSHOT";
export const RECEIVE_USERS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_USERS_TOTAL_COUNT_SNAPSHOT";
export const SET_USERS_SEARCH_CRITERIA = "SET_USERS_SEARCH_CRITERIA";
export const SET_USERS_COLUMN_VISIBILITY = "SET_USERS_COLUMN_VISIBILITY";

// events
export const RECEIVE_EVENTS_SNAPSHOT = "RECEIVE_EVENTS_SNAPSHOT";
