import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withFirebase } from "../Firebase";
import { pop } from "../../actions/messageActions";
import { deleteDoc, doc } from "@firebase/firestore";

const MessageSnackbar = ({ messages, pop, user, firebase }) => {
  const message = messages && messages.length > 0 ? messages[0] : null;
  const firestore = firebase.firestore;
  const handleClose = message => async (event, reason) => {
    if (message.id) {
      await deleteDoc(
        doc(firestore, "users", user.uid, "messages", message.id)
      );
    } else {
      pop();
    }
  };

  return message && message.severity ? (
    <Snackbar
      open={!!message}
      autoHideDuration={6000}
      onClose={handleClose(message)}
    >
      <Alert
        onClose={handleClose(message)}
        elevation={6}
        variant="filled"
        severity={message.severity}
      >
        {message.text}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={message}
      message={message ? `(1 of ${messages.length}) ${message.text}` : ""}
      action={
        <React.Fragment>
          <Button color="primary" size="small" onClick={handleClose(message)}>
            Mark as Read
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose(message)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default compose(
  withFirebase,
  connect(
    state => ({
      user: state.user,
      messages: state.messages
    }),
    {
      pop
    }
  )
)(MessageSnackbar);
