import React, { useState, FC, ReactElement } from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { compose } from "recompose";
import Firebase, { withFirebase } from '../Firebase';
import { httpsCallable } from "@firebase/functions";
import { collection, getDocs, query, where } from '@firebase/firestore';
import { connect } from 'react-redux';

interface IAuthUser {
  displayName: string;
  email: string;
  uid: string;
  metadata: any;
  isAdmin: boolean;
  algoliaPublicKey: string;
  role: string;
}

interface IUserDialog {
  authUser: IAuthUser;
  firebase: Firebase;
  open: boolean;
  handleClose: () => void;
}

const UserDialog: FC<IUserDialog> = ({ authUser, firebase, open, handleClose }): ReactElement => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>({});

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        set(event.target.value);
    };

    const handleSubmit = async () => {
      if (authUser.isAdmin) {
        const res = await validateUser();
        if (!res.isValid) {
          setErrors(res.errors);
        } else {
          try {
            await httpsCallable(firebase.functions, "createUser")({ email, password });
          } catch (error) {
            setErrors({ email: error });
            return;
          }
          handleClose();
        }
      }
    }

    const validateUser = async () => {
      let isValid = true;
      let errors: any = {};

      const validateTextField = (value: string, field: string, message: string) => {
        if (!value || value === "") {
          isValid = false;
          errors[field] = message;
        }
      };

      const validateEmailAlreadyExists = async (email: string, field: string, message: string) => {
        const q = query(collection(firebase.firestore, "users"), where("email", "==", email.toLowerCase()));
        const users = await getDocs(q);
        if (users.docs.length) {
          isValid = false;
          errors[field] = message;
        }
      }

      const validateEmail = async (email: string, field: string) => {
        validateTextField(email, field, `${field} field is required`);
        if (email && !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
          isValid = false;
          errors[field] = "Email is in an Incorrect Format";
        }
        await validateEmailAlreadyExists(email, field, "User with such email already exists");
      }

      const validatePassword = (password: string, field: string) => {
        validateTextField(password, field, `${field} field is required`);
        if (password && password.length < 6) {
          isValid = false;
          errors[field] = "Password minimum length is 6 symbols";
        }
      }

      await validateEmail(email, 'email');
      validatePassword(password, 'password');

      return { isValid, errors };
    }

    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
        <DialogTitle id="form-dialog">Create User</DialogTitle>
        <DialogContent>
            <TextField
                variant="outlined"
                margin="normal"
                id="email"
                name="email"
                label="Email"
                value={email}
                helperText={errors.email}
                error={Boolean(errors.email)}      
                onChange={handleTextChange(setEmail)}
                fullWidth
            />
            <TextField
                variant="outlined"
                margin="normal"
                id="password"
                name="password"
                label="Password"
                value={password}
                helperText={errors.password}
                error={Boolean(errors.password)}
                onChange={handleTextChange(setPassword)}
                fullWidth
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button onClick={handleSubmit} size="small" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default compose<IUserDialog, Element>(
  withFirebase,
  connect((state: any) => ({
    authUser: state.user,
    metadata: state.metadata
  }))
)(UserDialog);
