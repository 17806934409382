const styles = theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolBar: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  side: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "10%"
    }
  },
  center: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  }
});

export default styles;
