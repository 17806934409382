import React, { PureComponent, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  InputAdornment,
  Button,
  IconButton,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { withFirebase } from "../Firebase";
import ZipTaxApi from "../../api/ZipTax";
import styles from "./styles";
import { addDoc, collection, getDocs } from "@firebase/firestore";

const debouncedSearch = AwesomeDebouncePromise(ZipTaxApi.getTaxes, 750);

class Admin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      includeTaxes: true,
      terminationFee: "0.00",
      agents: [],
      inventory: [],
      chosenEquipment: {},
      errors: {}
    };
  }

  componentDidMount = async () => {
    const firestore = this.props.firebase.firestore;
    const equipmentSnapshots = await getDocs(
      collection(firestore, "equipment")
    );
    const agentsSnapshot = await getDocs(collection(firestore, "agents"));
    const equipment = equipmentSnapshots.docs.map(e => ({
      ...e.data(),
      id: e.id
    }));
    const agents = agentsSnapshot.docs.map(e => ({ ...e.data(), id: e.id }));
    this.setState({ inventory: equipment, agents });
  };

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  getZipTaxResults = async zipCode => {
    if (zipCode.length === 5) {
      this.setState({ loading: true });
      const results = await debouncedSearch(zipCode);
      if (results.rCode === 100 && results.results.length) {
        const taxes = (results.results[0].taxSales * 100).toFixed(2);
        this.setState({ taxes, loading: false });
      } else {
      }
      this.setState({ loading: false });
    }
  };

  handleZipCodeSearch = async event => {
    const zipCode = event.target.value;
    this.setState(
      { zipCode },
      async () => await this.getZipTaxResults(zipCode)
    );
  };

  handleEquipmentTextChange = id => event => {
    const { chosenEquipment } = this.state;
    let addedEquipment = chosenEquipment[id];
    addedEquipment.price = event.target.value;

    this.setState({
      chosenEquipment: { ...chosenEquipment, [id]: addedEquipment }
    });
  };

  handleAddEquipmentClick = () => {
    const { inventory, equipment, quantity, chosenEquipment } = this.state;
    let addedEquipment = chosenEquipment[equipment];
    if (addedEquipment) {
      addedEquipment = {
        ...addedEquipment,
        quantity: addedEquipment.quantity + quantity
      };
    } else {
      addedEquipment = {
        equipment: inventory.filter(e => e.id === equipment)[0],
        quantity,
        price: "1.00"
      };
    }
    this.setState({
      chosenEquipment: { ...chosenEquipment, [equipment]: addedEquipment }
    });
  };

  handleDeleteEquipmentClick = equipmentId => () => {
    let chosenEquipment = { ...this.state.chosenEquipment };
    delete chosenEquipment[equipmentId];
    this.setState({ chosenEquipment: chosenEquipment });
  };

  getSubtotal = () => {
    const { chosenEquipment } = this.state;
    let subtotal = Object.keys(chosenEquipment).reduce(
      (previous, current) =>
        parseFloat(chosenEquipment[current].price) *
          chosenEquipment[current].quantity +
        previous,
      0
    );
    return subtotal;
  };

  getSubtotalWithDiscount = () => {
    const { discountAmount, discountPercent } = this.state;
    let subtotal = this.getSubtotal();
    if (discountAmount) {
      subtotal = subtotal - parseFloat(discountAmount);
    }
    if (discountPercent) {
      subtotal = subtotal - subtotal * (parseFloat(discountPercent) / 100);
    }
    return subtotal;
  };

  getTaxes = () => {
    const subtotal = this.getSubtotalWithDiscount();
    const { includeTaxes, taxes } = this.state;
    if (includeTaxes && subtotal && taxes) {
      return subtotal * (taxes / 100);
    } else {
      return 0.0;
    }
  };

  getOrderTotal = () => {
    const subtotal = this.getSubtotalWithDiscount();
    const { shipping, additionalFee } = this.state;

    const orderTotal =
      parseFloat(subtotal) +
      this.getTaxes() +
      parseFloat(shipping || "0.00") +
      parseFloat(additionalFee || "0.00");
    return orderTotal.toFixed(2);
  };

  getDownPayment = () => {
    return (
      parseFloat(this.state.downPayment) ||
      (this.getOrderTotal() || 0) / (this.state.paymentPlan || 1)
    ).toFixed(2);
  };

  getBalance = () => {
    return (
      parseFloat(this.getOrderTotal()) - parseFloat(this.getDownPayment())
    ).toFixed(2);
  };

  closeDialog = () => {
    this.setState({ linkId: null });
  };

  generate = async () => {
    const { agents } = this.state;
    const link = { ...this.state };
    delete link.equipment;
    link.agent = agents.find(e => e.id === link.agent);
    link.equipment = Object.keys(link.chosenEquipment).map(e => ({
      ...link.chosenEquipment[e].equipment,
      price: parseFloat(link.chosenEquipment[e].price),
      quantity: link.chosenEquipment[e].quantity
    }));
    link.createdAt = new Date();
    link.pricing = {
      subtotal: this.getSubtotalWithDiscount(),
      discount: this.getSubtotal() - this.getSubtotalWithDiscount(),
      taxes: this.getTaxes(),
      total: this.getOrderTotal()
    };
    delete link.agents;
    delete link.inventory;
    delete link.loading;
    delete link.errors;
    delete link.chosenEquipment;
    const result = await addDoc(
      collection(this.props.firebase.firestore, "links"),
      link
    );
    this.setState({ linkId: result.id });
  };

  render() {
    const { classes } = this.props;
    const {
      agents,
      inventory,
      chosenEquipment,
      linkId,
      loading,
      errors
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="agent"
              label="Rep Code"
              variant="outlined"
              className={classes.sideMargins}
              value={this.state.agent}
              onChange={this.handleTextChange("agent")}
              error={!!errors.agent}
              helperText={errors.agent}
              margin="normal"
              select
              fullWidth
            >
              {agents.map(e => {
                return (
                  <MenuItem key={e.id} value={e.id}>
                    {`${e.repCode} - ${e.firstName} ${e.lastName}`}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className={classes.inputContainer}>
            <TextField
              id="equipment"
              label="Equipment"
              variant="outlined"
              className={classes.sideMargins}
              value={this.state.equipment}
              onChange={this.handleTextChange("equipment")}
              error={!!errors.equipment}
              helperText={errors.equipment}
              margin="normal"
              select
              fullWidth
            >
              <MenuItem value={-1}></MenuItem>
              {inventory.map(e => {
                return (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              className={classes.sideMargins}
              value={this.state.quantity}
              onChange={this.handleTextChange("quantity")}
              error={!!errors.quantity}
              helperText={errors.quantity}
              margin="normal"
              select
              fullWidth
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => {
                return (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="primary"
              startIcon={<AddIcon />}
              onClick={this.handleAddEquipmentClick}
            >
              Add New Equipment
            </Button>
          </div>

          <Paper className={classes.paymentOptionContainer} elevation={2}>
            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Payment Options
            </Typography>

            <div className={classes.inputContainer}>
              <TextField
                id="paymentType"
                label="Payment Type"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.paymentType}
                onChange={this.handleTextChange("paymentType")}
                error={!!errors.paymentType}
                helperText={errors.paymentType}
                margin="normal"
                select
                fullWidth
              >
                <MenuItem value={"Free"}>Free</MenuItem>
                <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                <MenuItem value={"ACH"}>ACH</MenuItem>
              </TextField>

              <TextField
                id="paymentPlan"
                label="Payment Plan"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.paymentPlan}
                onChange={this.handleTextChange("paymentPlan")}
                error={!!errors.paymentPlan}
                helperText={errors.paymentPlan}
                margin="normal"
                select
                fullWidth
              >
                {[1, 3, 6].map(e => {
                  return (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            {Object.keys(chosenEquipment).map((e, i) => {
              const current = chosenEquipment[e];
              return (
                <div className={classes.inputContainer}>
                  <TextField
                    key={i}
                    id={`price-${e}`}
                    type="number"
                    label={`${current.equipment.name}`}
                    variant="outlined"
                    className={classes.sideMargins}
                    value={current.price}
                    placeholder={"0.00"}
                    onChange={this.handleEquipmentTextChange(e)}
                    error={!!errors[`price-${e}`]}
                    helperText={
                      errors[`price-${e}`] || `Quantity: ${current.quantity}`
                    }
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="remove equipment"
                            onClick={this.handleDeleteEquipmentClick(e)}
                            edge="end"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              );
            })}
            <div className={classes.inputContainer}>
              <TextField
                id="discountAmount"
                label="Discount Amount"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.discountAmount}
                placeholder={"0.00"}
                onChange={this.handleTextChange("discountAmount")}
                error={!!errors.discountAmount}
                helperText={errors.discountAmount}
                margin="normal"
                fullWidth
                disabled={this.state.discountPercent}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />

              <TextField
                id="discountPercent"
                label="Discount Percent"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.discountPercent}
                placeholder={"0"}
                onChange={this.handleTextChange("discountPercent")}
                error={!!errors.discountPercent}
                helperText={errors.discountPercent}
                margin="normal"
                fullWidth
                disabled={this.state.discountAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.switchContainer}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={this.state.includeTaxes}
                      onChange={this.handleCheckChange("includeTaxes")}
                      value="checkedA"
                    />
                  }
                  label="Taxes will be included."
                />
              </FormGroup>
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="zipCode"
                label="Zip Code"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.zipCode}
                onChange={this.handleZipCodeSearch}
                error={!!errors.zipCode}
                helperText={
                  errors.zipCode ||
                  "Taxes can be calculated automatically based on Zip Code."
                }
                margin="normal"
                fullWidth
                disabled={!this.state.includeTaxes}
              />
              <TextField
                id="taxes"
                label="Taxes"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={!this.state.includeTaxes ? "0.00" : this.state.taxes}
                placeholder={"0.00"}
                onChange={this.handleTextChange("taxes")}
                error={!!errors.taxes}
                helperText={errors.taxes}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
                disabled={!this.state.includeTaxes}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="shipping"
                label="Shipping"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.shipping}
                placeholder={"0.00"}
                onChange={this.handleTextChange("shipping")}
                error={!!errors.shipping}
                helperText={errors.shipping}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="additionalFeeLabel"
                label="Additional Fee Name"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.additionalFeeLabel}
                onChange={this.handleTextChange("additionalFeeLabel")}
                error={!!errors.additionalFeeLabel}
                helperText={errors.additionalFeeLabel}
                margin="normal"
                fullWidth
              />
              <TextField
                id="additionalFee"
                label={this.state.additionalFeeLabel}
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.additionalFee}
                placeholder={"0.00"}
                onChange={this.handleTextChange("additionalFee")}
                error={!!errors.additionalFee}
                helperText={errors.additionalFee}
                margin="normal"
                fullWidth
                disabled={!this.state.additionalFeeLabel}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="downPayment"
                label="Down Payment"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.downPayment}
                placeholder={this.getDownPayment()}
                onChange={this.handleTextChange("downPayment")}
                error={!!errors.downPayment}
                helperText={errors.downPayment}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="terminationFee"
                label="Termination Fee"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.terminationFee}
                onChange={this.handleTextChange("terminationFee")}
                error={!!errors.terminationFee}
                helperText={errors.terminationFee}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Order Summary
            </Typography>

            <List>
              <ListItem>
                <ListItemText primary="Products" />
              </ListItem>
              <List component="div" disablePadding>
                {Object.keys(chosenEquipment).map((e, i) => {
                  const current = chosenEquipment[e];
                  return (
                    <ListItem className={classes.nested}>
                      <ListItemText
                        primary={current.equipment.name}
                        secondary={`Quantity: ${current.quantity}`}
                      />
                      <ListItemText
                        className={classes.alignRight}
                        primary={`$${(current.price * current.quantity).toFixed(
                          2
                        )}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Discount" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`($${(
                    this.getSubtotal() - this.getSubtotalWithDiscount()
                  ).toFixed(2)})`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Subtotal" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getSubtotalWithDiscount().toFixed(2)}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Taxes" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getTaxes().toFixed(2)}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Shipping" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${parseFloat(this.state.shipping || 0).toFixed(
                    2
                  )}`}
                />
              </ListItem>
              {this.state.additionalFee && (
                <Fragment>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary={this.state.additionalFeeLabel} />
                    <ListItemText
                      className={classes.alignRight}
                      primary={`$${parseFloat(
                        this.state.additionalFee || 0
                      ).toFixed(2)}`}
                    />
                  </ListItem>
                </Fragment>
              )}
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary="Order Total"
                  secondary={
                    this.state.paymentType && this.state.paymentPlan
                      ? `${this.state.paymentType} - ${this.state.paymentPlan} Month Term`
                      : ""
                  }
                />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getOrderTotal()}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Down Payment" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`($${this.getDownPayment()})`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Balance Due" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getBalance()}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Termination Fee" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${parseFloat(
                    this.state.terminationFee || 0
                  ).toFixed(2)}`}
                />
              </ListItem>
            </List>
          </Paper>
          <div className={classes.actionContainer}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={this.generate}
              disabled={Boolean(Object.keys(chosenEquipment).length === 0)}
            >
              Generate
            </Button>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          open={Boolean(linkId)}
          aria-labelledby="link-dialog-title"
          aria-describedby="link-dialog-description"
        >
          <DialogContent>
            <TextField
              label="Generated Link"
              id="link"
              readOnly
              className={classes.linkField}
              value={`https://app.posbrokers.com/?l=${linkId}`}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
              size="small"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withStyles(styles), withFirebase)(Admin);
