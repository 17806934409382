import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  TextField,
  Collapse
} from "@material-ui/core";

import Title from "../../Title";

import styles from "../styles";

class Principal extends PureComponent {

  render() {
    const {
      application,
      onApplicationTextChange,
      onCheckChange,
      errors,
      classes,
      isSsnEncrypted,
      resetSsn
    } = this.props;

    return (
      <div className={classes.root}>
        {application.isCheckout ? (
          <Title h1={"Identity Verification"} />
        ) : (
          <Title
            h1={"Principal Info"}
            h2={
              "Please enter your contact information so we can verify your identity."
            }
          />
        )}
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="firstName"
              label="First Name"
              variant="outlined"
              className={classes.sideMargins}
              value={application.firstName || ""}
              onChange={onApplicationTextChange("firstName")}
              error={!!errors.firstName}
              helperText={errors.firstName}
              margin="normal"
              fullWidth
            />
            <TextField
              id="lastName"
              label="Last Name"
              variant="outlined"
              className={classes.sideMargins}
              value={application.lastName || ""}
              onChange={onApplicationTextChange("lastName")}
              error={!!errors.lastName}
              helperText={errors.lastName}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="dateOfBirth"
              label="Date of Birth"
              placeholder="MM/DD/YYYY"
              variant="outlined"
              className={classes.sideMargins}
              value={application.dateOfBirth || ""}
              onChange={onApplicationTextChange("dateOfBirth")}
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth}
              margin="normal"
              fullWidth
            />
            {isSsnEncrypted ? 
            <div onDoubleClick={resetSsn} className={classes.disabledTextWrapper}>
              <TextField
                id="socialSecurityNumber"
                disabled={true}
                label="Social Security Number is encrypted and saved"
                placeholder="***-**-****"
                variant="outlined"
                className={classes.textWrapper}
                value={""}
                helperText={errors.socialSecurityNumber}
                margin="normal"
                fullWidth
              />
            </div>
            :
            <TextField
              id="socialSecurityNumber"
              label="Social Security Number"
              placeholder="000-00-0000"
              variant="outlined"
              className={classes.sideMargins}
              value={application.socialSecurityNumber || ""}
              onChange={onApplicationTextChange("socialSecurityNumber")}
              error={!!errors.socialSecurityNumber}
              helperText={errors.socialSecurityNumber}
              margin="normal"
              fullWidth
            />
            }
          </div>
          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
            Residence Address
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.inputContainer}>
            <FormGroup className={classes.differentCheckboxContainer} row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={application.residenceDifferent}
                    onChange={onCheckChange("residenceDifferent")}
                    value="residenceDifferent"
                  />
                }
                label="Residence Address different than Business Address?"
              />
            </FormGroup>
          </div>
          <Collapse in={application.residenceDifferent}>
            <div className={classes.inputContainer}>
              <TextField
                id="homeAddress1"
                label="Home Address 1"
                placeholder="Street Address"
                variant="outlined"
                className={classes.sideMargins}
                value={
                  (application.residenceDifferent
                    ? application.homeAddress1
                    : application.businessAddress1) || ""
                }
                onChange={onApplicationTextChange("homeAddress1")}
                error={!!errors.homeAddress1}
                helperText={errors.homeAddress1}
                margin="normal"
                fullWidth
                disabled={application.residenceDifferent === false}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="homeAddress2"
                label="Home Address 2 (optional)"
                placeholder="Apt/Unit"
                variant="outlined"
                className={classes.sideMargins}
                value={
                  (application.residenceDifferent
                    ? application.homeAddress2
                    : application.businessAddress2) || ""
                }
                onChange={onApplicationTextChange("homeAddress2")}
                error={!!errors.homeAddress2}
                helperText={errors.homeAddress2}
                margin="normal"
                fullWidth
                disabled={application.residenceDifferent === false}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="homeCity"
                label="City"
                variant="outlined"
                className={classes.sideMargins}
                value={
                  (application.residenceDifferent
                    ? application.homeCity
                    : application.businessCity) || ""
                }
                onChange={onApplicationTextChange("homeCity")}
                error={!!errors.homeCity}
                helperText={errors.homeCity}
                margin="normal"
                fullWidth
                disabled={application.residenceDifferent === false}
              />
              <TextField
                id="homeState"
                label="State"
                variant="outlined"
                className={classes.sideMargins}
                value={
                  (application.residenceDifferent
                    ? application.homeState
                    : application.businessState) || ""
                }
                onChange={onApplicationTextChange("homeState")}
                error={!!errors.homeState}
                helperText={errors.homeState}
                margin="normal"
                fullWidth
                disabled={application.residenceDifferent === false}
                inputProps={{
                  maxLength: 2
                }}
              />
              <TextField
                id="homeZip"
                label="Zip Code"
                variant="outlined"
                className={classes.sideMargins}
                value={
                  (application.residenceDifferent
                    ? application.homeZip
                    : application.businessZip) || ""
                }
                onChange={onApplicationTextChange("homeZip")}
                error={!!errors.homeZip}
                helperText={errors.homeZip}
                margin="normal"
                fullWidth
                disabled={application.residenceDifferent === false}
              />
            </div>
          </Collapse>
          <div className={classes.inputContainer}>
            <TextField
              id="homePhone"
              label="Phone Number"
              placeholder="999-999-9999"
              variant="outlined"
              className={classes.sideMargins}
              value={application.homePhone}
              onChange={onApplicationTextChange("homePhone")}
              error={!!errors.homePhone}
              helperText={errors.homePhone}
              margin="normal"
              fullWidth
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Principal);
