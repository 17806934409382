const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  sectionTitle: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  sectionForm: {
    flexGrow: 2
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  buttonContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1)
    }
  },
  switchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  actionContainer: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  paymentOptionContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  sideMargins: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  alignRight: {
    textAlign: "right"
  },
  linkField: {
    width: 500
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  dialogActions: {
    padding: theme.spacing(1)
  },
  removeLink: {
    marginRight: theme.spacing(2)
  },
  vertMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

export default styles;
