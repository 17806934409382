import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { endOfDay, startOfDay, subDays } from "date-fns";
import React, { Fragment, useState } from "react";
import { ListOption } from "../../models/ListOption";

const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: theme.spacing(1),
  },
  popoverContent: {
    width: 320,
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  button: {
    fontSize: "16px",
  },
}));

export type Operator = { id: string; label: string };

export type FilterOption = {
  id: string;
  column: string;
  type:
    | "text"
    | "number"
    | "currency"
    | "currencyRange"
    | "date"
    | "dateRange"
    | "list";
  label: string;
  operators: Operator[];
  options?: ListOption[];
  dateFormat?: string;
  force?: boolean;
};

export type ActiveFilter = {
  option: FilterOption;
  operator: Operator;
  value: any;
};

interface Props {
  options: FilterOption[];
  onApplyFilter: (filter: ActiveFilter) => void;
}

export const Filters = ({ options, onApplyFilter }: Props) => {
  const classes = useStyles();

  const [option, setOption] = useState<FilterOption | null>();
  const [operator, setOperator] = useState<Operator | null>();
  const [value, setValue] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const _option = options.find((e) => e.id === event.target.value);
    setOption(_option);
    setValue(null);
    if (_option?.type === "date") {
      const _operator = _option?.operators.find((e) => e.id === "__between");
      setOperator(_operator);
      setValue([startOfDay(new Date()), endOfDay(new Date())]);
    }
    if (_option?.type === "dateRange") {
      const _operator = _option?.operators.find((e) => e.id === "__between");
      setOperator(_operator);
      setValue([startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]);
    }
    if (_option?.type === "currencyRange") {
      const _operator = _option?.operators.find((e) => e.id === "__between");
      setOperator(_operator);
      setValue([0, 0]);
    }
    if (_option?.type === "list") {
      const _operator = _option?.operators.find((e) => e.id === "__in");
      setOperator(_operator);
    }
    if (_option?.type === "currency") {
      setValue("");
    }
  };

  const handleOperatorChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const _operator = option?.operators.find(
      (e) => e.id === event.target.value
    );
    setOperator(_operator);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    setValue(value ? value.value : null);
  };

  const handleDateRangeSelect = (index: 0 | 1) => (
    date: MaterialUiPickersDate
  ) => {
    if (!date) return;
    const _value = [...value];
    _value[index] = date;
    setValue(_value);
  };

  const handleCurrencyRangeSelect = (index: 0 | 1) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _value = [...value];
    _value[index] = parseFloat(event.target.value);
    setValue(_value);
  };

  const handleDateSelect = (date: MaterialUiPickersDate) => {
    if (!date) return;
    setValue([startOfDay(date.toDate()), endOfDay(date.toDate())]);
  };

  const reset = () => {
    setOption(null);
    setOperator(null);
    setValue(null);
  };

  const apply = () => {
    if (!(option && operator && value)) return;
    // allow a currency range from 0 to 0 to be applied
    if (
      Array.isArray(value) &&
      !((value[0] && value[1]) || option?.type === "currencyRange")
    )
      return;
    onApplyFilter({ option, operator, value });
    reset();
    handleClose();
  };

  return (
    <Fragment>
      <Button
        color="primary"
        endIcon={<FilterListIcon />}
        onClick={handleButtonClick}
      ></Button>
      <Popover
        className={classes.popover}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContent}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Field"
                id="field"
                name="field"
                value={option?.id || ""}
                onChange={handleFieldChange}
                select
              >
                {options
                  .filter((e) => !e.force)
                  .map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {option && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Operator"
                  id="operator"
                  name="operator"
                  defaultValue={option.operators[0].id}
                  value={operator?.id || ""}
                  onChange={handleOperatorChange}
                  select
                >
                  {option.operators.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>

            {options && option?.type === "text" && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                />
              </Grid>
            )}

            {options && option?.type === "number" && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                />
              </Grid>
            )}

            {options && option?.type === "currency" && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            {options && option?.type === "currencyRange" && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    label={"From"}
                    id="value"
                    name="value"
                    value={value?.[0]}
                    onChange={handleCurrencyRangeSelect(0)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    label={"To"}
                    id="value"
                    name="value"
                    value={value?.[1]}
                    onChange={handleCurrencyRangeSelect(1)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {options && option?.type === "list" && option.options && (
              <Grid item xs={12}>
                <Autocomplete
                  id="list-autocomplete"
                  options={option.options}
                  getOptionLabel={(option) => option.value}
                  onChange={handleAutoCompleteChange}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={option.label}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {options && option?.type === "dateRange" && value && (
              <Fragment>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    label={"From"}
                    id="value"
                    name="value"
                    value={value[0]}
                    format={
                      option?.dateFormat != null
                        ? option.dateFormat
                        : DEFAULT_DATE_FORMAT
                    }
                    onChange={handleDateRangeSelect(0)}
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    label={"To"}
                    id="value"
                    name="value"
                    value={value[1]}
                    format={
                      option?.dateFormat != null
                        ? option.dateFormat
                        : DEFAULT_DATE_FORMAT
                    }
                    onChange={handleDateRangeSelect(1)}
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                </Grid>
              </Fragment>
            )}

            {options && option?.type === "date" && value && (
              <Grid item xs={12}>
                <KeyboardDatePicker
                  label={"Date"}
                  id="value"
                  name="value"
                  value={value[0]}
                  format={
                    option?.dateFormat != null
                      ? option.dateFormat
                      : DEFAULT_DATE_FORMAT
                  }
                  onChange={handleDateSelect}
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="default"
                onClick={reset}
                fullWidth
                className={classes.button}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={apply}
                fullWidth
                className={classes.button}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </Fragment>
  );
};
