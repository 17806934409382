import React, { Component, Fragment } from "react";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

import MobileHeader from "./MobileHeader";
import Footer from "./Footer";
import Stepper from "./Stepper";
import MobileStepper from "./MobileStepper";
import Actions from "./Actions";
import Start from "./steps/Start";
import Business from "./steps/Business";
import Principal from "./steps/Principal";
import Account from "./steps/Account";
import Financial from "./steps/Financial";
import Review from "./steps/Review";
import Cart from "./steps/Cart";
import AlmostDone from "./steps/AlmostDone";
import Plan from "./steps/Plan";
import ResumeDialog from "./ResumeDialog";
import { mask } from "../../util/MaskUtil";
import { validateStep } from "../../util/ValidationUtil";
import { dataUrlToBlob } from "../../util/FileUtil";

import { withFirebase } from "../Firebase";

import IpAddressApi from "../../api/IpAddress";

import URLSearchParams from "url-search-params";

import styles from "./styles";
import {
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  collection,
  where,
  query
} from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { httpsCallable } from "@firebase/functions";
import {
  onAuthStateChanged,
  signOut,
  signInAnonymously as signInAnonymouslyFunc
} from "@firebase/auth";

const DEFAULT_AGENT = "206";

class Application extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const agent = parseInt(params.get("agent") || DEFAULT_AGENT);
    const test = params.get("test") === "true";
    const checkoutId = params.get("checkout");
    const isCheckout = Boolean(checkoutId);
    const linkId = params.get("l");
    const repAppId = params.get("r");
    const uid = params.get("uid");
    let application = {
      agent: agent,
      activeStep: 0,
      status: "Incomplete",
      shippingDifferent: false,
      billingDifferent: false,
      residenceDifferent: false,
      billingSameAsBusiness: false,
      billingSameAsResidence: false,
      agree: false,
      agreeAdditional: false,
      checkoutId: checkoutId,
      isCheckout: isCheckout,
      withoutPayment: !linkId
    };
    if (uid) {
      application.uid = uid;
    }
    if (isCheckout) {
      application.plan = "0";
    }
    if (test) {
      application = {
        ...application,
        averageSale: "30",
        bankAccountNumber: "123123",
        bankName: "Wells",
        businessAddress1: "123 Business Rd.",
        businessAddress2: "Room 2",
        businessCity: "Canyon Country",
        businessPhone: "661-713-6234",
        businessState: "CA",
        businessZip: "91387",
        businessDescription: "Test Business",
        businessYear: "2017",
        cardSales: "300",
        confirmBankAccountNumber: "123123",
        confirmEmail: "e@jeffodle.com",
        dateOfBirth: "02-27-1987",
        doingBusinessAs: "DBA",
        email: "e@jeffodle.com",
        federalTaxId: "23-2342342",
        firstName: "Joe",
        highestSale: "300",
        homeAddress1: "123 Residence St.",
        homeAddress2: "Unit 246",
        homeCity: "Valencia",
        homePhone: "661-713-6234",
        homeState: "CA",
        homeZip: "91355",
        lastName: "Smith",
        legalBusinessName: "Business Name",
        ownershipType: "Corporation",
        routingNumber: "123123123",
        shippingAddress1: "123 Shipping Rd.",
        shippingAddress2: "Suite 1",
        shippingCity: "Santa Clarita",
        shippingState: "CA",
        shippingZip: "91387",
        socialSecurityNumber: "221-32-4233",
        website: "http://www.yahoo.com",
        billingAddress: "123 Test Rd.",
        billingCity: "Valencia",
        billingPhone: "661-713-6234",
        billingState: "CA",
        billingZip: "91387",
        billingName: "Jeff Odle",
        paymentStatementName: "Jeff O",
        paymentBankName: "Wells Fargo",
        paymentAccountNumber: "0123456789",
        paymentRoutingNumber: "111111111",
        paymentAccountType: "checking",
        branchCity: "Valencia",
        branchState: "CA",
        branchZip: "91355"
      };
    }
    this.state = {
      activeStep: 0,
      loading: false,
      checkoutId: checkoutId,
      isCheckout: isCheckout,
      savingNewApplication: false,
      promptResume: null,
      user: null,
      application,
      transaction: undefined,
      linkId,
      repAppId: repAppId,
      errors: {},
      snackbarMessage: null
    };
  }

  signaturePad = {};

  handleResetSsn = () => {
    const application = { ...this.state.application };
    application.encryptedSocialSecurityNumber = "";
    application.socialSecurityNumber = "";
    this.setState({ application });
  };

  componentDidMount = async () => {
    let linkId;
    const firestore = this.props.firebase.firestore;

    if (this.state.repAppId) {
      const repAppsSnapshot = await getDoc(
        doc(firestore, "repApplications", this.state.repAppId)
      );

      if (repAppsSnapshot.exists) {
        const application = {
          ...this.state.application,
          ...repAppsSnapshot.data()
        };
        await this.createApplication(application, this.state.repAppId);
        return;
      }
    }

    if (this.state.checkoutId) {
      const landingPageSnapshot = await getDoc(
        doc(firestore, "landingPages", this.state.checkoutId)
      );

      if (landingPageSnapshot.exists && landingPageSnapshot.data()) {
        linkId = landingPageSnapshot.data().linkId;
        this.setState({ linkId });
      }
    }

    if (!linkId && this.state.linkId) {
      linkId = this.state.linkId;
    }

    if (!linkId && this.state.application) {
      linkId = this.state.application.link?.id;
    }

    if (linkId) {
      const link = await getDoc(doc(firestore, "links", linkId));
      const data = link.data();
      this.setState({
        application: {
          ...this.state.application,
          agent: 0 /* parseInt(data.agent.irisUserId)*/,
          link: { ...data, id: link.id },
          uid: data.uid,
          processor: data.processor || "paysafe"
        }
      });
    }

    onAuthStateChanged(this.props.firebase.auth, user => {
      const state = this.state;
      if (user && !this.state.linkId) {
        this.setState({ promptResume: !state.savingNewApplication, user });
      }
    });
  };

  signInAnonymously = async () => {
    const authResponse = await signInAnonymouslyFunc(this.props.firebase.auth);
    return authResponse.user;
  };

  handleResume = async user => {
    const firestore = this.props.firebase.firestore;
    const querySnapshot = await getDocs(
      query(
        collection(firestore, "applications"),
        where("customerUid", "==", user.uid)
      )
    );
    if (querySnapshot.docs.length > 0) {
      const doc = querySnapshot.docs[0];
      const application = doc.data();
      this.setState({
        promptResume: false,
        activeStep: application.activeStep,
        application
      });
    }
  };

  handleStartOver = async () => {
    await signOut(this.props.firebase.auth);
    this.setState({
      promptResume: false,
      user: null,
      activeStep: 0,
      application: {
        agent: parseInt(DEFAULT_AGENT),
        activeStep: 0,
        status: 'Incomplete',
        shippingDifferent: false,
        residenceDifferent: false,
        billingDifferent: false,
        agree: false,
        agreeAdditional: false
      }
    });
  };

  createApplication = async (application, repApplicationId) => {
    this.setState({ savingNewApplication: true, loading: true });
    const user = await this.signInAnonymously();
    const firestore = this.props.firebase.firestore;

    const ipAddressResults = await IpAddressApi.getIpAddress();
    const newApplication = {
      ...application,
      customerUid: user.uid,
      createdAt: new Date(),
      ipAddress: ipAddressResults.ipAddress
    };

    if (repApplicationId) {
      newApplication.repId = repApplicationId;
    }

    newApplication.activeStep =
      application.activeStep > 1 ? application.activeStep : 1;

    const doc = await addDoc(
      collection(firestore, "applications"),
      newApplication
    );

    this.setState({
      activeStep: application.activeStep > 1 ? application.activeStep : 1,
      savingNewApplication: false,
      loading: false,
      application: {
        ...newApplication,
        id: doc.id
      }
    });
    return doc.id;
  };

  saveApplication = async application => {
    if (application.federalTaxId === undefined) {
      delete application.federalTaxId;
    }
    const firestore = this.props.firebase.firestore;

    return await setDoc(
      doc(firestore, "applications", application.id),
      application,
      { merge: true }
    );
  };

  addSignatureToStorage = async dataUrl => {
    const { application } = this.state;
    const blob = dataUrlToBlob(dataUrl);
    const storage = this.props.firebase.storage;
    const snapshot = await uploadBytes(
      ref(storage, `signatures/${application.id}`),
      blob
    );
    const signatureRef = snapshot.ref;
    const downloadUrl = await getDownloadURL(signatureRef);
    return { fullPath: signatureRef.fullPath, downloadUrl };
  };

  signatureRef = ref => {
    this.signaturePad = ref;
  };

  clearSignature = () => {
    this.signaturePad.clear();
  };

  getSignatureDataUrl = () => {
    return this.signaturePad.getTrimmedCanvas().toDataURL("image/png");
  };

  saveLead = () => {
    const { application } = this.state;
    const lead = {
      source: "SMA",
      name: `${application.startFirstName} ${application.startLastName}`,
      dba: "N/A",
      phone: application.startPhone,
      email: application.email,
      createdAt: new Date(),
      disposition: "New"
    };
    const firestore = this.props.firebase.firestore;
    addDoc(collection(firestore, "leads"), lead);
  };

  handleNext = async () => {
    if (this.isValid()) {
      const steps = this.getSteps();
      if (
        (this.state.isCheckout === false &&
          this.state.activeStep === steps.length - 1) ||
        (this.state.isCheckout && this.state.activeStep === steps.length)
      ) {
        this.handleSubmit();
      } else {
        let nextStep = this.state.activeStep + 1;
        let application = { ...this.state.application };
        if (nextStep > application.activeStep) {
          application.activeStep = nextStep;
        }
        if (this.state.activeStep === 0) {
          const id = await this.createApplication(application);
          application.id = id;
          if (application.isCheckout) {
            application.firstName = application.startFirstName;
            application.lastName = application.startLastName;
            application.businessPhone = application.startPhone;
            this.saveLead();
          }
        } else {
          if (nextStep === 2) {
            application.dateBusinessStarted = "01/01/" + application.businessYear;

            if (!application.mailingDifferent) {
              application.mailingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.mailingAddress2 = application.businessAddress2;
              }
              application.mailingCity = application.businessCity;
              application.mailingState = application.businessState;
              application.mailingZip = application.businessZip;
            }
            if (!application.shippingDifferent) {
              application.shippingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.shippingAddress2 = application.businessAddress2;
              }
              application.shippingCity = application.businessCity;
              application.shippingState = application.businessState;
              application.shippingZip = application.businessZip;
            }
            application.homePhone = application.businessPhone;
            if (application.ownershipType === "Sole Proprietorship") {
              application.federalTaxId = "00-0000000";
            }
          }
          if (nextStep === 3) {
            const socialSecurityNumber = application.socialSecurityNumber;

            try {
              const {
                data: { encryptedText, salt }
              } = await httpsCallable(
                this.props.firebase.functions,
                "encryptField"
              )({
                plainText: socialSecurityNumber
              });
              application.encryptedSocialSecurityNumber = encryptedText;
              application.encryptedSocialSecurityNumberSalt = salt;
            } catch (err) {
              console.error(
                "error encrypting sensitive data. plain text will not be saved."
              );
            } finally {
              delete application.socialSecurityNumber;
            }

            if (!application.residenceDifferent) {
              application.homeAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.homeAddress2 = application.businessAddress2;
              }
              application.homeCity = application.businessCity;
              application.homeState = application.businessState;
              application.homeZip = application.businessZip;
            }
            if (application.ownershipType === "Sole Proprietorship") {
              //application.federalTaxId = application.socialSecurityNumber;
              application.federalTaxId = "00-0000000";
            }
          }
          if (nextStep === 5) {
            if (application.link && application.link.paymentType === "ACH") {
              application.paymentBankName = application.bankName;
              application.paymentAccountNumber = application.bankAccountNumber;
              application.paymentRoutingNumber = application.routingNumber;
            }
            if (application.paymentType === "Free") {
              nextStep += 1;
            }
          }
          if (nextStep === 4) {
            application.monthlyAmexVolume = Math.round(application.cardSales * 0.1);
            application.cardSalesYear = Math.round(application.cardSales * 12);
          }
          
          await this.saveApplication(application);
        }
        this.setState({ application, activeStep: nextStep });
      }
    }
    window.scrollTo(0, 0);
  };

  handleBack = () => {
    const previousStep = this.state.activeStep - 1;
    if (this.state.activeStep > 0) {
      this.setState({ activeStep: previousStep });
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const completedAt = new Date();
    const signatureDataUrl = this.getSignatureDataUrl();
    const saveResult = await this.addSignatureToStorage(signatureDataUrl);
    const completeApplication = {
      ...this.state.application,
      signatureUrl: saveResult.downloadUrl,
      signatureStoragePath: saveResult.fullPath,
      status: "Verification",
      completedAt
    };
    await this.saveApplication(completeApplication);
    await signOut(this.props.firebase.auth);
    this.props.history.push({
      pathname: "/confirmation",
      state: {
        application: {
          id: this.state.application.id,
          email: this.state.application.email,
          legalBusinessName: this.state.application.legalBusinessName
        }
      }
    });
  };

  handleApplicationTextChange = name => event => {
    const application = { ...this.state.application };
    application[name] = mask(name, event.target.value);
    if (
      name === "ownershipType" &&
      event.target.value === "Sole Proprietorship"
    ) {
      application.federalTaxId = "";
    }
    this.setState({ application });
  };

  handleCheckChange = name => event => {
    let application = { ...this.state.application };
    application[name] = event.target.checked;
    if (name === "mailingDifferent" && event.target.checked) {
      application.mailingAddress1 = "";
      application.mailingAddress2 = "";
      application.mailingCity = "";
      application.mailingState = "";
      application.mailingZip = "";
    } 
    if (name === "residenceDifferent" && event.target.checked) {
      application.homeAddress1 = "";
      application.homeAddress2 = "";
      application.homeCity = "";
      application.homeState = "";
      application.homeZip = "";
    }
    this.setState({ application });

    console.log(application.agree, application.agreeAdditional);
  };

  handlePlanChange = event => {
    let application = { ...this.state.application };
    application.plan = event.target.value;
    this.setState({ application });
  };

  setApplicationPricing = pricing => {
    let application = { ...this.state.application };
    application.pricing = pricing;
    this.setState({ application });
  };

  getSteps = () => {
    if (this.state.application.link) {
      return [
        "Get Started",
        "Business Info",
        "Principal Info",
        "Account Info",
        "Financial Info",
        "Payment Info",
        "Review"
      ];
    } else {
      return [
        "Get Started",
        "Business Info",
        "Principal Info",
        "Account Info",
        "Financial Info",
        "Payment Info",
        "Review"
      ];
    }
  };

  getStepContent = (stepIndex, application, errors) => {
    console.log(application);
    if (this.state.application.link) {
      switch (stepIndex) {
        case 0:
          return (
            <Start
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 1:
          return (
            <Business
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              onCheckChange={this.handleCheckChange}
              errors={errors}
            />
          );
        case 2:
          return (
            <Principal
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              onCheckChange={this.handleCheckChange}
              errors={errors}
            />
          );
        case 3:
          return (
            <Account
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 4:
          return (
            <Financial
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 5:
          return (
            <Cart
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              setApplicationPricing={this.setApplicationPricing}
              onCheckChange={this.handleCheckChange}
              onBack={this.handleBack}
              onNext={this.handleNext}
              errors={errors}
              transaction={this.state.transaction}
            />
          );
        case 6:
          return (
            <Review
              application={application}
              signatureRef={this.signatureRef}
              onApplicationTextChange={this.handleApplicationTextChange}
              onAgreeCheckChange={this.handleCheckChange}
              onAgreeAdditionalCheckChange={this.handleCheckChange}
              onClearSignature={this.clearSignature}
              errors={errors}
            />
          );
        default:
          return <Start />;
      }
    } else {
      switch (stepIndex) {
        case 0:
          return (
            <Start
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 1:
          return (
            <Business
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              onCheckChange={this.handleCheckChange}
              errors={errors}
            />
          );
        case 2:
          return (
            <Principal
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              onCheckChange={this.handleCheckChange}
              errors={errors}
              isSsnEncrypted={
                application.encryptedSocialSecurityNumber
              }
              resetSsn={this.handleResetSsn}
            />
          );
        case 3:
          return (
            <Account
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 4:
          return (
            <Financial
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              errors={errors}
            />
          );
        case 5:
          return (
            <Cart
              application={application}
              onApplicationTextChange={this.handleApplicationTextChange}
              setApplicationPricing={this.setApplicationPricing}
              onCheckChange={this.handleCheckChange}
              onBack={this.handleBack}
              onNext={this.handleNext}
              errors={errors}
              transaction={this.state.transaction}
            />
          );
        case 6:
          return (
            <Review
              application={application}
              signatureRef={this.signatureRef}
              onApplicationTextChange={this.handleApplicationTextChange}
              onAgreeCheckChange={this.handleCheckChange}
              onAgreeAdditionalCheckChange={this.handleCheckChange}
              onClearSignature={this.clearSignature}
              errors={errors}
            />
          );
        default:
          return <Start />;
      }
    }
  };

  isValid = () => {
    const { activeStep, application } = this.state;
    const validationResult = validateStep(
      activeStep,
      application,
      this.signaturePad
    );
    if (!validationResult.isValid) {
      this.setState({ errors: validationResult.errors });
    } else {
      this.setState({ errors: {} });
    }
    return validationResult.isValid;
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: null });
  };

  render() {
    const { handleStartOver, handleResume, handleSnackbarClose } = this;
    const { classes } = this.props;
    const {
      user,
      isCheckout,
      promptResume,
      activeStep,
      application,
      loading,
      errors,
      snackbarMessage
    } = this.state;
    const steps = this.getSteps();
    const stepIndex =
      isCheckout && activeStep > 3 ? activeStep - 1 : activeStep;

    return (
      <div className={classes.root}>
        {isCheckout && (
          <Fragment>
            <MobileHeader steps={steps} activeStep={stepIndex} />
            <Stepper steps={steps} activeStep={stepIndex} />
          </Fragment>
        )}

        {this.getStepContent(activeStep, application, errors)}

        <Actions
          application={application}
          steps={steps}
          activeStep={activeStep}
          onNext={this.handleNext}
          onBack={this.handleBack}
          loading={loading}
        />

        <Footer application={application} />

        <MobileStepper
          application={application}
          steps={steps}
          activeStep={activeStep}
          onNext={this.handleNext}
          onBack={this.handleBack}
          loading={loading}
        />

        <ResumeDialog
          open={promptResume}
          user={user}
          onResume={handleResume}
          onStartOver={handleStartOver}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={5000}
          open={snackbarMessage !== null}
          onClose={handleSnackbarClose}
        >
          <MuiAlert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withFirebase
)(Application);
