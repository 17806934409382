import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withHttpClient } from "../HttpClient";
import { Typography, Paper, Grid, Button, TextField } from "@material-ui/core";
import withLayout from "../Layout/withLayout";
import ImageUpload from "../ImageUpload";
import { addDoc, collection, doc, setDoc } from "@firebase/firestore";

const styles = theme => ({
  paper: {
    margin: "auto",
    overflow: "hidden",
    padding: theme.spacing(2)
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: theme.spacing(1)
  }
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appPassword: props.user.appPassword || "",
      errors: {}
    };
  }

  handleTextChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSaveClick = async () => {
    const firestore = this.props.firebase.firestore;
    await setDoc(
      doc(firestore, "users", this.props.user.uid),
      { appPassword: this.state.appPassword },
      { merge: true }
    );
    await addDoc(
      collection(firestore, "users", this.props.user.uid, "messages"),
      {
        severity: "success",
        text: "Successfully saved settings."
      }
    );
  };

  render() {
    const { user, classes } = this.props;

    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6">Profile Image</Typography>
            </Grid>
            <Grid item xs={12}>
              <ImageUpload user={user} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                Images must be square and at least 350x350.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Settings</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                id="appPassword"
                name="appPassword"
                label="Google App Password"
                value={this.state.appPassword}
                onChange={this.handleTextChange}
                helperText={
                  <Typography variant="caption">
                    Instructions on how to generate an App Password can be found{" "}
                    <a
                      href="https://youtu.be/B-upRSbYmzQ"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      here.
                    </a>
                  </Typography>
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.actions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={this.handleSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withLayout,
  withStyles(styles),
  withHttpClient
)(Settings);
