import algoliaSearch from "algoliasearch";
import _ from "lodash";

const generalSearchParams = {
  hitsPerPage: 10,
  page: 0,
  analytics: false,
  attributesToRetrieve: "*",
  exactOnSingleWordQuery: "word"
};

export default class AlgoliaApi {
  constructor(axios, firebaseAxios) {
    this.axios = axios;
    this.firebaseAxios = firebaseAxios;
  }

  getApiKey = async () => {
    const response = await this.firebaseAxios.get(
      `${process.env.REACT_APP_API_HOST}/algoliaSearchKey`
    );
    return Promise.resolve(response.data);
  };

  autocompleteSearch = (index, publicKey) => {
    const algolia = algoliaSearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      publicKey
    );
    return async input => {
      const params = { ...generalSearchParams };
      let queries = [
        {
          indexName: process.env.REACT_APP_ALGOLIA_LEADS_INDEX,
          query: input,
          params
        },
        {
          indexName: process.env.REACT_APP_ALGOLIA_APPLICATIONS_INDEX,
          query: input,
          params
        }
      ];

      if (index) {
        queries = [
          {
            indexName: index,
            query: input,
            params
          }
        ];
      }

      const { results } = await algolia.search(queries);
      const suggestions = results.map(result => {
        if (result.hits.length > 0) {
          let suggestions = result.hits.map(hit => {
            let e = {
              id: hit.objectID,
              index: result.index,
              ...hit
            };
            return e;
          });
          return suggestions;
        }
        return [];
      });

      const flattened = _.flatten(suggestions);

      return flattened;
    };
  };
}
