import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import styles from "./styles";

class Header extends Component {
  render() {
    const { activeStep, steps, classes } = this.props;
    return (
      <AppBar className={classes.root} position="static">
        <Toolbar className={classes.toolBar}>
          <div className={classes.side}></div>
          <div className={classes.center}>
            <Typography variant="h6" component="h3">
              {steps[activeStep]} - {activeStep + 1}/{steps.length}
            </Typography>
          </div>
          <div className={classes.side} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
