import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Title from "../Title";

import moment from "moment";

import Check from "../../../static/images/checkmark.png";

import styles from "./styles";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: props.location.state.application,
      completedAt: moment().format("L LT")
    };
  }
  render() {
    const { application } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.checkContainer}>
          <img className={classes.check} src={Check} alt="Confirmation" />
        </div>
        <Title
          h1="Thank You!"
          h2="Your application was successfully submitted and is pending approval."
        />
        <div className={classes.confirmContainer}>
          <Typography
            className={classes.textBlock}
            variant="body1"
            gutterBottom
          >
            When your application is approved, we will send you an email within
            two business days (or sooner) that contains instructions to start
            processing credit cards with your new merchant account.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Application Number:</b> {application.id}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Application Date:</b> {this.state.completedAt}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Business Name:</b> {application.legalBusinessName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Email Address:</b> {application.email}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Confirmation));
