import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ETableHead from "./Head";
import { ExportToCsv } from "export-to-csv";
import copy from "copy-to-clipboard";
import Duplicate from "../../static/images/duplicate.png";
import { Snackbar } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%"
  },
  table: {
    width: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  row: {},
  highlight: {
    borderLeft: `solid 10px ${theme.palette.primary.main}`,
    backgroundColor: "#b0dfff !important"
  },
  previous: {
    backgroundColor: "#dbf0ff !important"
  },
  filters: {
    paddingBottom: theme.spacing(1)
  },
  duplicate: {
    backgroundImage: `url(${Duplicate})`,
    backgroundPosition: 0
    //textDecoration: "line-through",
    //color: "red !important"
  }
});

class EDataTable extends React.Component {
  state = {
    selected: null,
    prevSelected: null,
    filterAnchorEl: null,
    snackbarMessage: null
  };

  handleFilterMenuClick = event => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterMenuClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  handleFilterMenuItemClick = column => {
    this.props.onFilterMenuItemClick(column);
  };

  handleDownloadClick = () => {
    const { data, columns } = this.props.data;
    const rows = [];
    data.forEach(row => {
      const newRow = {};
      columns.forEach(column => {
        if (!column.export) {
          return;
        }
        let value = row[column.id];
        if (typeof column.selector === "function") {
          value = column.selector(row);
        }
        newRow[column.label] = value;
      });
      rows.push(newRow);
    });
    const options = {
      fieldSeparator: ",",
      // filename: "",
      quoteStrings: "",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(rows);
  };

  handleClick = (event, id) => {
    const {
      data: { data },
      onRowClick
    } = this.props;
    const isAlreadySelected = this.state.selected === id;
    const newSelected = isAlreadySelected ? null : id;
    this.setState({ selected: newSelected, prevSelected: this.state.selected });
    const selectedRow = data.filter(e => e.id === id)[0];
    onRowClick(selectedRow, isAlreadySelected);
  };

  handleCopyClick = (event, id) => {
    const { data } = this.props.data;
    const values = data.map(row => row[id]);
    const delimeter = "\r\n";
    const options = {
      format: "text/plain",
      onCopy: () => {
        this.setState({
          snackbarMessage: "Emails were successfully copied to clipboard."
        });
      }
    };
    copy(values.join(delimeter), options);
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: null });
  };

  handleRequestSort = (event, property) => {
    const {
      props: {
        data,
        data: { rowsPerPage, where },
        onRetrieveData
      }
    } = this;

    const orderBy = property;
    let order = "desc";

    if (data.orderBy === property && data.order === "desc") {
      order = "asc";
    }

    const searchCriteria = { order, orderBy, page: 0, rowsPerPage, where };

    onRetrieveData(searchCriteria, true);
  };

  handleChangePage = (event, page) => {
    const {
      data: { order, orderBy, rowsPerPage, where },
      onRetrieveData
    } = this.props;

    const searchCriteria = { order, orderBy, page, rowsPerPage, where };

    onRetrieveData(searchCriteria);
  };

  handleDeleteFilter = filterKey => () => {
    const {
      data: { order, orderBy, rowsPerPage, page, where },
      onRetrieveData
    } = this.props;

    delete where[filterKey];

    const searchCriteria = { order, orderBy, page, rowsPerPage, where };

    onRetrieveData(searchCriteria);
  };

  handleChangeRowsPerPage = event => {
    const {
      data: { order, orderBy, where },
      onRetrieveData
    } = this.props;

    const searchCriteria = {
      order,
      orderBy,
      page: 0,
      rowsPerPage: event.target.value,
      where
    };

    onRetrieveData(searchCriteria, true);
  };

  render() {
    const {
      props,
      props: { toolbar, metadata, user, classes, emptyMessage }
    } = this;
    const { selected, prevSelected } = this.state;
    const {
      data,
      order,
      orderBy,
      rowsPerPage,
      page,
      where,
      totalCount
    } = props.data;
    const isAdminOrSupport =
      user && ["super", "admin", "support"].includes(user.role);
    const allColumns = props.data.columns;
    const columns = allColumns.filter(column =>
      column.visible && column.showAdminOnly ? isAdminOrSupport : true
    );
    const emptyRows = rowsPerPage - data.length;
    const filters = where
      ? Object.keys(where).map(key => ({ key, value: where[key] }))
      : null;
    return (
      <div className={classes.root}>
        {toolbar}
        {filters && filters.length > 0 && (
          <div className={classes.filters}>
            {filters.map(e => (
              <Chip
                key={e.key}
                variant="outlined"
                label={`${e.key}: ${e.value === null ? "''" : e.value}`}
                onDelete={this.handleDeleteFilter(e.key)}
              />
            ))}
          </div>
        )}
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              size="small"
              aria-labelledby="tableTitle"
            >
              <ETableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                onCopyButtonClick={this.handleCopyClick}
              />
              <TableBody>
                {data.length > 0 &&
                  data.map(row => {
                    const isSelected = selected === row.id;
                    const isPrevSelected = prevSelected === row.id;
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isSelected || isPrevSelected}
                        className={clsx(classes.row, {
                          [classes.highlight]: isSelected,
                          [classes.previous]: isPrevSelected,
                          [classes.duplicate]: row.duplicate
                        })}
                      >
                        {columns.map(column => {
                          if (!column.visible) {
                            return null;
                          }
                          let value = row[column.id];
                          if (typeof column.selector === "function") {
                            value = column.selector(row, metadata);
                          }
                          return <TableCell key={column.id}>{value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                {data.length > 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={columns.length} />
                  </TableRow>
                )}
                {!data.length && emptyMessage && (
                  <TableRow style={{ height: 400 }}>
                    <TableCell
                      colSpan={columns.length}
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {emptyMessage}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
            component="div"
            labelDisplayedRows={({ page }) => `Page ${page + 1}`}
            rowsPerPage={rowsPerPage}
            count={totalCount}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          autoHideDuration={3000}
          open={this.state.snackbarMessage !== null}
          onClose={this.handleSnackbarClose}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

EDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onFilterMenuItemClick: PropTypes.func,
  onRetrieveData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string
};

export default withStyles(styles)(EDataTable);
