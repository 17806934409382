const styles = theme => ({
  root: {
    marginTop: "5%",
    marginLeft: "10%",
    marginRight: "10%",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginLeft: "5%",
      marginRight: "5%"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginRight: "0"
    }
  },
  textBlock: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify"
    }
  },
  checkContainer: {
    width: "100%",
    textAlign: "center"
  },
  check: {
    width: 200,
    height: 200
  },
  confirmContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  }
});

export default styles;
