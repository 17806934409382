const styles = theme => ({
  root: {
    marginLeft: "10%",
    marginRight: "10%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "5%",
      marginRight: "5%"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginRight: "0"
    }
  },
  done: {
    padding: theme.spacing(10),
    textAlign: "center"
  }
});

export default styles;
