import React from "react";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withLayout } from "../../Layout";
import { withStyles } from "@material-ui/core/styles";
import { LeadsClosingRatioWithFilter } from "../../Charts";
import styles from "../styles";

const Ratios = () => {
  return (
    <div>
      <LeadsClosingRatioWithFilter
        displayFilter={true}
        title="Lead Source Closing Ratio"
      />
    </div>
  );
};

export default compose(
  withLayout,
  withStyles(styles),
  withAuthorization(user => !!user)
)(Ratios);
