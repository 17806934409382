import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../Session";

import { Typography, CircularProgress } from "@material-ui/core";
import { httpsCallable } from "@firebase/functions";
import Stepper from "./Stepper";
import Actions from "./SubmitActions";
import Start from "./steps/Start";
import Business from "./steps/Business";
import Principal from "./steps/Principal";
import Account from "./steps/Account";
import Financial from "./steps/Financial";
import Processing from "./steps/Processing";
import Pricing from "./steps/Pricing";
import Addendums from "./steps/Addendums";
import { mask } from "../../util/MaskUtil";
import { validateStep } from "../../util/ValidationUtil";
import Cart from "./steps/Cart";
import fields from "./steps/Pricing/fields";

import { withFirebase } from "../Firebase";

import IpAddressApi from "../../api/IpAddress";

import styles from "./styles";
import {
  addDoc,
  onSnapshot,
  setDoc,
  getDoc,
  getDocs,
  doc,
  collection,
  updateDoc, 
  deleteField
} from "@firebase/firestore";

const { setHiddenPricingFields } = fields;

class SubmitApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 6,
      loading: false,
      savingNewApplication: false,
      promptResume: null,
      user: null,
      application: {},
      transaction: undefined,
      applicationId: props.applicationId,
      errors: {},
      isSaveButtonVisible: false,
    };
  }

  componentDidMount = async () => {
    const { applicationId } = this.props;
    const firestore = this.props.firebase.firestore;

    const defaultEquipmentDoc = await getDoc(doc(firestore, "settings", "defaultEquipment"));
    const defaultEquipment = defaultEquipmentDoc.data();

    const defaultTerminalsSnapshots = await getDocs(
      collection(firestore, "defaultTerminals")
    );
    const defaultTerminals = defaultTerminalsSnapshots.docs.map(e => ({
      ...e.data(),
      id: e.id,
      type: 'TM',
      name: `${e.data().BrandName} ${e.data().ModelName}`
    }));

    const selectedTerminal = defaultTerminals.find(
      e => e.name.toLowerCase().includes(defaultEquipment.name.toLowerCase())
    ) || defaultTerminals[0];
    
    const selectedProcessor = selectedTerminal.Processors.find(
      e => e.Name.toLowerCase().includes(defaultEquipment.processor.toLowerCase())
    );

    if (applicationId) {
      onSnapshot(doc(firestore, "applications", applicationId), (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          this.setState({
            application: {
              ...data,
              isSubmitting: true,
            },
            defaultEquipment: selectedTerminal,
            defaultProcessor: selectedProcessor
          });
        }
      });
    }
  };

  createApplication = async (application) => {
    this.setState({ savingNewApplication: true, loading: true });

    const ipAddressResults = await IpAddressApi.getIpAddress();

    const newApplication = {
      ...application,
      activeStep: 1,
      createdAt: new Date(),
      repIpAddress: ipAddressResults.ipAddress,
    };

    const firestore = this.props.firebase.firestore;
    const d = await addDoc(
      collection(firestore, "repApplications"),
      newApplication
    );

    this.setState({
      savingNewApplication: false,
      loading: false,
      application: {
        ...newApplication,
        id: d.id,
      },
    });
    return d.id;
  };

  saveApplication = async (application) => {
    const firestore = this.props.firebase.firestore;

    console.log('saving application', application);
    return await setDoc(
      doc(firestore, "applications", application.id),
      application,
      { merge: true }
    );
  };

  deleteCustomPricing = async (application) => {
    const firestore = this.props.firebase.firestore;
    const docRef = doc(firestore, "applications", application.id);
    await updateDoc(docRef, {
      customPricing: deleteField(),
    });
  };

  decryptSsn = async (application) => {
    if (
      application.status !== "Sent To Api" &&
      !application.socialSecurityNumber
    ) {
      try {
        const response = await httpsCallable(
          this.props.firebase.functions,
          "decryptField"
        )({
          encryptedText: application.encryptedSocialSecurityNumber,
          salt: application.encryptedSocialSecurityNumberSalt,
        });
        application.socialSecurityNumber = response.data.decryptedText;
      } catch (err) {
        console.error("error decrypting sensitive data", err);
      }
    }
    else {
      console.log('skipped decrypring');
    }
  }

  handleNext = async () => {
    if (this.isValid()) {
      const steps = this.getSteps();
      if (this.state.activeStep === steps.length - 1) {
        this.handleSubmit();
      } else {
        let nextStep = this.state.activeStep + 1;
        let application = { ...this.state.application };
        if (nextStep > application.activeStep) {
          application.activeStep = nextStep;
        }
        if (this.state.activeStep === 0) {
        } else {
          if (nextStep === 2) {
            application.dateBusinessStarted =
              "01/01/" + application.businessYear;

            if (!application.mailingDifferent) {
              application.mailingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.mailingAddress2 = application.businessAddress2;
              }
              application.mailingCity = application.businessCity;
              application.mailingState = application.businessState;
              application.mailingZip = application.businessZip;
            }
            if (!application.shippingDifferent) {
              application.shippingAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.shippingAddress2 = application.businessAddress2;
              }
              application.shippingCity = application.businessCity;
              application.shippingState = application.businessState;
              application.shippingZip = application.businessZip;
            }
            if (!application.homePhone) {
              application.homePhone = application.businessPhone;
            }
            if (application.ownershipType === "Sole Proprietorship") {
              application.federalTaxId = "00-0000000";
            }

            // decrypt and show until submitted
            await this.decryptSsn(application);
          }
          if (nextStep === 3) {
            if (
              !application.encryptedSocialSecurityNumber ||
              application.socialSecurityNumber
            ) {
              const socialSecurityNumber = application.socialSecurityNumber;

              try {
                const {
                  data: { encryptedText, salt },
                } = await httpsCallable(
                  this.props.firebase.functions,
                  "encryptField"
                )({
                  plainText: socialSecurityNumber,
                });
                application.encryptedSocialSecurityNumber = encryptedText;
                application.encryptedSocialSecurityNumberSalt = salt;
              } catch (err) {
                console.error(
                  "error encrypting sensitive data. plain text will not be saved."
                );
              } finally {
                delete application.socialSecurityNumber;
              }
            }
            if (!application.residenceDifferent) {
              application.homeAddress1 = application.businessAddress1;
              if (application.businessAddress2) {
                application.homeAddress2 = application.businessAddress2;
              }
              application.homeCity = application.businessCity;
              application.homeState = application.businessState;
              application.homeZip = application.businessZip;
            }
            if (application.ownershipType === "Sole Proprietorship") {
              application.federalTaxId = "00-0000000";
            }
          }
          if (nextStep === 4) {
            application.monthlyAmexVolume = Math.round(
              application.cardSales * 0.1
            );
            application.cardSalesYear = Math.round(application.cardSales * 12);
          }
          if (nextStep === 6) {
            if (application.link && application.link.paymentType === "ACH") {
              application.paymentBankName = application.bankName;
              application.paymentAccountNumber = application.bankAccountNumber;
              application.paymentRoutingNumber = application.routingNumber;
            }
            if (application.paymentType === "Free") {
              nextStep += 1;
            }
          }
          if (nextStep === 7) {
            this.setEquipmentFields(application);
            application.terminalShippingEmail = application.terminalShippingEmail || application.email;
            application.runCreditReport = (application.runCreditReport === undefined ? true : application.runCreditReport);
          }
          if (nextStep === 8) {
            await this.deleteCustomPricing(application);
          }
          if (nextStep === 9) {
            setHiddenPricingFields(application);
          }
        }
        await this.saveApplication(application);
        this.setState({
          application,
          activeStep: nextStep,
          isSaveButtonVisible: nextStep === 9,
        });
      }
    }
    window.scrollTo(0, 0);
  };

  setEquipmentFields = async (application) => {
    if (application.reprogram === undefined) {
      application.reprogram = true;
    }

    let equipmentData = application.link ? application.link.equipment.map(equip => ({
      EquipmentTypeID: equip.type || 'TM', // Terminal is default
      EquipmentID: equip.TerminalID || equip.GatewayID,
      ProcessorID: equip.processor.ProcessorID,
      FieldValues: [],
      Peripherials: []
    })) : [];

    if (equipmentData.length == 0) {
      console.log('equipment - default');
      
      equipmentData = [{
        EquipmentTypeID: 'TM',
        EquipmentID: this.state.defaultEquipment.TerminalID,
        ProcessorID: this.state.defaultProcessor.ProcessorID,
        FieldValues: [],
        Peripherials: []
      }];
    }

    application.equipment = equipmentData;

    console.log('equipment', application.equipment, application);
    
  };

  handleBack = async () => {
    let application = { ...this.state.application };
    let nextStep = this.state.activeStep - 1;

    if (nextStep === 2) {
      await this.decryptSsn(application);
    }
    
    const previousStep = this.state.activeStep - 1;
    if (this.state.activeStep > 0) {
      this.setState({ application, activeStep: previousStep });
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleSaveAndSend = async () => {
    try {
      const { application } = this.state;
      application.status = "Sending To Api";
      application.error = "";
      application.validation = "";
      await this.saveApplication(application);

      this.setState({ sent: true });
      this.props.onSave && this.props.onSave(application.id);
    } catch (error) {
      console.error("Error saving and sending application:", error);
    }
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
  };

  handleResetSsn = () => {
    const application = { ...this.state.application };
    application.encryptedSocialSecurityNumber = "";
    this.setState({ application });
  };

  handleApplicationTextChange = (name, container) => (event) => {
    if (!event) {
      return;
    }

    let val = event.target.value;

    const application = { ...this.state.application };
    if (container) {
      application[container][name] = mask(name, val);
    } else {
      application[name] = mask(name, val);
    }

    if (
      name === "ownershipType" &&
      val === "Sole Proprietorship"
    ) {
      application.federalTaxId = "";
    }

    if (name === "office") {
      application.salesRepInfo = null;
    }

    if (name === "customPricingType") {
      application.customPricing = {};
    }

    this.setState({ application });
  };

  handleApplicationAutocompleteChange = (name, container) => (event, value) => {
    if (!event) {
      return;
    }
    let val = (value === undefined) ? event.target.value : value;
    if (!val && name === "soldProductTypes") {
      val = event.target.textContent;
    }

    const application = { ...this.state.application };
    if (container) {
      application[container][name] = mask(name, val);
    } else {
      application[name] = mask(name, val);
    }
    this.setState({ application });
  };

  handleCheckChange = (name) => (event) => {
    let application = { ...this.state.application };
    application[name] = event.target.checked;
    if (name === "mailingDifferent" && event.target.checked) {
      application.mailingAddress1 = "";
      application.mailingAddress2 = "";
      application.mailingCity = "";
      application.mailingState = "";
      application.mailingZip = "";
    } 
    if (name === "shippingDifferent" && event.target.checked) {
      application.shippingAddress1 = "";
      application.shippingAddress2 = "";
      application.shippingCity = "";
      application.shippingState = "";
      application.shippingZip = "";
    }
    if (name === "residenceDifferent" && event.target.checked) {
      application.homeAddress1 = "";
      application.homeAddress2 = "";
      application.homeCity = "";
      application.homeState = "";
      application.homeZip = "";
    }
    this.setState({ application });
  };

  handlePlanChange = (event) => {
    let application = { ...this.state.application };
    application.plan = event.target.value;
    this.setState({ application });
  };

  setApplicationPricing = (pricing) => {
    let application = { ...this.state.application };
    application.pricing = pricing;
    this.setState({ application });
  };

  getSteps = () => {
    return [
      "Email Address",
      "Business Info",
      "Principal Info",
      "Account Info",
      "Financial Info",
      "Payment Info",
      "Processing Info",
      "Credit card sales and Pricing",
      "Addendums",
      "Done",
    ];
  };

  showError = (application) => {
    if (application.error && application.error.Results) {
      return application.error.Results.map((item) => (
        <p key={item.ReferenceID}>
          {item.Message} ({item.ReferenceID})
        </p>
      ));
    }
    if (application.error && application.error.Message) {
      return <p>{application.error.Message}</p>
    }
  };

  showValidationError = (application) => {
    if (application.validation && application.validation[0]) {
      return application.validation[0].Results.map((item) => (
        <div key={item.ReferenceID}>
          {item.Message} {item.ReferenceID}
        </div>
      ));
    }
  };

  getStepContent = (stepIndex, application, errors) => {
    switch (stepIndex) {
      case 0:
        return (
          <Start
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 1:
        return (
          <Business
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            onCheckChange={this.handleCheckChange}
            errors={errors}
          />
        );
      case 2:
        return (
          <Principal
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            onCheckChange={this.handleCheckChange}
            errors={errors}
            isSsnEncrypted={
              application.status === "Sent To Api" &&
              application.encryptedSocialSecurityNumber
            }
            resetSsn={this.handleResetSsn}
          />
        );
      case 3:
        return (
          <Account
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 4:
        return (
          <Financial
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            errors={errors}
          />
        );
      case 5:
        return (
          <Cart
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            setApplicationPricing={this.setApplicationPricing}
            onCheckChange={this.handleCheckChange}
            onBack={this.handleBack}
            onNext={this.handleNext}
            errors={errors}
            transaction={this.state.transaction}
          />
        );
      case 6:
        return (
          <Processing
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            setApplicationPricing={this.setApplicationPricing}
            onCheckChange={this.handleCheckChange}
            onBack={this.handleBack}
            onNext={this.handleNext}
            errors={errors}
            transaction={this.state.transaction}
          />
        );
      case 7:
        return (
          <Pricing
            application={application}
            onApplicationTextChange={this.handleApplicationTextChange}
            onApplicationAutocompleteChange={this.handleApplicationAutocompleteChange}
            setApplicationPricing={this.setApplicationPricing}
            onCheckChange={this.handleCheckChange}
            onBack={this.handleBack}
            onNext={this.handleNext}
            errors={errors}
            transaction={this.state.transaction}
          />
        );
      case 8:
        return (
          <Addendums
            applicationId={this.props.applicationId}
            onApplicationTextChange={this.handleApplicationTextChange}
            setApplicationPricing={this.setApplicationPricing}
            onCheckChange={this.handleCheckChange}
            onBack={this.handleBack}
            onNext={this.handleNext}
            errors={errors}
            transaction={this.state.transaction}
          />
        );
      case 9:
        return (
          <div className={this.props.classes.done}>
            {this.state.sent ? (
              <Typography>
                <p>Application will be submitted to Paysafe</p>
                <div>
                  {(application.status === "Sending To Api" ||
                    application.status === "Uploading Documents") && (
                    <p>
                      <CircularProgress size={24} color="inherit" />
                    </p>
                  )}
                  {application.status}
                </div>
                {this.showError(application)}
                {this.showValidationError(application)}
              </Typography>
            ) : (
              <Typography>
                You will see the submittion result on the Applications page.
              </Typography>
            )}
          </div>
        );
      default:
        throw RangeError("Invalid step");
    }
  };

  isValid = () => {
    const { activeStep, application } = this.state;
    const validationResult = validateStep(activeStep, application);
    if (!validationResult.isValid) {
      this.setState({ errors: validationResult.errors });
    } else {
      this.setState({ errors: {} });
    }
    return validationResult.isValid;
  };

  render() {
    const { classes } = this.props;
    const {
      activeStep,
      application,
      loading,
      errors,
      isSaveButtonVisible,
    } = this.state;
    const steps = this.getSteps();
    const stepIndex = activeStep;

    return (
      <div className={classes.root}>
        <Stepper steps={steps} activeStep={stepIndex} />
        {this.getStepContent(activeStep, application, errors)}
        <Actions
          application={application}
          steps={steps}
          activeStep={activeStep}
          onNext={this.handleNext}
          onSave={this.handleSaveAndSend}
          onBack={this.handleBack}
          loading={loading}
          showSubmit={
            isSaveButtonVisible &&
            (!this.state.sent || application.status === "Api Errors")
          }
          showBack={!this.state.sent || application.status === "Api Errors"}
          showNext={!this.state.sent || application.status === "Api Errors"}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withAuthorization(user => !!user),
  withFirebase
)(SubmitApp);
