import React, { Fragment, PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import replace from "react-string-replace";

import TextField from "@material-ui/core/TextField";

import Title from "../../Title";

import styles from "../styles";

class Start extends PureComponent {
  render() {
    const {
      application,
      onApplicationTextChange,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        <Title
          h1={"Let's Get Started"}
          h2={
            application.isCheckout
              ? null
              : replace(
                  "This application takes about 5 minutes to complete. If you would rather apply by phone, call 888-241-8393 and an application specialist will assist you.",
                  "888-241-8393",
                  (match, i) => (
                    <a key={i} href={`tel:${match}`}>
                      {match}
                    </a>
                  )
                )
          }
        />
        <div className={classes.sectionForm}>
          {application.isCheckout && (
            <Fragment>
              <div className={classes.inputContainer}>
                <TextField
                  id="startFirstName"
                  label="First Name"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.startFirstName || ""}
                  onChange={onApplicationTextChange("startFirstName")}
                  error={!!errors.startFirstName}
                  helperText={errors.startFirstName}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="startLastName"
                  label="Last Name"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.startLastName || ""}
                  onChange={onApplicationTextChange("startLastName")}
                  error={!!errors.startLastName}
                  helperText={errors.startLastName}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="startPhone"
                  label="Phone"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.startPhone || ""}
                  onChange={onApplicationTextChange("startPhone")}
                  error={!!errors.startPhone}
                  helperText={errors.startPhone}
                  margin="normal"
                  fullWidth
                />
              </div>
            </Fragment>
          )}
          <div className={classes.inputContainer}>
            <TextField
              id="email"
              label="Email Address"
              placeholder="emailname@example.com"
              variant="outlined"
              className={classes.sideMargins}
              value={application.email || ""}
              onChange={onApplicationTextChange("email")}
              error={!!errors.email}
              helperText={errors.email}
              margin="normal"
              fullWidth
            />
            <TextField
              id="confirmEmail"
              label="Confirm Email Address"
              placeholder="emailname@example.com"
              variant="outlined"
              className={classes.sideMargins}
              value={application.confirmEmail || ""}
              onChange={onApplicationTextChange("confirmEmail")}
              error={!!errors.confirmEmail}
              helperText={errors.confirmEmail}
              margin="normal"
              fullWidth
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Start);
