import React, { Component, Fragment, createRef } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import withWidth from '@material-ui/core/withWidth';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getContent } from "./text.js";
import { jsPDF } from "jspdf";
import { getDoc, doc } from "@firebase/firestore";
import { withFirebase } from "../../Firebase";

const downloadPDF = (content) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a2'
  });

  const container = document.createElement('div');
  container.innerHTML = content;
  container.style.fontSize = '8px';

  doc.html(container, {
    callback: function (doc) {
      doc.save('epp.pdf');
    },
    x: 10,
    y: 10
  });
}

const styles = theme => ({
  dialogPaper: {
    minWidth: "850px",
  },
});

class TermsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      scroll: "paper",
      defaultEquipment: null,
    };
    this.descriptionElementRef = createRef();
  }

  async componentDidMount() {
    const firestore = this.props.firebase.firestore;
    const defaultEquipmentDoc = await getDoc(doc(firestore, "settings", "defaultEquipment")); 
    const defaultEquipment = defaultEquipmentDoc.data();
    this.setState({ defaultEquipment });
  }

  handleClickOpen = scrollType => () => {
    this.setState({ open: true, scroll: scrollType });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, application, width } = this.props;
    const { open, scroll, defaultEquipment } = this.state;
    const fullScreen = width === 'xs' || width === 'sm';

    return (
      <Fragment>
        <Link href="#" onClick={this.handleClickOpen("body")}>
          Equipment Placement Program
        </Link>
        <Dialog
          open={open}
          onClose={this.handleClose}
          scroll={"paper"}
          fullScreen={fullScreen}
          aria-labelledby="terms-dialog-title"
          aria-describedby="terms-dialog-description"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle id="terms-dialog-title">Equipment Placement Program</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="terms-dialog-description"
              ref={this.descriptionElementRef}
              tabIndex={-1}
            >
              {application.Id}
              <Typography variant="h6" component="h3" dangerouslySetInnerHTML={{ __html: getContent(application, defaultEquipment) }}>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {fullScreen || (
              <Button size="small" onClick={() => downloadPDF(getContent(application, defaultEquipment))} color="primary">
                Download
              </Button>
            )}
            <Button
              size="small"
              onClick={this.handleClose}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withFirebase(withTheme(withStyles(styles)(withWidth()(TermsDialog))));
