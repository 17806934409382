const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "calc(100% - 32px)",
    background: "#ffffff",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
});

export default styles;
