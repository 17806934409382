import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ResumeDialog = ({ open, user, onResume, onStartOver }) => {
  return (
    <Dialog
      open={!!open}
      aria-labelledby="resume-dialog-title"
      aria-describedby="resume-dialog-description"
    >
      <DialogTitle id="resume-dialog-title">
        Continue where you left off?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We see that you've already started an application. Would you like to
          continue where you left off?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onStartOver} color="primary">
          Start a New Application
        </Button>
        <Button
          size="small"
          onClick={() => onResume(user)}
          variant="contained"
          color="secondary"
        >
          Resume Application
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResumeDialog;
