import React, { useState, FC, ReactElement, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Firebase from '../../Firebase';
import { httpsCallable } from "@firebase/functions";
import { collection, getDocs, query, orderBy } from '@firebase/firestore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';

interface IAuthUser {
  displayName: string;
  email: string;
  uid: string;
  metadata: any;
  isAdmin: boolean;
  algoliaPublicKey: string;
  role: string;
}

interface ICreateCampaignDialog {
  authUser: IAuthUser;
  firebase: Firebase;
  open: boolean;
  handleClose: () => void;
  handleRefresh: () => void;
}

const useStyles = makeStyles({
  sourcesContainer: {
    maxHeight: 200,
    overflowY: 'auto',
    marginTop: 10,
    marginBottom: 10,
  }
});

export const CreateCampaignDialog: FC<ICreateCampaignDialog> = ({ authUser, firebase, open, handleClose, handleRefresh }): ReactElement => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [campaignType, setCampaignType] = useState("");
    const [campaignTypes, setCampaignTypes] = useState<any>([]);
    const [description, setDescription] = useState("");
    const [sources, setSources] = useState<string[]>([]);
    const [allSources, setAllSources] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        loadCampaignTypes();
        loadSources();
    }, []); // eslint-disable-line

    const loadCampaignTypes = async() => {
      let q = query(collection(firebase.firestore, "campaignTypes"));
      q = query(q, orderBy('name'));
      const docs = await getDocs(q);
      const results = docs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCampaignTypes(results);
    }

    const loadSources = async() => {
      let q = query(collection(firebase.firestore, "sources"));
      q = query(q, orderBy('name'));
      const docs = await getDocs(q);
      const results = docs.docs.map(doc => ({ ...doc.data(), id: doc.id, name: doc.data().name }));
      setAllSources(results);
    }

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        set(event.target.value);
    }

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
      const updatedSources = event.target.checked
        ? [...sources, name]
        : sources.filter(source => source !== name);
      setSources(updatedSources);
    }

    const handleSubmit = async () => {
      if (authUser.isAdmin) {
        try {
            await httpsCallable(firebase.functions, "createCampaign")({ name, campaignType, description, sources });
        } catch (error) {
            setErrors({ name: error });
            return;
        }
        handleRefresh();
      }
    }

    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
        <DialogTitle id="form-dialog">Create Campaign</DialogTitle>
        <DialogContent>
            <TextField
                variant="outlined"
                margin="normal"
                id="name"
                name="name"
                label="Name"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}      
                onChange={handleTextChange(setName)}
                fullWidth
            />
            <TextField
                variant="outlined"
                margin="normal"
                id="description"
                name="description"
                label="Description"
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}      
                onChange={handleTextChange(setDescription)}
                fullWidth
            />
            <TextField
                variant="outlined"
                margin="normal"
                id="campaignType"
                name="campaignType"
                label="Campaign Type"
                value={campaignType}
                helperText={errors.campaignType}
                error={Boolean(errors.campaignType)}
                onChange={handleTextChange(setCampaignType)}
                fullWidth
                select
            >
              {campaignTypes.map((ct: any) => (
                <MenuItem key={ct.id} value={ct.name}>{ct.name}</MenuItem>
              ))}
            </TextField>
            <div className={classes.sourcesContainer}>
              <FormGroup>
                {allSources.map(source => (
                  <FormControlLabel
                    key={source.id}
                    control={
                      <Checkbox
                        checked={sources.includes(source.name)}
                        onChange={(e) => handleSourceChange(e, source.name)}
                        name={source.name}
                      />
                    }
                    label={source.name}
                  />
                ))}
              </FormGroup>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button onClick={handleSubmit} size="small" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
}
