import React, { useState, FC, ReactElement, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from 'react-redux';
import Firebase, { withFirebase } from '../Firebase';
import { collection, query, orderBy, onSnapshot } from '@firebase/firestore';
import { withLayout } from "../Layout";
import { withAuthorization } from "../Session";
import DataTable from "../DataTableInfiniteScroll";
import { ClassNameMap } from "@material-ui/styles";
import { Button, TextField, Typography, withStyles } from "@material-ui/core";
import _ from 'lodash';
import styles from "./styles";
import { CreateCampaignDialog } from './CreateCampaignDialog/';
import { CampaignPanel } from './CampaignPanel/';

interface ICampaignsPage {
    classes: ClassNameMap<any>;
    firebase: Firebase;
    campaignsProps: any;
    user: any;
}

const CampaignsPage: FC<ICampaignsPage> = (props): ReactElement => {
    let { classes, firebase, campaignsProps, user } = props;
    const [allCampaigns, setAllCampaigns] = useState<any>([]);
    const [campaigns, setCampaigns] = useState<any>(campaignsProps);
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [row, setRow] = useState<any>(null);

    useEffect(() => {
        loadCampaigns();
    }, []); // eslint-disable-line

    useEffect(() => {
        filterCampaigns();
    }, [campaigns.order, campaigns.page, campaigns.where]); // eslint-disable-line

    const loadCampaigns = async () => {
        let q = query(collection(firebase.firestore, "campaigns"));
        q = query(q, orderBy('name'));

        onSnapshot(q, snapshot => {
            if (snapshot.docs.length) {
                const results = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setAllCampaigns(results);
                setCampaigns({ ...campaignsProps, data: results.slice(0, campaigns.rowsPerPage) });
            }
        });
    };

    const filterCampaigns = () => {
        let data = allCampaigns;

        if (campaigns.where) {
            data = _.filter(allCampaigns, o => 
                (o.name && o.name.toUpperCase().indexOf(campaigns.where.toUpperCase()) !== -1) ||
                (o.source && o.source.toUpperCase().indexOf(campaigns.where.toUpperCase()) !== -1)
            );
        }

        data = _.orderBy(data, campaigns.orderBy, campaigns.order);
        data = data.slice(0, campaigns.rowsPerPage * (campaigns.page + 1));

        setCampaigns({ ...campaigns, data });
    }

    const handleSearchTextChanged = (event: any) => {
        setCampaigns({
            ...campaigns,
            page: 0,
            where: event.target.value || null
        });
    }

    const handleRetrieveData = (searchCriteria: any) => {
        setCampaigns({
            ...campaigns,
            order: searchCriteria.order,
            page: 0
        });
    }
    
    const handleNextPageLoad = () => {
        setCampaigns({
            ...campaigns,
            page: campaigns.page + 1
        });
    }

    const handleReset = () => {
        setCampaigns({
            ...campaigns,
            page: 0,
            where: null
        });
    }

    const handleCampaignCreate = (open: boolean) => () => {
        setCreateDialog(open);
    }

    const handleCampaignCreated = async () => {
        setCreateDialog(false);
    }

    const handleRowClick = (selectedRow: any, isAlreadySelected: boolean) => {
        setRow(isAlreadySelected ? null : selectedRow);
    }

    return (
        <div>
            <div className={classes.titleBar}>
                <Typography variant="h4">Campaigns</Typography>
                <div className={classes.actions}>
                    {user.isAdmin && (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleCampaignCreate(true)}
                        >
                            Create
                        </Button>
                    )}
                </div>
                {createDialog && (
                    <CreateCampaignDialog
                        authUser={user}
                        firebase={firebase}
                        open={createDialog}
                        handleClose={handleCampaignCreate(false)}
                        handleRefresh={handleCampaignCreated}
                    />
                )}
            </div>
            <div className={classes.toolbar}>
                <div className={classes.search}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        onChange={handleSearchTextChanged}
                        value={campaigns.where || ''}
                    />
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={handleReset}
                        disabled={!campaigns.where}
                        style={{ marginLeft: '8px' }}
                    >
                        Reset Filters
                    </Button>
                </div>
            </div>            
            <div className={classes.container}>
                {/* @ts-ignore */}
                <DataTable
                    title="Campaigns"
                    data={campaigns}
                    emptyMessage="empty"
                    onFilterMenuItemClick={() => {}}
                    onRetrieveData={handleRetrieveData}
                    onRowClick={handleRowClick}
                    onNext={handleNextPageLoad}
                    hasMore={true}
                    loading={false}
                    distanceBottom={1500}
                    disableFilters={true}
                />
                {user.isAdmin && row && (
                    <CampaignPanel
                        classes={classes}
                        firebase={firebase}
                        campaign={row}
                        campaigns={allCampaigns}
                    />
                )}
            </div>
        </div>
    );
}

const condition = (user: any) => !!user;

export default compose<ICampaignsPage, Element>(
    withLayout,
    withFirebase,
    withAuthorization(condition),
    withStyles(styles as any),
    connect((state: any) => {
        return ({
      campaignsProps: state.campaigns
    })})
)(CampaignsPage);
