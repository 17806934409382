import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withLayout } from "../Layout";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  Grid,
  InputAdornment,
  Button,
  IconButton,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import RepApp from "../Application/rep";
import Quote from "../Quote";

import { withFirebase } from "../Firebase";

import ZipTaxApi from "../../api/ZipTax";
import _ from "lodash";

import styles from "./styles";
import {
  addDoc,
  collection,
  doc,
  query,
  where,
  getDocs,
  setDoc
} from "@firebase/firestore";

const debouncedSearch = AwesomeDebouncePromise(ZipTaxApi.getTaxes, 750);

class Links extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startApplication: false,
      startQuote: false,
      includeTaxes: true,
      shipping: 0.0,
      terminationFee: "0.00",
      paymentPlan: "1",
      paymentType: "Credit Card",
      processor: "",
      inventory: [],
      processors: [],
      chosenEquipment: {},
      errors: {},
      showPricelessLink: false
    };
    const locationState = this.props.location.state;
    if (locationState && locationState.lead) {
      this.state.lead = locationState.lead;
    }
  }

  componentDidMount = async () => {
    const firestore = this.props.firebase.firestore;
    const defaultTerminalsSnapshots = await getDocs(
      collection(firestore, "defaultTerminals")
    );
    const defaultGatewaysSnapshots = await getDocs(
      collection(firestore, "defaultGateways")
    );
    const defaultTerminals = defaultTerminalsSnapshots.docs.map(e => ({
      ...e.data(),
      id: e.id,
      type: 'TM',
      name: `${e.data().BrandName} ${e.data().ModelName}`
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

    const defaultGateways = defaultGatewaysSnapshots.docs.map(e => ({
      ...e.data(),
      id: e.id,
      type: 'G',
      name: `${e.data().Name}`
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

    const defaultEquipment = [...defaultTerminals, ...defaultGateways];

    this.setState({ inventory: defaultEquipment, inventoryProcessors: [] });
  };

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleHeaderEquipmentTextChange = () => event => {
    let inventory = this.state.inventory.find(x => x.id == event.target.value);
    this.setState({ ['equipment']: event.target.value, inventoryProcessors: inventory.Processors });
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRepAppSave = repAppId => {
    this.setState({ repAppId });
  };

  handleRemoveLeadLink = () => {
    this.setState({ lead: null });
  };

  getZipTaxResults = async zipCode => {
    if (zipCode.length === 5) {
      this.setState({ loading: true });
      const results = await debouncedSearch(zipCode);
      if (results.rCode === 100 && results.results.length) {
        const taxes = (results.results[0].taxSales * 100).toFixed(2);
        this.setState({ taxes, loading: false });
      } else {
      }
      this.setState({ loading: false });
    }
  };

  handleZipCodeSearch = async event => {
    const zipCode = event.target.value;
    this.setState(
      { zipCode },
      async () => await this.getZipTaxResults(zipCode)
    );
  };

  handleEquipmentTextChange = (index, id) => event => {
    const { chosenEquipment } = this.state;
    let addedEquipment = chosenEquipment[id];
    addedEquipment.prices[index] = event.target.value;
    const total = addedEquipment.prices.reduce(
      (prev, curr) => prev + parseFloat(curr),
      0
    );
    addedEquipment.price = (total / addedEquipment.quantity).toString();
    this.setState({
      chosenEquipment: { ...chosenEquipment, [id]: addedEquipment }
    });
  };

  handleEquipmentFeeTextChange = id => event => {
    const { chosenEquipment } = this.state;
    let addedEquipment = chosenEquipment[id];
    addedEquipment.softwareFee = event.target.value;
    this.setState({
      chosenEquipment: { ...chosenEquipment, [id]: addedEquipment }
    });
  };

  handleDescriptionTextChange = id => event => {
    const { chosenEquipment } = this.state;
    let addedEquipment = chosenEquipment[id];
    addedEquipment[event.target.name] = event.target.value;
    this.setState({
      chosenEquipment: { ...chosenEquipment, [id]: addedEquipment }
    });
  };

  handleAddEquipmentClick = () => {
    const { inventory, equipment, quantity, chosenEquipment, processor} = this.state;
    const equipmentKey = `${equipment}-${processor}`;
    let addedEquipment = chosenEquipment[equipmentKey];
    if (addedEquipment) {
      addedEquipment = {
        ...addedEquipment,
        quantity: addedEquipment.quantity + quantity
      };
    } else {
      const equipmentObject = inventory.filter(e => e.id === equipment)[0];
      const processorObject = equipmentObject.Processors.filter(p => p.ProcessorID === processor)[0];
      addedEquipment = {
        equipment: equipmentObject,
        processor: processorObject,
        quantity,
        price: "1.00",
        prices: new Array(quantity).map(() => ""),
        softwareFee: "0.00",
        hardwareDescription: "",
        feeDescription: ""
      };
    }
    this.setState({
      chosenEquipment: { ...chosenEquipment, [equipmentKey]: addedEquipment }
    });
  };

  handleDeleteEquipmentClick = (equipmentId) => () => {
    let chosenEquipment = { ...this.state.chosenEquipment };
    delete chosenEquipment[equipmentId];
    this.setState({ chosenEquipment: chosenEquipment });
  };

  getSubtotal = () => {
    const { chosenEquipment } = this.state;
    let subtotal = Object.keys(chosenEquipment).reduce(
      (previous, current) =>
        parseFloat(chosenEquipment[current].price) *
          chosenEquipment[current].quantity +
        previous,
      0
    );
    return subtotal;
  };

  getSubtotalWithDiscount = () => {
    const { discountAmount, discountPercent } = this.state;
    let subtotal = this.getSubtotal();
    if (discountAmount) {
      subtotal = subtotal - parseFloat(discountAmount);
    }
    if (discountPercent) {
      subtotal = subtotal - subtotal * (parseFloat(discountPercent) / 100);
    }
    return subtotal;
  };

  getTaxes = () => {
    const subtotal = this.getSubtotalWithDiscount();
    const { includeTaxes, taxes } = this.state;
    if (includeTaxes && subtotal && taxes) {
      return subtotal * (taxes / 100);
    } else {
      return 0.0;
    }
  };

  getOrderTotal = () => {
    const subtotal = this.getSubtotalWithDiscount();
    const { shipping, additionalFee } = this.state;

    const orderTotal =
      parseFloat(subtotal) +
      this.getTaxes() +
      parseFloat(shipping || "0.00") +
      parseFloat(additionalFee || "0.00");
    return orderTotal.toFixed(2);
  };

  getDownPayment = () => {
    return (parseFloat(this.state.downPayment) || 0) // (this.getOrderTotal() || 0) / (this.state.paymentPlan || 1)
      .toFixed(2);
  };

  getBalance = () => {
    return (
      parseFloat(this.getOrderTotal()) - parseFloat(this.getDownPayment())
    ).toFixed(2);
  };

  closeDialog = () => {
    this.setState({ linkId: null, repAppId: null, showPricelessLink: false });
  };

  handleShowPricelessLink = () => {
    this.setState({ showPricelessLink: true });
  };

  handleCloseApplication = () => {
    this.setState({ startApplication: false });
  };

  handleCloseQuote = () => {
    this.setState({ startQuote: false });
  };

  generateLink = async () => {
    const linkId = await this.generate();
    this.setState({ linkId });
  };

  generateQuote = async () => {
    const linkId = await this.generate();
    this.setState({ linkId, startQuote: true });
  };

  startApplication = async () => {
    const linkId = await this.generate();
    this.setState({ linkId, startApplication: true });
  };

  handleSaveQuote = async quoteId => {
    const firestore = this.props.firebase.firestore;
    const quoteUrl = `https://app.posbrokers.com/quote/${quoteId}`;
    if (this.state.lead) {
      await setDoc(
        doc(firestore, "leads", this.state.lead.id),
        { quoteUrl, quoteId },
        { merge: true }
      );
    }
    this.setState({ quoteId });
  };

  generate = async () => {
    const { user } = this.props;
    const link = { ...this.state };
    delete link.equipment;
    const firestore = this.props.firebase.firestore;
    const agentSnapshot = await getDocs(
      query(collection(firestore, "agents"), where("uid", "==", user.uid))
    );
    if (!agentSnapshot.empty) {
      const agent = agentSnapshot.docs[0];
      link.agent = { id: agent.id, ...agent.data() };
    }
    link.uid = user.uid;
    link.equipment = Object.keys(link.chosenEquipment).map(e => ({
      ...link.chosenEquipment[e].equipment,
      price: parseFloat(link.chosenEquipment[e].price),
      prices: link.chosenEquipment[e].prices.map(price => parseFloat(price)),
      quantity: link.chosenEquipment[e].quantity,
      softwareFee: parseFloat(link.chosenEquipment[e].softwareFee || 0),
      hardwareDescription: link.chosenEquipment[e].hardwareDescription,
      feeDescription: link.chosenEquipment[e].feeDescription,
      processor: link.chosenEquipment[e].processor
    }));
    link.createdAt = new Date();
    link.pricing = {
      subtotal: this.getSubtotalWithDiscount(),
      discount: this.getSubtotal() - this.getSubtotalWithDiscount(),
      taxes: this.getTaxes(),
      total: this.getOrderTotal()
    };
    delete link.inventory;
    delete link.loading;
    delete link.errors;
    delete link.chosenEquipment;
    delete link.inventoryProcessors;
    delete link.processors;
    delete link.processor;

    const result = await addDoc(collection(firestore, "links"), link);
    if (this.state.lead) {
      const applicationUrl = `https://app.posbrokers.com/?l=${result.id}`;
      await setDoc(
        doc(firestore, "leads", this.state.lead.id),
        { applicationUrl, linkId: result.id },
        { merge: true }
      );
    }
    return result.id;
  };

  renderProcessorsOptions = () => {
    return this.state.inventoryProcessors?.map((processor) => (
      <MenuItem key={processor.Name} value={processor.ProcessorID}>
        {processor.Name}
      </MenuItem>
    ));
  };

  render() {
    const { classes, user } = this.props;
    const equipmentTypes = {
      'TM': 'Terminal',
      'G': 'Gateway'
    };
    const {
      lead,
      inventory,
      chosenEquipment,
      linkId,
      loading,
      startApplication,
      startQuote,
      repAppId,
      showPricelessLink,
      quoteId,
      errors,
      equipment,
      quantity
    } = this.state;
    return (
      <div className={classes.root}>
        {lead && (
          <Alert
            severity="info"
            className={classes.alert}
            action={
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                className={classes.removeLink}
                onClick={this.handleRemoveLeadLink}
              >
                Remove Link
              </Button>
            }
          >
            <AlertTitle>
              {lead.name} - {lead.dba}
            </AlertTitle>
            Pricing will automatically be associated to this lead.
          </Alert>
        )}
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="equipment"
              label="Equipment"
              variant="outlined"
              className={classes.sideMargins}
              value={this.state.equipment || ""}
              onChange={this.handleHeaderEquipmentTextChange()}
              error={!!errors.equipment}
              helperText={errors.equipment}
              margin="normal"
              select
              fullWidth
            >
              {inventory.map(e => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name} - {equipmentTypes[e.type]}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="processor"
              label="Processor"
              variant="outlined"
              className={classes.sideMargins}
              value={this.state.processor || ""}
              onChange={this.handleTextChange("processor")}
              margin="normal"
              select
              fullWidth
            >
              {this.renderProcessorsOptions()}
            </TextField>

            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              className={classes.sideMargins}
              value={quantity || ""}
              onChange={this.handleTextChange("quantity")}
              error={!!errors.quantity}
              helperText={errors.quantity}
              margin="normal"
              select
              fullWidth
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => {
                return (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="primary"
              startIcon={<AddIcon />}
              onClick={this.handleAddEquipmentClick}
              disabled={equipment === undefined || quantity === undefined}
            >
              Add New Equipment
            </Button>
          </div>

          <Paper className={classes.paymentOptionContainer} elevation={2}>
            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Payment Options
            </Typography>

            <div className={classes.inputContainer}>
              <TextField
                id="paymentType"
                label="Payment Type"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.paymentType || ""}
                onChange={this.handleTextChange("paymentType")}
                error={!!errors.paymentType}
                helperText={errors.paymentType}
                margin="normal"
                select
                fullWidth
              >
                <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                <MenuItem value={"ACH"}>ACH</MenuItem>
              </TextField>

              <TextField
                id="paymentPlan"
                label="Payment Plan"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.paymentPlan || ""}
                onChange={this.handleTextChange("paymentPlan")}
                error={!!errors.paymentPlan}
                helperText={errors.paymentPlan}
                margin="normal"
                select
                fullWidth
              >
                {[1, 3, 6].map(e => {
                  return (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <Divider className={classes.vertMargin} />
            {Object.keys(chosenEquipment).map((e, i) => {
              const current = chosenEquipment[e];
              const quantity = parseInt(current.quantity);
              const id = current.equipment.id;
              return (
                <Fragment key={`chosenEquipment-${id}`}>
                  {_.range(0, quantity).map(index => (
                    <div
                      className={classes.inputContainer}
                      key={`chosenEquipment-${id}-${index}`}
                    >
                      <TextField
                        key={`price-${id}-${i}`}
                        id={`price-${e}`}
                        type="number"
                        label={`${current.equipment.name} #${index + 1} (${current.processor.Name})`}
                        variant="outlined"
                        className={classes.sideMargins}
                        value={current.prices[index]}
                        placeholder={"0.00"}
                        onChange={this.handleEquipmentTextChange(index, e)}
                        error={!!errors[`price-${e}`]}
                        helperText={errors[`price-${e}`]}
                        margin="normal"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: index === 0 && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="remove equipment"
                                onClick={this.handleDeleteEquipmentClick(e)}
                                edge="end"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  ))}
                  {/*<div className={classes.inputContainer}>
                    <TextField
                      key={i}
                      id={`hardwareDescription-${e}`}
                      label={`${current.equipment.name} Description`}
                      name="hardwareDescription"
                      variant="outlined"
                      className={classes.sideMargins}
                      value={current.hardwareDescriptionn}
                      onChange={this.handleDescriptionTextChange(e)}
                      margin="normal"
                      fullWidth
                    />
                      </div>*/}
                  <div className={classes.inputContainer}>
                    <TextField
                      key={`softwareFee-${id}-${i}`}
                      id={`softwareFee-${e}`}
                      type="number"
                      label={`${current.equipment.name} Software Fee`}
                      variant="outlined"
                      className={classes.sideMargins}
                      value={current.softwareFee}
                      placeholder={"0.00"}
                      onChange={this.handleEquipmentFeeTextChange(e)}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      key={`softwareFeeDescription-${id}-${i}`}
                      id={`softwareFeeDescription-${e}`}
                      label={`${current.equipment.name} Fee Description`}
                      name="feeDescription"
                      variant="outlined"
                      className={classes.sideMargins}
                      value={current.feeDescription}
                      onChange={this.handleDescriptionTextChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <Divider className={classes.vertMargin} />
                </Fragment>
              );
            })}
            <div className={classes.inputContainer}>
              <TextField
                id="discountAmount"
                label="Discount Amount"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.discountAmount || ""}
                placeholder={"0.00"}
                onChange={this.handleTextChange("discountAmount")}
                error={!!errors.discountAmount}
                helperText={errors.discountAmount}
                margin="normal"
                fullWidth
                disabled={this.state.discountPercent}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />

              <TextField
                id="discountPercent"
                label="Discount Percent"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.discountPercent || ""}
                placeholder={"0"}
                onChange={this.handleTextChange("discountPercent")}
                error={!!errors.discountPercent}
                helperText={errors.discountPercent}
                margin="normal"
                fullWidth
                disabled={this.state.discountAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.switchContainer}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={this.state.includeTaxes}
                      onChange={this.handleCheckChange("includeTaxes")}
                      value="checkedA"
                    />
                  }
                  label="Taxes will be included."
                />
              </FormGroup>
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="zipCode"
                label="Zip Code"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.zipCode || ""}
                onChange={this.handleZipCodeSearch}
                error={!!errors.zipCode}
                helperText={
                  errors.zipCode ||
                  "Taxes can be calculated automatically based on Zip Code."
                }
                margin="normal"
                fullWidth
                disabled={!this.state.includeTaxes}
              />
              <TextField
                id="taxes"
                label="Taxes"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={!this.state.includeTaxes ? "0.00" : this.state.taxes}
                placeholder={"0.00"}
                onChange={this.handleTextChange("taxes")}
                error={!!errors.taxes}
                helperText={errors.taxes}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
                disabled={!this.state.includeTaxes}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="shipping"
                label="Shipping"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.shipping || ""}
                placeholder={"0.00"}
                onChange={this.handleTextChange("shipping")}
                error={!!errors.shipping}
                helperText={errors.shipping}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="additionalFeeLabel"
                label="Additional Fee Name"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.additionalFeeLabel || ""}
                onChange={this.handleTextChange("additionalFeeLabel")}
                error={!!errors.additionalFeeLabel}
                helperText={errors.additionalFeeLabel}
                margin="normal"
                fullWidth
              />
              <TextField
                id="additionalFee"
                label={this.state.additionalFeeLabel}
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.additionalFee || ""}
                placeholder={"0.00"}
                onChange={this.handleTextChange("additionalFee")}
                error={!!errors.additionalFee}
                helperText={errors.additionalFee}
                margin="normal"
                fullWidth
                disabled={!this.state.additionalFeeLabel}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <TextField
                id="downPayment"
                label="Down Payment"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.downPayment || ""}
                placeholder={this.getDownPayment()}
                onChange={this.handleTextChange("downPayment")}
                error={!!errors.downPayment}
                helperText={errors.downPayment}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="terminationFee"
                label="Termination Fee"
                type="number"
                variant="outlined"
                className={classes.sideMargins}
                value={this.state.terminationFee || ""}
                onChange={this.handleTextChange("terminationFee")}
                error={!!errors.terminationFee}
                helperText={errors.terminationFee}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>

            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Order Summary
            </Typography>

            <List>
              <ListItem>
                <ListItemText primary="Products" />
              </ListItem>
              <List component="div" disablePadding>
                {Object.keys(chosenEquipment).map((e, i) => {
                  const current = chosenEquipment[e];
                  return (
                    <ListItem
                      className={classes.nested}
                      key={`equipment-qty-${i}`}
                    >
                      <ListItemText
                        primary={current.equipment.name}
                        secondary={`Quantity: ${current.quantity}`}
                      />
                      <ListItemText
                        className={classes.alignRight}
                        primary={`$${(current.price * current.quantity).toFixed(
                          2
                        )}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Discount" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`($${(
                    this.getSubtotal() - this.getSubtotalWithDiscount()
                  ).toFixed(2)})`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Subtotal" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getSubtotalWithDiscount().toFixed(2)}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Taxes" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getTaxes().toFixed(2)}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Shipping" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${parseFloat(this.state.shipping || 0).toFixed(
                    2
                  )}`}
                />
              </ListItem>
              {this.state.additionalFee && (
                <Fragment>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary={this.state.additionalFeeLabel} />
                    <ListItemText
                      className={classes.alignRight}
                      primary={`$${parseFloat(
                        this.state.additionalFee || 0
                      ).toFixed(2)}`}
                    />
                  </ListItem>
                </Fragment>
              )}
              <Divider component="li" />
              <ListItem>
                <ListItemText
                  primary="Order Total"
                  secondary={
                    this.state.paymentType && this.state.paymentPlan
                      ? `${this.state.paymentType} - ${this.state.paymentPlan} Month Term`
                      : ""
                  }
                />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getOrderTotal()}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Down Payment" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`($${this.getDownPayment()})`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Balance Due" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${this.getBalance()}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Termination Fee" />
                <ListItemText
                  className={classes.alignRight}
                  primary={`$${parseFloat(
                    this.state.terminationFee || 0
                  ).toFixed(2)}`}
                />
              </ListItem>
            </List>
          </Paper>
          <div className={classes.buttonContainer}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={this.startApplication}
                  disabled={Boolean(Object.keys(chosenEquipment).length === 0)}
                >
                  Start Application
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={this.handleShowPricelessLink}
                >
                  Show Link without Pricing
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={this.generateLink}
                  disabled={Boolean(Object.keys(chosenEquipment).length === 0)}
                >
                  Generate Link w/ Pricing
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={this.generateQuote}
                  disabled={
                    !lead || Boolean(Object.keys(chosenEquipment).length === 0)
                  }
                >
                  Create Quote Page
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={loading || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={startApplication} fullScreen>
          <DialogContent>
            <RepApp linkId={linkId} onSave={this.handleRepAppSave} />
          </DialogContent>
          <DialogActions>
            <div className={classes.dialogActions}>
              <Button
                onClick={this.handleCloseApplication}
                color="primary"
                variant="contained"
                size="small"
                autoFocus
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog open={startQuote} fullScreen>
          <DialogContent>
            <Quote
              lead={lead}
              quoteId={quoteId}
              onQuoteSave={this.handleSaveQuote}
              linkId={linkId}
              user={user}
            />
          </DialogContent>
          <DialogActions>
            <div className={classes.dialogActions}>
              <Button
                onClick={this.handleCloseQuote}
                color="primary"
                variant="contained"
                size="small"
                autoFocus
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={Boolean(linkId) && !(startApplication || startQuote)}
          aria-labelledby="link-dialog-title"
          aria-describedby="link-dialog-description"
        >
          <DialogContent>
            <TextField
              label="Generated Link"
              id="link"
              readOnly
              className={classes.linkField}
              value={`https://app.posbrokers.com/?l=${linkId}`}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
              size="small"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showPricelessLink}
          aria-labelledby="link-dialog-title"
          aria-describedby="link-dialog-description"
        >
          <DialogContent>
            <TextField
              label="Generated Link"
              id="link"
              readOnly
              className={classes.linkField}
              value={`https://app.posbrokers.com/?agent=${this.props.user.irisUserId}`}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
              size="small"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(repAppId)}
          aria-labelledby="link-dialog-title"
          aria-describedby="link-dialog-description"
        >
          <DialogContent>
            <TextField
              label="Generated Application Link"
              id="link"
              readOnly
              className={classes.linkField}
              helperText="Save this link in case the merchant does not receive an automated email."
              value={`https://pos-brokers-production.web.app/?r=${repAppId}`}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
              size="small"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(
  withLayout,
  withStyles(styles),
  connect(state => ({
    user: state.user
  })),
  withFirebase
)(Links);
