import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import { Typography, Divider } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Chart } from "react-google-charts";
import _ from "lodash";

class LeadsByGroup extends Component {
  state = {
    chart: [],
    expanded: false
  };
  componentDidMount = async () => {
    await this.getChart();
  };
  getChart = async () => {
    const data = await this.props.httpClient.reports.leadsCurrentYear();
    const grouped = _.groupBy(data, e => e.uid);
    const chart = Object.keys(grouped).map(uid => {
      const user = this.props.metadata.users[uid];
      const numbers = this.props.metadata.groups.reduce((numbers, group) => {
        const uidGroup = grouped[uid].filter(e => e.group === group)[0];
        numbers[group] = uidGroup ? uidGroup.count : 0;
        return numbers;
      }, {});
      return [
        user ? `${user.firstName} ${user.lastName}` : "Unassigned",
        ..._.values(numbers)
      ];
    });
    const columns = [
      {
        type: "string",
        label: "Rep Name"
      },
      ...this.props.metadata.groups.map(e => ({ type: "number", label: e }))
    ];
    this.setState({ chart, columns, expanded: true });
  };
  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { chart, columns, expanded } = this.state;
    return (
      <Accordion expanded={expanded} onChange={this.handleExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="overline">Annual Lead Info</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Chart
            width={"100%"}
            height={"250px"}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            columns={columns}
            rows={chart}
            rootProps={{ "data-testid": "1" }}
            options={{
              isStacked: true
            }}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(
  withHttpClient,
  connect(
    state => ({
      metadata: state.metadata
    }),
    {}
  )
)(LeadsByGroup);
