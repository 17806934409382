import * as ActionTypes from "./actionTypes";

export const signInSuccess = user => {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    user
  };
};

export const signInError = error => {
  return {
    type: ActionTypes.SIGN_IN_ERROR,
    error
  };
};

export const signOutSuccess = () => {
  return {
    type: ActionTypes.SIGN_OUT_SUCCESS,
    user: null
  };
};

export const receiveUserAuthStateChange = user => {
  return {
    type: ActionTypes.RECEIVE_USER_STATE_CHANGE,
    user
  };
};

export const setFirestoreUser = user => {
  return {
    type: ActionTypes.SET_FIRESTORE_USER,
    user
  };
};
