const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1)
    }
  },
  h1: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em"
    }
  },
  h2: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      textAlign: "justify"
    }
  }
});

export default styles;
