import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_APPLICATIONS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_APPLICATIONS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveApplicationsSnapshot = (
  applications,
  endBefore,
  startAfter
) => {
  return {
    type: ActionTypes.RECEIVE_APPLICATIONS_SNAPSHOT,
    applications,
    endBefore,
    startAfter
  };
};

export const receiveApplicationsTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_APPLICATIONS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
