import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Collapse
} from "@material-ui/core";

import Title from "../../Title";

import styles from "../styles";

const getYears = () => {
  const years = [];
  const thisYear = new Date().getFullYear();
  for (var index = thisYear; index > thisYear - 100; index--) {
    years.push(index);
  }
  return years;
};

class Business extends PureComponent {
  render() {
    const {
      application,
      onApplicationTextChange,
      onCheckChange,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        {application.isCheckout ? (
          <Title
            h1={"Checkout"}
            h2={"Let's start with some business and shipping information."}
          />
        ) : (
          <Title
            h1={"Business Info"}
            h2={"Let's start with some business and ownership information."}
          />
        )}
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>
            <TextField
              id="legalBusinessName"
              label="Legal Business Name"
              variant="outlined"
              className={classes.sideMargins}
              value={application.legalBusinessName || ""}
              onChange={onApplicationTextChange("legalBusinessName")}
              error={!!errors.legalBusinessName}
              helperText={errors.legalBusinessName}
              margin="normal"
              fullWidth
            />
            <TextField
              id="doingBusinessAs"
              label="Doing Business As (DBA)"
              variant="outlined"
              className={classes.sideMargins}
              value={application.doingBusinessAs || ""}
              onChange={onApplicationTextChange("doingBusinessAs")}
              error={!!errors.doingBusinessAs}
              helperText={errors.doingBusinessAs}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="businessDescription"
              label="Business Description"
              placeholder="What type of business do you run?"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessDescription || ""}
              onChange={onApplicationTextChange("businessDescription")}
              error={!!errors.businessDescription}
              helperText={errors.businessDescription}
              margin="normal"
              maxLength="199"
              multiline
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="ownershipType"
              label="Ownership Type"
              variant="outlined"
              className={classes.sideMargins}
              value={application.ownershipType || ""}
              onChange={onApplicationTextChange("ownershipType")}
              error={!!errors.ownershipType}
              helperText={errors.ownershipType}
              margin="normal"
              select
              fullWidth
            >
              <MenuItem value={"Sole Proprietorship"}>
                Sole Proprietorship
              </MenuItem>
              <MenuItem value={"Corporation"}>Corporation</MenuItem>
              <MenuItem value={"LLC"}>LLC</MenuItem>
              <MenuItem value={"Non Profit"}>Non Profit</MenuItem>
            </TextField>
            {application.ownershipType !== "Sole Proprietorship" && (
              <TextField
                id="federalTaxId"
                label="Federal Tax ID"
                placeholder="00-0000000"
                variant="outlined"
                className={classes.sideMargins}
                value={application.federalTaxId || ""}
                onChange={onApplicationTextChange("federalTaxId")}
                error={!!errors.federalTaxId}
                helperText={errors.federalTaxId}
                margin="normal"
                fullWidth
                disabled={application.ownershipType === "Sole Proprietorship"}
              />
            )}
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="businessYear"
              label="What year did you open your business?"
              placeholder="2019"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessYear || ""}
              onChange={onApplicationTextChange("businessYear")}
              error={!!errors.businessYear}
              helperText={errors.businessYear}
              margin="normal"
              select
              fullWidth
            >
              {getYears().map(year => {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              id="website"
              label="Website URL (optional)"
              placeholder="http://www.website.com"
              variant="outlined"
              className={classes.sideMargins}
              value={application.website || ""}
              onChange={onApplicationTextChange("website")}
              error={!!errors.website}
              helperText={errors.website}
              margin="normal"
              fullWidth
            />
          </div>
          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
            Business Address
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.inputContainer}>
            <TextField
              id="businessAddress1"
              label="Business Address 1"
              placeholder="Street Address"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessAddress1 || ""}
              onChange={onApplicationTextChange("businessAddress1")}
              error={!!errors.businessAddress1}
              helperText={errors.businessAddress1}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="businessAddress2"
              label="Business Address 2 (optional)"
              placeholder="Apt/Unit"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessAddress2 || ""}
              onChange={onApplicationTextChange("businessAddress2")}
              error={!!errors.businessAddress2}
              helperText={errors.businessAddress2}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="businessCity"
              label="City"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessCity || ""}
              onChange={onApplicationTextChange("businessCity")}
              error={!!errors.businessCity}
              helperText={errors.businessCity}
              margin="normal"
              fullWidth
            />
            <TextField
              id="businessState"
              label="State"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessState || ""}
              onChange={onApplicationTextChange("businessState")}
              error={!!errors.businessState}
              helperText={errors.businessState}
              margin="normal"
              fullWidth
              inputProps={{
                maxLength: 2
              }}
            />
            <TextField
              id="businessZip"
              label="Zip Code"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessZip || ""}
              onChange={onApplicationTextChange("businessZip")}
              error={!!errors.businessZip}
              helperText={errors.businessZip}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="businessPhone"
              label="Phone Number"
              placeholder="999-999-9999"
              variant="outlined"
              className={classes.sideMargins}
              value={application.businessPhone || ""}
              onChange={onApplicationTextChange("businessPhone")}
              error={!!errors.businessPhone}
              helperText={errors.businessPhone}
              margin="normal"
              fullWidth
            />
          </div>
          <div className={classes.inputContainer}>
            <FormGroup className={classes.differentCheckboxContainer} row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={application.mailingDifferent}
                    onChange={onCheckChange("mailingDifferent")}
                    value="mailingDifferent"
                  />
                }
                label="Mailing Address different than Business Address?"
              />
            </FormGroup>
          </div>
          <Collapse in={application.mailingDifferent}>
            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Mailing Address
            </Typography>
            <Divider className={classes.divider} />
            <div className={classes.inputContainer}>
              <TextField
                id="mailingAddress1"
                label="Mailing Address 1"
                placeholder="Street Address"
                variant="outlined"
                className={classes.sideMargins}
                value={application.mailingAddress1 || ""}
                onChange={onApplicationTextChange("mailingAddress1")}
                error={!!errors.mailingAddress1}
                helperText={errors.mailingAddress1}
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="mailingAddress2"
                label="Mailing Address 2 (optional)"
                placeholder="Apt/Unit"
                variant="outlined"
                className={classes.sideMargins}
                value={application.mailingAddress2 || ""}
                onChange={onApplicationTextChange("mailingAddress2")}
                error={!!errors.mailingAddress2}
                helperText={errors.mailingAddress2}
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="mailingCity"
                label="City"
                variant="outlined"
                className={classes.sideMargins}
                value={application.mailingCity || ""}
                onChange={onApplicationTextChange("mailingCity")}
                error={!!errors.mailingCity}
                helperText={errors.mailingCity}
                margin="normal"
                fullWidth
              />
              <TextField
                id="mailingState"
                label="State"
                variant="outlined"
                className={classes.sideMargins}
                value={application.mailingState || ""}
                onChange={onApplicationTextChange("mailingState")}
                error={!!errors.mailingState}
                helperText={errors.mailingState}
                margin="normal"
                fullWidth
                inputProps={{
                  maxLength: 2
                }}
              />
              <TextField
                id="mailingZip"
                label="Zip Code"
                variant="outlined"
                className={classes.sideMargins}
                value={application.mailingZip || ""}
                onChange={onApplicationTextChange("mailingZip")}
                error={!!errors.mailingZip}
                helperText={errors.mailingZip}
                margin="normal"
                fullWidth
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Business);
