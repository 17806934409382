import * as ActionTypes from "./actionTypes";

function push(text) {
  return { type: ActionTypes.PUSH_MESSAGE, message: { text } };
}

export function info(text) {
  return push(text);
}

export function pop() {
  return { type: ActionTypes.POP_MESSAGE };
}

export const receiveMessagesSnapshot = messages => {
  return {
    type: ActionTypes.RECEIVE_MESSAGES_SNAPSHOT,
    messages
  };
};
