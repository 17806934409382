import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import { withFirebase } from "../Firebase";
import { ExportToCsv } from "export-to-csv";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { collection, getDocs, query, where } from "@firebase/firestore";

const COLLECTION = "leads";

const LeadExportDialog = ({ open, handleClose, metadata, firebase, leads }) => {
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const handleFromDateChange = date => {
    setFromDate(date);
  };

  const handleToDateChange = date => {
    setToDate(date);
  };

  const handleExport = async () => {
    const start = moment(fromDate).startOf("day");
    const end = moment(toDate).endOf("day");

    const snapshot = await getDocs(
      query(
        collection(firebase.firestore, COLLECTION),
        where("createdAt", ">=", start.toDate()),
        where("createdAt", "<=", end.toDate())
      )
    );

    exportCSV(snapshot);
    handleClose();
  };

  const exportCSV = snapshot => {
    const rows = [];
    const columns = leads.columns;
    const options = {
      fieldSeparator: "\t",
      quoteStrings: "",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    snapshot.forEach(doc => {
      const row = { ...doc.data(), id: doc.id };
      const newRow = {};
      columns.forEach(column => {
        if (!column.export) {
          return;
        }
        let value = row[column.id] || "";
        if (
          typeof column.selector === "function" &&
          column.id !== "disposition"
        ) {
          value = column.selector(row, metadata);
        }
        newRow[column.label] = value;
      });
      rows.push(newRow);
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(rows);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
        <DialogTitle id="form-dialog">Leads CSV Export</DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            inputVariant="outlined"
            required
            margin="normal"
            id="date"
            label="From Date"
            value={fromDate}
            onChange={handleFromDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            fullWidth
            disableFuture
          />
          <KeyboardDatePicker
            inputVariant="outlined"
            required
            margin="normal"
            id="date"
            label="To Date"
            value={toDate}
            onChange={handleToDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button
            onClick={handleExport}
            size="small"
            color="primary"
            disabled={!fromDate || !toDate}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default compose(
  withFirebase,
  connect(state => ({
    user: state.user,
    metadata: state.metadata,
    leads: state.leads
  }))
)(LeadExportDialog);
