const styles = theme => ({
  root: {},
  titleBar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    borderTop: "1px solid rgba(0,0,0,0.13)",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    padding: theme.spacing(1)
  },
  dialogActions: {
    padding: theme.spacing(1)
  },
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 300px) !important"
  },
  paper: {
    marginLeft: theme.spacing(1),
    width: 300 - theme.spacing(1)
  },
  sticky: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: 70
  },
  toolbar: {
    paddingBottom: theme.spacing(1)
  },
  search: {
    display: "flex",
    flexDirection: "row"
  },
  list: {
    padding: 0
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[50],
    paddingRight: 4,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    borderTop: "1px solid rgba(0,0,0,0.13)"
  },
  field: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  reverseListItemText: {
    display: "flex",
    flexDirection: "column-reverse",
    overflow: "hidden"
  },
  documentLink: {
    cursor: "pointer"
  }
});

export default styles;
