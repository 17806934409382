import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CommentIcon from "@material-ui/icons/Comment";
import { validateLead } from "../../util/ValidationUtil";

const LeadDialog = ({ open, onSubmit, handleClose, metadata }) => {
  const [name, setName] = React.useState("");
  const [dba, setDba] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailAlt, setEmailAlt] = React.useState("");
  const [source, setSource] = React.useState("");
  const [currentlyProcessing, setCurrentlyProcessing] = React.useState("");
  const [volumeProcessing, setVolumeProcessing] = React.useState("");
  const [posInterest, setPosInterest] = React.useState("");
  const [howSoon, setHowSoon] = React.useState("");
  const [note, setNote] = React.useState("");
  const [errors, setErrors] = React.useState({});

  const handleTextChange = set => event => {
    set(event.target.value);
  };

  const handleAutoCompleteChange = (event, value) => {
    setSource(value ? value.name : "");
  };

  const handleSubmit = () => {
    const lead = {
      name,
      dba,
      phone,
      email,
      source,
      note,
      currentlyProcessing,
      volumeProcessing,
      howSoon,
      posInterest,
      emailAlt
    };
    const result = validateLead(lead);
    if (!result.isValid) {
      setErrors(result.errors);
    } else {
      onSubmit(result.lead);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
      <DialogTitle id="form-dialog">Create Lead</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          id="name"
          name="name"
          label="Name"
          value={name}
          onChange={handleTextChange(setName)}
          helperText={errors.name}
          error={Boolean(errors.name)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          id="dba"
          name="dba"
          label="DBA"
          value={dba}
          onChange={handleTextChange(setDba)}
          helperText={errors.dba}
          error={Boolean(errors.dba)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          id="phone"
          name="phone"
          label="Phone"
          value={phone}
          onChange={handleTextChange(setPhone)}
          helperText={errors.phone}
          error={Boolean(errors.phone)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          id="email"
          name="email"
          label="Email"
          value={email}
          onChange={handleTextChange(setEmail)}
          helperText={errors.email}
          error={Boolean(errors.email)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          id="emailAlt"
          name="emailAlt"
          label="Alternate Email"
          value={emailAlt}
          onChange={handleTextChange(setEmailAlt)}
          helperText={errors.emailAlt}
          error={Boolean(errors.emailAlt)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
        />

        <Autocomplete
          id="source-autocomplete"
          name="source"
          options={[...new Set(metadata.sources)]}
          autoHighlight
          value={metadata.sources.find(e => e.name === source) ?? null}
          onChange={handleAutoCompleteChange}
          getOptionSelected={option => option.name}
          getOptionLabel={option => option.name}
          renderOption={option => {
            return <span>{option.name}</span>;
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Source"
                margin="normal"
                value={source}
                variant="outlined"
                helperText={errors.source}
                error={Boolean(errors.source)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
                fullWidth
              />
            );
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          id="note"
          name="Note"
          label="Note"
          value={note}
          onChange={handleTextChange(setNote)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CommentIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField
          id="currentlyProcessing"
          name="currentlyProcessing"
          label="Currently Processing"
          margin="normal"
          variant="outlined"
          value={currentlyProcessing}
          onChange={handleTextChange(setCurrentlyProcessing)}
          fullWidth
        />

        <TextField
          id="volumeProcessing"
          name="volumeProcessing"
          label="Volume Processing"
          margin="normal"
          variant="outlined"
          value={volumeProcessing}
          onChange={handleTextChange(setVolumeProcessing)}
          fullWidth
        />

        <TextField
          id="howSoon"
          name="howSoon"
          label="How Soon"
          margin="normal"
          variant="outlined"
          value={howSoon}
          onChange={handleTextChange(setHowSoon)}
          fullWidth
        />

        <TextField
          id="posInterest"
          name="posInterest"
          label="POS of Interest"
          margin="normal"
          variant="outlined"
          value={posInterest}
          onChange={handleTextChange(setPosInterest)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button onClick={handleSubmit} size="small" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadDialog;
