import React, { FC, ReactElement } from 'react';
import { List, ListItem, ListSubheader, Link } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from '../styles';
import { withHttpClient } from "../../HttpClient";

interface IDocumentsList {
    application: any;
    classes: any;
    httpClient: any;
}

const DocumentsList: FC<IDocumentsList> = ({
    application, classes, httpClient
}): ReactElement => {
    
    const handleDocumentClick = (applicationId: string, documentId: string, fileName: string) => {
      httpClient.pdfService.downloadPDF(applicationId, documentId, fileName);
  }

    return (
      <div>
        <List
            component="div"
            className={classes.list}
            subheader={
                <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    className={classes.listSubheader}
                >
                    Documents
                </ListSubheader>
            }
        >
            {
            application.documents && Object.keys(application.documents).map((docKey) => {
                const document = application.documents[docKey];
                return (
                    <ListItem key={document.documentId}>
                        <Link 
                            onClick={() => handleDocumentClick(application.id, docKey, document.fileName)}
                            className={classes.documentLink}
                        >
                           {document.fileName}
                        </Link>
                    </ListItem>
                )
            })
          }
        </List>
      </div>
    );
}

export default compose<IDocumentsList, Element>(
    withHttpClient,
    withStyles(styles as any),
    connect((state: any) => ({
      metadata: state.metadata,
      user: state.user
    }))
)(DocumentsList);