import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  limit,
  onSnapshot,
  orderBy as orderByFunc,
  query,
  setDoc,
  startAfter as startAfterFunc,
  where as whereFunc
} from "@firebase/firestore";
import { 
  Button, 
  Paper, 
  Typography, 
  Dialog,
  DialogActions,
  DialogContent, 
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  receiveApplicationsSnapshot,
  setColumnVisibility,
  setSearchCriteria
} from "../../actions/applicationActions";
import * as ROUTES from "../../constants/routes";
import { createLog } from "../../util/ActivityUtil";
import AutoComplete from "../AutoComplete";
import DataTable from "../DataTableInfiniteScroll";
import { withLayout } from "../Layout";
import { withAuthorization } from "../Session";
import LinkLead from "./LinkLead";
import Documents from "./Documents";
import styles from "./styles";
import { withHttpClient } from "../HttpClient";
import SubmitApp from "../Application/submit";

const COLLECTION = "applications";

class ApplicationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: null,
      linking: false,
      loading: false,
      hasMore: true,
      packages: [],
      selectedPackage: "",
      submitApplication: false
    };
    this.log = createLog(props.firebase, props.user);
  }

  async getPackages() {
    return await this.props.httpClient.pdfService.getPackages();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user &&
      !prevProps.user &&
      this.props[COLLECTION].data.length < 1
    ) {
      this.registerListeners();
    }
  }

  async componentDidMount() {
    if (this.props.user) {
      this.registerListeners();

      const packages = await this.getPackages();
      this.setState({ packages });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeListener) {
      this.unsubscribeListener();
    }
  }

  registerListeners = () => {
    const collection = this.props[COLLECTION];
    this.unsubscribeListener = this.registerSnapshotListener(collection);
    this.unsubscribeSyncDiagnosticsListener = this.registerSyncDiagnosticsListener();
  };

  registerSyncDiagnosticsListener = () => {
    const firestore = this.props.firebase.firestore;
    const syncDiagnosticsRef = doc(firestore, "syncDiagnostics", "klaviyo_applications");
  
    return onSnapshot(syncDiagnosticsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const syncData = docSnapshot.data();
        console.log(syncData);
        this.setState({ klaviyoStatus: syncData.status });
      } else {
        console.log("No data found for sync diagnostics.");
      }
    }, (error) => {
      console.error("Error listening to sync diagnostics changes: ", error);
    });
  };

  getQuery = () => {
    const userIsRole = ["support"].includes(this.props.user.role);
    const firestore = this.props.firebase.firestore;
    return this.props.user.isAdmin || userIsRole
      ? query(collection(firestore, COLLECTION))
      : query(
          collection(firestore, COLLECTION),
          whereFunc("uid", "==", this.props.user.uid)
        );
  };

  getQueryWithSearchCriteria = (
    order,
    orderBy,
    rowsPerPage,
    page,
    where,
    reset
  ) => {
    const {
      [COLLECTION]: dataSet,
      [COLLECTION]: { endBefore, startAfter }
    } = this.props;

    const firestore = this.props.firebase.firestore;
    let q = this.getQuery();

    if (where && where.id) {
      return doc(firestore, COLLECTION, where.id);
    }

    if (!reset) {
      if (page > dataSet.page) {
        q = query(q, orderByFunc(orderBy, order), startAfterFunc(startAfter));
      } else if (page < dataSet.page) {
        q = query(
          q,
          orderByFunc(orderBy, order === "asc" ? "desc" : "asc"),
          startAfterFunc(endBefore)
        );
      } else {
        q = query(q, orderByFunc(orderBy, order));
      }
    } else {
      q = query(q, orderByFunc(orderBy, order));
    }

    q = query(q, limit(rowsPerPage));

    return q;
  };

  registerSnapshotListener = (
    { order, orderBy, rowsPerPage, page, where },
    reset
  ) => {
    const { receiveSnapshot } = this.props;
    const {
      [COLLECTION]: { page: currentPage }
    } = this.props;

    let q = this.getQueryWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      where,
      reset
    );

    return onSnapshot(q, snapshot => {
      if (snapshot.id) {
        const row = { ...snapshot.data(), id: snapshot.id };
        receiveSnapshot([row], snapshot, snapshot);
      } else {
        const snapshots = this.maybeReverseSnapshots(
          snapshot,
          page,
          currentPage,
          reset
        );
        const results = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
        const endBefore = snapshots[0];
        const startAfter = snapshots[results.length - 1];
        receiveSnapshot(results, endBefore, startAfter);
      }
    });
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    if (this.unsubscribeListener) {
      this.unsubscribeListener();
    }
    this.unsubscribeListener = this.registerSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleLeadIdChange = leadId => {
    this.setState({
      row: { ...this.state.row, leadId: leadId }
    });
  };

  resetWhere = () => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage },
      setSearchCriteria
    } = this.props;
    const searchCriteria = { order, orderBy, page, rowsPerPage, where: null };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null });
  };

  handleAutoCompleteChange = (event, value) => {
    if (!value) {
      return;
    }
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage },
      setSearchCriteria
    } = this.props;
    const where = value ? { id: value.id } : null;
    const searchCriteria = { order, orderBy, page, rowsPerPage, where };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null });
  };

  handleLinkClick = () => {
    this.setState({ linking: !this.state.linking });
  };

  handleSaveLinkClick = () => {
    this.handleLinkApplication(this.state.row);
    this.setState({ linking: false });
  };

  handleCancelLinking = () => {
    this.setState({ lead: this.props.lead, linking: false });
  };

  handleLinkApplication = async row => {
    this.setState({
      row: { ...row, leadId: row.leadId, mid: row.mid }
    });

    const firestore = this.props.firebase.firestore;
    await setDoc(
      doc(firestore, "applications", row.id),
      { leadId: row.leadId || null, mid: row.mid || null },
      { merge: true }
    );

    this.log("Link Application", {
      leadId: row.leadId,
      applicationId: row.id,
      mid: row.mid
    });
  };

  handleEditClick = row => () => {
    this.props.history.push(`${ROUTES.APPLICATIONS}/${row.id}`);
  };

  handleSendClick = row => async () => {
    const firestore = this.props.firebase.firestore;
    await setDoc(
      doc(firestore, "applications", row.id),
      { status: 'Sending To Api' },
      { merge: true }
    );
  }

  handleShowSubmitDialog = row => async () => {
    this.setState({ submitApplicationId: row.Id, submitApplication: true });
  }

  handleSubmitAppSave = row => async () => {
    const firestore = this.props.firebase.firestore;
    await setDoc(
      doc(firestore, "applications", row.id),
      { status: 'Sending To Api' },
      { merge: true }
    );
  }

  handleCloseApplication = () => {
    this.setState({ submitApplication: false });
  };

  handleDeleteClick = row => () => {
    const firestore = this.props.firebase.firestore;
    deleteDoc(doc(firestore, "applications", row.id));
    this.log("Deleted Application", { applicationId: row.id });
  };

  handleRowClick = (row, isAlreadySelected) => {
    this.setState({ row: isAlreadySelected ? null : row });
  };

  handleNext = () => {
    this.setState({ loading: true });

    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where, startAfter },
      reset,
      receiveSnapshot
    } = this.props;

    let q = this.getQueryWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      where,
      reset
    );

    q = query(q, startAfterFunc(startAfter));
    q = query(q, limit(rowsPerPage));

    getDocs(q).then(snapshot => {
      const results = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshot.docs[0];
      const startAfter = snapshot.docs[results.length - 1];
      this.setState({ loading: false, hasMore: results.length !== 0 });
      receiveSnapshot(
        [...this.props.applications.data, ...results],
        endBefore,
        startAfter
      );
    });
  };

  sendToKlaviyo = async () => {
    const firestore = this.props.firebase.firestore;
    const syncDiagnosticsRef = doc(firestore, "syncDiagnostics", "klaviyo_applications");
  
    try {
      const docSnap = await getDoc(syncDiagnosticsRef);
  
      if (!docSnap.exists()) {
        await setDoc(syncDiagnosticsRef, {
          lastDateTime: null,
          lastStatus: null,
          lastError: null,
          lastSourcesCount: 0,
          lastLeadsCount: 0,
          history: [],
          status: 'done'
        });
        
      } 
      setTimeout( () => {
      updateDoc(syncDiagnosticsRef, {
        status: 'scheduled'
      });},1
      )
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }  

  render() {
    const { row, linking, loading, hasMore, submitApplication, klaviyoStatus } = this.state;
    const { applications, metadata, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.page}>
          <div className={classes.titleBar}>
            <Typography variant="h4">Applications</Typography>

            <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.sendToKlaviyo}
                disabled={klaviyoStatus && klaviyoStatus != "done"}
              >
                Export to Klaviyo {(klaviyoStatus && klaviyoStatus != 'done') && (`(${klaviyoStatus})`)}
              </Button>
          </div>
          <div className={classes.container}>
            <div
              className={classNames(classes.tableContainer, {
                [classes.tableContainerZoom]: Boolean(row)
              })}
            >
              <DataTable
                title="Applications"
                data={applications}
                onSearchTextChange={() => {}}
                onFilterMenuItemClick={() => {}}
                onRetrieveData={this.handleRetrieveData}
                onRowClick={this.handleRowClick}
                onNext={this.handleNext}
                hasMore={hasMore}
                loading={loading}
                metadata={metadata}
                distanceBottom={1500}
                toolbar={[
                  <div className={classes.toolbar} key="applications">
                    <div className={classes.search}>
                      <AutoComplete
                        index={process.env.REACT_APP_ALGOLIA_APPLICATIONS_INDEX}
                        publicKey={this.props.user.algoliaPublicKey}
                        onChange={this.handleAutoCompleteChange}
                      />
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={this.resetWhere}
                        disabled={!applications.where}
                      >
                        Reset Filters
                      </Button>
                    </div>
                  </div>
                ]}
              />
            </div>
            {row && (
              <Paper className={classes.paper}>
                <div className={classes.sticky}>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className={classes.listSubheader}
                      >
                        Actions
                      </ListSubheader>
                    }
                    className={classes.list}
                  >
                    <ListItem button onClick={this.handleEditClick(row)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Details" />
                    </ListItem>
                    <ListItem button onClick={this.handleDeleteClick(row)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Delete" />
                    </ListItem>
                  </List>

                  <LinkLead
                    application={row}
                    classes={classes}
                    linking={linking}
                    leadId={this.state.row.leadId}
                    cancelClick={this.handleCancelLinking}
                    editLinkClick={this.handleLinkClick}
                    saveLinkClick={this.handleSaveLinkClick}
                    textChange={this.handleLeadIdChange}
                  />

                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    className={classes.listSubheader}
                  >
                    Send to Paysafe
                  </ListSubheader>

                  {false && this.state.row && (this.state.row.status === 'Verification' || this.state.row.status === 'Api Errors' || this.state.row.status === 'Sent To Api') && (
                  <ListItem disabled={!this.state.row.paysafePackageId} button onClick={this.handleSendClick(row)}>
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Submit Application" />
                  </ListItem>
                  )}

                  {this.state.row && (this.state.row.status === 'Verification' || this.state.row.status === 'Api Errors' || this.state.row.status === 'Sent To Api') && (
                  <ListItem button onClick={this.handleShowSubmitDialog(row)}>
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Submit Application" />
                  </ListItem>
                  )}
                  
                  <Documents
                    application={this.state.row}
                    classes={classes}
                  ></Documents>
                </div>
              </Paper>
            )}
          </div>
          <Dialog open={submitApplication} fullScreen>
            <DialogContent>
              <SubmitApp applicationId={this.state.row?.id} onSave={this.handleSubmitAppSave} />
            </DialogContent>
            <DialogActions>
              <div className={classes.dialogActions}>
                <Button
                  onClick={this.handleCloseApplication}
                  color="primary"
                  variant="contained"
                  size="small"
                  autoFocus
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

const condition = user => !!user;

export default compose(
  withHttpClient,
  withLayout,
  withStyles(styles),
  withAuthorization(condition),
  connect(
    state => ({
      user: state.user,
      metadata: state.metadata,
      applications: state.applications
    }),
    {
      setSearchCriteria,
      setColumnVisibility,
      receiveSnapshot: receiveApplicationsSnapshot
    }
  )
)(ApplicationsPage);
