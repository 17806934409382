import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_USERS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_USERS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveUsersSnapshot = (users, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_USERS_SNAPSHOT,
    users,
    endBefore,
    startAfter
  };
};

export const receiveUsersTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_USERS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
