import { addDoc, collection } from "@firebase/firestore";

export const createLog = (firebase, user) => (action, data) => {
  const log = {
    uid: user.uid,
    createdAt: new Date(),
    action
  };
  if (data) {
    log.data = data;
  }
  return addDoc(collection(firebase.firestore, "activity"), log);
};
