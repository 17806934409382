const styles = theme => ({
    paper: {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        width: 500 + theme.spacing(1)
    },
    panel: {
        flexDirection: "column",
        padding: theme.spacing(1)
    },
    sticky: {
        display: "flex",
        flexDirection: "row",
        position: "sticky",
        top: 70
    },
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    titleBar: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
        borderTop: "1px solid rgba(0,0,0,0.13)",
        borderBottom: "1px solid rgba(0,0,0,0.13)",
        padding: theme.spacing(1)
    },
    search: {
        display: "flex",
        flexDirection: "row"
    },
    toolbar: {
        paddingBottom: theme.spacing(1)
    },
    button: {
        float: "right"
    },
    sourcesContainer: {
        maxHeight: 200,
        overflowY: 'auto',
        marginTop: 10,
        marginBottom: 10,
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    sectionTitle: {
        marginTop: 10,
        color: 'gray',
    }
});

export default styles;
