import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";
import AuthUserContext from "./context";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { withHttpClient } from "../HttpClient";
import {
  receiveUserAuthStateChange,
  setFirestoreUser
} from "../../actions/userActions";
import { receiveMessagesSnapshot } from "../../actions/messageActions";
import { receiveMetadataSnapshot } from "../../actions/metadataActions";
import { receiveEventsSnapshot } from "../../actions/eventsActions";
import * as ROUTES from "../../constants/routes";
import moment from "moment";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  getDoc
} from "firebase/firestore";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    state = {};
    firebase = this.props.firebase;
    auth = this.firebase.auth; // Auth
    firestore = this.firebase.firestore; // Firestore

    componentDidMount() {
      this.unsubscribeAuthStateListener = this.props.firebase.auth.onAuthStateChanged(
        async authUser => {
          let sessionTimeout = null;
          if (authUser) {
            const tokenResult = await authUser.getIdTokenResult(true);
            const user = {
              ...authUser,
              isAdmin:
                tokenResult.claims.role === "admin" ||
                tokenResult.claims.role === "super",
              role: tokenResult.claims.role,
              photoURL: tokenResult.claims.picture
            };
            const apiKey = await this.props.httpClient.algolia.getApiKey();
            if (apiKey && apiKey.publicKey) {
              user.algoliaPublicKey = apiKey.publicKey;
            }
            this.setState({ user });
            this.props.receiveUserAuthStateChange(user);
            this.unsubscribeUserListener = this.registerUserSnapshotListener(
              user
            );
            this.unsubscribeMessagesListener = this.registerUserMessagesSnapshotListener(
              user.uid
            );
            this.unsubscribeEventsListener = this.registerUserEventsSnapshotListener(
              user.uid
            );
            this.getDispositions();
            this.getAgents();
            this.getSources();
            this.getCampaigns();
            this.getCampaignTypes();
            authUser.getIdTokenResult().then(idTokenResult => {
              const sessionDuration = 1000 * 60 * 60 * 8; // 8 hour
              sessionTimeout = setTimeout(() => {
                window.location.reload();
              }, sessionDuration);
            });
          } else {
            sessionTimeout && clearTimeout(sessionTimeout);
            sessionTimeout = null;
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
        () => {
          this.setState({ user: null });
        }
      );
    }

    componentWillUnmount() {
      if (typeof unsubscribeAuthStateListener === "function") {
        this.unsubscribeAuthStateListener();
      }
      if (typeof unsubscribeUserListener === "function") {
        this.unsubscribeUserListener();
      }
      if (typeof this.unsubscribeMessagesListener === "function") {
        this.unsubscribeMessagesListener();
      }
      if (typeof this.unsubscribeOutboxListener === "function") {
        this.unsubscribeOutboxListener();
      }
      if (typeof this.unsubscribeEventsListener === "function") {
        this.unsubscribeEventsListener();
      }
    }

    registerUserSnapshotListener = user => {
      return onSnapshot(
        doc(this.firestore, "users", user.uid),
        async snapshot => {
          if (snapshot.exists) {
            let firebaseUser = { ...snapshot.data() };
            if (this.props.user && this.props.user.role !== firebaseUser.role) {
              this.firebase.doSignOut();
              window.location.reload();
            }
            if (firebaseUser.active === false) {
              this.firebase.doSignOut();
              window.location.reload();
            }
            if (firebaseUser.agentRef) {
              const agentSnapshot = await getDoc(firebaseUser.agentRef);
              if (agentSnapshot.exists) {
                firebaseUser = { ...firebaseUser, ...agentSnapshot.data() };
              }
            }
            this.props.setFirestoreUser(firebaseUser);
            this.setState({ ...this.state.user, ...firebaseUser });
          }
        }
      );
    };

    registerUserMessagesSnapshotListener = uid => {
      return onSnapshot(
        collection(this.firestore, "users", uid, "messages"),
        snapshot => {
          const messages = snapshot.docs.map(e => {
            return { id: e.id, ...e.data() };
          });
          this.props.receiveMessagesSnapshot(messages);
        }
      );
    };

    registerUserEventsSnapshotListener = uid => {
      const q = query(
        collection(this.firestore, "events"),
        where("uid", "==", uid),
        where(
          "scheduledAt",
          ">=",
          moment()
            .subtract(30, "minutes")
            .toDate()
        ),
        orderBy("scheduledAt", "asc"),
        limit(10)
      );
      return onSnapshot(q, snapshot => {
        const events = snapshot.docs.map(e => {
          return { id: e.id, ...e.data() };
        });
        this.props.receiveEventsSnapshot(events);
      });
    };

    getAgents = async () => {
      onSnapshot(collection(this.firestore, "users"), snapshot => {
        const users = snapshot.docs
          .map(e => {
            return { id: e.id, ...e.data() };
          })
          .reduce((previous, current) => {
            previous[current.id] = current;
            return previous;
          }, {});
        this.props.receiveMetadataSnapshot("users", users);
      });
    };

    getDispositions = () => {
      const q = query(
        collection(this.firestore, "dispositions"),
        orderBy("order", "asc")
      );
      onSnapshot(q, snapshot => {
        const dispositions = snapshot.docs
          .map(e => e.data())
          .reduce((previous, current) => {
            previous[current.name] = {
              color: current.color,
              backgroundColor: current.backgroundColor,
              group: current.group,
              hide: current.hide || false
            };
            return previous;
          }, {});
        this.props.receiveMetadataSnapshot("dispositions", dispositions);
        const groups = _.uniq(snapshot.docs.map(e => e.data().group));
        this.props.receiveMetadataSnapshot("groups", groups);
      });
    };

    getSources = () => {
      const q = query(collection(this.firestore, "sources"), orderBy("name"));
      return onSnapshot(q, snapshot => {
        const sources = snapshot.docs.map(e => {
          return { id: e.id, ...e.data() };
        });
        this.props.receiveMetadataSnapshot("sources", sources);
      });
    };

    getCampaigns = () => {
      const q = query(collection(this.firestore, "campaigns"), orderBy("name"));
      return onSnapshot(q, snapshot => {
        const campaigns = snapshot.docs.map(e => {
          return { id: e.id, ...e.data() };
        });
        this.props.receiveMetadataSnapshot("campaigns", campaigns);
      });
    };

    getCampaignTypes = () => {
      const q = query(collection(this.firestore, "campaignTypes"), orderBy("name"));
      return onSnapshot(q, snapshot => {
        const campaignTypes = snapshot.docs.map(e => {
          return { id: e.id, ...e.data() };
        });
        this.props.receiveMetadataSnapshot("campaignTypes", campaignTypes);
      });
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state.user}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
    withHttpClient,
    connect(state => ({ user: state.user }), {
      receiveUserAuthStateChange,
      receiveMetadataSnapshot,
      receiveMessagesSnapshot,
      receiveEventsSnapshot,
      setFirestoreUser
    })
  )(WithAuthentication);
};

export default withAuthentication;
