export default class SearchApi {
  constructor(axios) {
    this.axios = axios;
  }
  leads = async query => {
    const response = await this.axios.get(
      `${process.env.REACT_APP_API_HOST}/search/leads?q=${query}`
    );
    return response.data.results;
  };
}
