import React, { useState, FC, ReactElement, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Firebase from '../../Firebase';
import { httpsCallable } from "@firebase/functions";
import { collection, getDocs, query, orderBy } from '@firebase/firestore';

interface IAuthUser {
  displayName: string;
  email: string;
  uid: string;
  metadata: any;
  isAdmin: boolean;
  algoliaPublicKey: string;
  role: string;
}

interface ICreateSourceDialog {
  authUser: IAuthUser;
  firebase: Firebase;
  open: boolean;
  handleClose: () => void;
  handleRefresh: () => void;
  showCampaigns?: boolean;
}

export const CreateSourceDialog: FC<ICreateSourceDialog> = ({ authUser, firebase, open, handleClose, handleRefresh, showCampaigns = false }): ReactElement => {
    const [name, setName] = useState("");
    const [campaign, setCampaign] = useState("");
    const [campaigns, setCampaigns] = useState<any>([]);
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (showCampaigns) {
            loadCampaigns();
        }
    }, [showCampaigns]); // eslint-disable-line

    const loadCampaigns = async() => {
      let q = query(collection(firebase.firestore, "campaigns"));
      q = query(q, orderBy('name'));
      const docs = await getDocs(q);
      const results = docs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCampaigns(results);
    }

    const handleTextChange = (set: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        set(event.target.value);
    }

    const handleSubmit = async () => {
      if (authUser.isAdmin) {
        try {
            await httpsCallable(firebase.functions, "createSource")({ name, campaign, description });
        } catch (error) {
            setErrors({ name: error });
            return;
        }
        handleRefresh();
      }
    }

    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
        <DialogTitle id="form-dialog">Create Source</DialogTitle>
        <DialogContent>
            <TextField
                variant="outlined"
                margin="normal"
                id="name"
                name="name"
                label="Name"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}      
                onChange={handleTextChange(setName)}
                fullWidth
            />
            <TextField
                variant="outlined"
                margin="normal"
                id="description"
                name="description"
                label="Description"
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}      
                onChange={handleTextChange(setDescription)}
                fullWidth
            />
            {showCampaigns && (
                <TextField
                    variant="outlined"
                    margin="normal"
                    id="campaign"
                    name="campaign"
                    label="Campaign"
                    value={campaign}
                    helperText={errors.campaign}
                    error={Boolean(errors.campaign)}
                    onChange={handleTextChange(setCampaign)}
                    fullWidth
                    select
                >
                  {campaigns.map((c: any) => (
                    <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
                  ))}
                </TextField>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button onClick={handleSubmit} size="small" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
}
