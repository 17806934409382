import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { withFirebase } from "../Firebase";
import { rename } from "../../util/FileUtil";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";

const styles = theme => ({
  container: {
    width: 200
  },
  profileImg: {
    borderRadius: 200,
    marginBottom: theme.spacing(1)
  }
});

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      progress: 0
    };
  }

  ref = React.createRef();

  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.handleUpload(image);
    }
  };

  handleUpload = image => {
    const file = rename(image, uuidv4());

    const imageRef = ref(
      this.props.firebase.storage,
      `profileImages/${file.name}`
    );
    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      error => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(url => {
          this.setState({ url });
          this.props.firebase.doProfileImageUpdate(url);
        });
      }
    );
  };
  render() {
    const { user, classes } = this.props;
    return (
      <div className={classes.container}>
        <img
          className={classes.profileImg}
          src={this.state.url || user.photoURL}
          alt="Uploaded Images"
          height="200"
          width="200"
        />
        <input
          ref={this.ref}
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={this.handleChange}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            component={"span"}
            fullWidth
          >
            Upload
          </Button>
        </label>
      </div>
    );
  }
}

export default withFirebase(withStyles(styles)(ImageUpload));
