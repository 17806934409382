import axios from "axios";

export default class IpAddressApi {
  static async getIpAddress() {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/ipAddress/`
    );
    return Promise.resolve(response.data);
  }
}
