import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_LEADS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_LEADS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveLeadsSnapshot = (leads, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_LEADS_SNAPSHOT,
    leads,
    endBefore,
    startAfter
  };
};

export const receiveLeadsTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_LEADS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
