import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  collection,
  doc,
  orderBy as orderByFunc,
  query,
  where as whereFunc,
  startAfter as startAfterFunc,
  limit,
  onSnapshot
} from "@firebase/firestore";

const withListener = (
  collectionName,
  mapDispatchToProps,
  roles
) => Component => {
  class WithListener extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.user && !prevProps.user) {
        this.registerListeners();
      }
    }

    componentDidMount() {
      if (this.props.user) {
        this.registerListeners();
      }
    }

    componentWillUnmount() {
      this.unsubscribeListener();
      if (this.unsubscribeTotalCountListener) {
        this.unsubscribeTotalCountListener();
      }
    }

    registerListeners = () => {
      this.unsubscribeListener = this.registerSnapshotListener(
        this.props[collectionName]
      );
      this.unsubscribeTotalCountListener = this.registerTotalCountSnapshotListener();
    };

    getQuery = () => {
      const firestore = this.props.firebase.firestore;
      const userIsRole = roles && roles.includes(this.props.user.role);
      return this.props.user.isAdmin || userIsRole
        ? query(collection(firestore, collectionName))
        : query(
            collection(firestore, collectionName),
            whereFunc("id", "==", this.props.user.uid)
          );
    };

    getQueryWithSearchCriteria = (
      order,
      orderBy,
      rowsPerPage,
      page,
      where,
      reset
    ) => {
      const {
        [collectionName]: dataSet,
        [collectionName]: { endBefore, startAfter }
      } = this.props;

      let q = this.getQuery();

      if (where && where.id) {
        return doc(this.props.firebase.firestore, collectionName, where.id);
      }

      if (!reset) {
        if (page > dataSet.page) {
          q = query(q, orderByFunc(orderBy, order), startAfterFunc(startAfter));
        } else if (page < dataSet.page) {
          q = query(
            q,
            orderByFunc(orderBy, order === "asc" ? "desc" : "asc"),
            startAfterFunc(endBefore)
          );
        } else {
          q = query(q, orderByFunc(orderBy, order));
        }
      } else {
        q = query(q, orderByFunc(orderBy, order));
      }

      q = query(q, limit(rowsPerPage));

      return q;
    };

    registerSnapshotListener = (
      { order, orderBy, rowsPerPage, page, where },
      reset
    ) => {
      const { receiveSnapshot } = this.props;
      const {
        [collectionName]: { page: currentPage }
      } = this.props;
      return onSnapshot(
        this.getQueryWithSearchCriteria(
          order,
          orderBy,
          rowsPerPage,
          page,
          where,
          reset
        ),
        snapshot => {
          if (snapshot.id) {
            receiveSnapshot(
              [{ ...snapshot.data(), id: snapshot.id }],
              snapshot,
              snapshot
            );
          } else {
            const snapshots = this.maybeReverseSnapshots(
              snapshot,
              page,
              currentPage,
              reset
            );
            const results = snapshots.map(doc => ({
              ...doc.data(),
              id: doc.id
            }));
            const endBefore = snapshots[0];
            const startAfter = snapshots[results.length - 1];
            receiveSnapshot(results, endBefore, startAfter);
          }
        }
      );
    };

    registerTotalCountSnapshotListener = () => {
      const { receiveTotalCountSnapshot } = this.props;
      if (receiveTotalCountSnapshot) {
        return onSnapshot(this.getQuery(), snapshot => {
          let totalCount = snapshot.size;
          receiveTotalCountSnapshot(totalCount);
        });
      }
    };

    maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
      let snapshots = snapshot.docs;
      if (!reset) {
        if (previousPage < currentPage) {
          snapshots = snapshots.reverse();
        }
      }
      return snapshots;
    };

    handleRetrieveData = (searchCriteria, reset) => {
      const { setSearchCriteria } = this.props;
      this.unsubscribeListener();
      this.unsubscribeListener = this.registerSnapshotListener(
        searchCriteria,
        reset
      );
      setSearchCriteria(searchCriteria);
    };

    render() {
      return (
        <Component
          {...this.props}
          handleRetrieveData={this.handleRetrieveData}
        />
      );
    }
  }
  return compose(
    connect(
      state => ({
        user: state.user,
        metadata: state.metadata,
        [collectionName]: state[collectionName]
      }),
      mapDispatchToProps
    )
  )(WithListener);
};

export default withListener;
