import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.metadata, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_METADATA_SNAPSHOT:
      return {
        ...state,
        [action.key]: action.value
      };
    case ActionTypes.SIGN_OUT_SUCCESS: {
      return initialState.metadata;
    }
    default:
      return state;
  }
};
