const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`
  },
  menuButton: {
    marginRight: 36
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(2)
  },
  toolBarContent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  leftSide: {
    flexBasis: "25%",
    display: "flex",
    alignItems: "center"
  },
  center: {
    display: "flex",
    flex: 2,
    justifyContent: "center"
  },
  rightSide: {
    flexBasis: "25%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  time: {
    marginRight: theme.spacing(2)
  },
  logo: { height: 36, marginTop: 0, marginRight: theme.spacing(2) },
  partnerName: {
    marginTop: 2
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  avatarButton: {
    padding: 0
  },
  avatar: {
    border: "solid 1px rgba(0, 0, 0, 0.23)"
  },
  eventButton: {
    marginRight: theme.spacing(2)
  }
});

export default styles;
