import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Title from "../../Title";
import styles from "../styles";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import {
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  getDocs,
  collection
} from "@firebase/firestore";

class Processing extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      salesReps: [],
      processors: []
    };
  }

  componentDidMount = async () => {
    const firestore = this.props.firebase.firestore;
    const salesRepsSnapshot = await getDocs(collection(firestore, "salesReps"));
    const salesRepsData = salesRepsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    const processorsSnapshot = await getDocs(collection(firestore, "processors"));
    const processorsData = processorsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    this.setState({ salesReps: salesRepsData, processors: processorsData });
  };

  renderSalesRepOptions = (office) => {
    return this.state.salesReps
    .filter(rep => rep.office.includes(office))
    .sort((a, b) => a.code.localeCompare(b.code))
    .map((rep) => (
      <MenuItem key={rep.code} value={rep.id}>
        {rep.name} - {rep.code}
      </MenuItem>
    ));
  };  
  
  renderProcessorsOptions = () => {
    return this.state.processors.map((processor) => (
      <MenuItem key={processor.code} value={processor.code}>
        {processor.name}
      </MenuItem>
    ));
  };

  renderOfficeOptions = () => {
    const offices = Array.from(new Set(this.state.salesReps.flatMap(rep => rep.office)))
      .sort((a, b) => a.localeCompare(b));
    return offices.map(office => (
      <MenuItem key={office} value={office}>
        {office}
      </MenuItem>
    ));
  };  

  render() {
    const {
      application,
      onApplicationTextChange,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        <Title
          h1={"Processing information"}
        />
        <div className={classes.sectionForm}>
          <div className={classes.inputContainer}>

          <TextField
              id="processor"
              label="Processor"
              variant="outlined"
              placeholder="Choose payment processor"
              className={classes.sideMargins}
              value={application.processor || ""}
              onChange={onApplicationTextChange("processor")}
              error={!!errors.processor}
              helperText={errors.processor}
              margin="normal"
              select
              fullWidth
            >
              {this.renderProcessorsOptions()}
            </TextField>

            <TextField
              id="office"
              label="Office"
              variant="outlined"
              placeholder="Choose office number"
              className={classes.sideMargins}
              value={application.office || ""}
              onChange={onApplicationTextChange("office")}
              error={!!errors.office}
              helperText={errors.office}
              margin="normal"
              select
              fullWidth
            >
              {this.renderOfficeOptions()}
            </TextField>

            <TextField
              id="salesRepInfo"
              label="Sales Rep"
              placeholder="Choose sales rep"
              variant="outlined"
              className={classes.sideMargins}
              value={application.salesRepInfo || ""}
              onChange={onApplicationTextChange("salesRepInfo")}
              error={!!errors.salesRepInfo}
              helperText={errors.salesRepInfo}
              margin="normal"
              select
              fullWidth
            >
              {this.renderSalesRepOptions(application?.office)}
            </TextField>

          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withFirebase
)(Processing);
