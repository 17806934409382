import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import styles from "./styles";

class MobileSteps extends Component {
  render() {
    const { activeStep, steps, onNext, onBack, loading, classes } = this.props;
    return (
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={onNext}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color="inherit"
                className={classes.buttonProgress}
              />
            ) : (
              <Fragment>
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
                <KeyboardArrowRight />
              </Fragment>
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={onBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    );
  }
}

export default withStyles(styles)(MobileSteps);
