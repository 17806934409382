import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Title from "../../Title";
import styles from "../styles";

class AlmostDone extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Title h2={"Your Hardware Order Has Successfully Been Placed!"} />
        <Typography variant="body1" align="center" gutterBottom>
          Now Let’s Activate your Merchant Account so your hardware arrives
          ready to accept all major credit cards.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(AlmostDone);
