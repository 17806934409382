import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import OdleCRM from "../../static/images/odlecrm.png";
import styles from "./styles";

/*
google.options({
  http2: false,
});*/

const INITIAL_STATE = {
  email: "",
  password: "",
  errors: {}
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        POS PROS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class SignInPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    if (this.isValid()) {
      const { email, password } = this.state;

      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ errors: { email: "Invalid Login" } });
        });
    }
    event.preventDefault();
  };

  signInWithGoogle = event => {
    this.props.firebase
      .doSignInWithGoogleProvider()
      .then(result => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ errors: { email: "Invalid Login" } });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isValid = () => {
    let isValid = true;
    let errors = {};
    if (!this.state.email || this.state.email === "") {
      isValid = false;
      errors.email = "Email Address is Required";
    }
    if (!this.state.password || this.state.password === "") {
      isValid = false;
      errors.password = "Password is Required";
    }
    if (!isValid) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }
    return isValid;
  };

  render() {
    const { email, password, errors } = this.state;

    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={OdleCRM} alt="OdleCRM" className={classes.logo} />
          <form className={classes.form} noValidate>
            <TextField
              value={email}
              onChange={this.onChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              value={password}
              onChange={this.onChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.onSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={this.signInWithGoogle}>
                  Sign in with Google
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withFirebase
)(SignInPage);
