import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.messages, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_EVENTS_SNAPSHOT:
      return [...action.events];
    case ActionTypes.SIGN_OUT_SUCCESS: {
      return initialState.events;
    }
    default:
      return state;
  }
};
