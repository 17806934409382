import React from 'react';
import moment from 'moment';
import { Chip, makeStyles } from '@material-ui/core';
import { ActiveFilter } from './';

const useStyles = makeStyles(theme => ({
    chip: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    filter: {
        float: 'left',
        marginBottom: '10px',
    },
}));

interface Props {
    filters: ActiveFilter[];
    onDeleteFilter: (index: number) => void;
}

export const FiltersPreview = ({ filters, onDeleteFilter }: Props) => {
    const classes = useStyles();

    const handleDeleteFilter = (index: number) => () => {
        onDeleteFilter(index);
    }

    const renderDateRange = (filter: ActiveFilter, index: number) => (
        <Chip
            className={classes.chip}
            label={
                <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{moment(filter.value[0]).format('MM/DD/YYYY')}</b>' and '
                    <b>{moment(filter.value[1]).format('MM/DD/YYYY')}</b>'
                </span>
            }
            variant="outlined"
            onDelete={handleDeleteFilter(index)}
        />
    );

    const renderList = (filter: ActiveFilter, index: number) => (
        <Chip
            className={classes.chip}
            label={
                <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '<b>{filter.value}</b>'
                </span>
            }
            variant="outlined"
            onDelete={handleDeleteFilter(index)}
        />
    );

    const renderFilter = (filter: ActiveFilter, index: number) => (
        <div key={index} className={classes.filter}>
            {filter.option.type === 'dateRange' && filter.operator.id === '__between' && 
                renderDateRange(filter, index)
            }
            {filter.option.type === "list" && filter.operator.id === '__in' &&
                renderList(filter, index)
            }
        </div>
    );

    return (
        <>
            {filters && filters.length > 0 && filters.map((filter, index) => renderFilter(filter, index))}
        </>
    );
}
