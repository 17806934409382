import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { createLog } from "../../util/ActivityUtil";

import styles from "./styles";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc
} from "@firebase/firestore";

class EventDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.event.id,
      scheduledAt: moment(
        props.event.scheduledAt ? props.event.scheduledAt.toDate() : new Date()
      ),
      title: props.event.title || ""
    };
    this.originalEvent = {
      id: props.event.id,
      scheduledAt: moment(
        props.event.scheduledAt ? props.event.scheduledAt.toDate() : new Date()
      ).toDate(),
      title: props.event.title || ""
    };
    this.log = createLog(props.firebase, props.user);
  }

  componentDidMount = async () => {
    // TODO: Show todays events
  };

  handleSubmit = async () => {
    const event = {
      uid: this.props.user.uid,
      leadId: this.props.lead.id,
      title: this.state.title,
      scheduledAt: this.state.scheduledAt.toDate(),
      createdAt: new Date()
    };
    const firestore = this.props.firebase.firestore;
    if (this.state.id) {
      await setDoc(doc(firestore, "events", this.state.id), event, {
        merge: true
      });
      this.log("Event Updated", {
        oldEvent: this.originalEvent,
        newEvent: event
      });
    } else {
      await addDoc(collection(firestore, "events"), event);
      this.log("Event Created", { event });
    }
    this.handleClose();
  };

  handleDelete = async () => {
    if (this.state.id) {
      const firestore = this.props.firebase.firestore;
      await deleteDoc(doc(firestore, "events", this.state.id));
      this.log("Event Deleted", { eventId: this.state.id });
    }
    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDateChange = date => {
    this.setState({ scheduledAt: date });
  };

  render() {
    const { id, scheduledAt, title } = this.state;
    const { open, classes } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="event-dialog-title"
          open={open}
        >
          <DialogTitle id="event-dialog-title">
            {id ? "Editing" : "Creating"} Event
          </DialogTitle>
          <div className={classes.content}>
            <TextField
              id="title"
              name="title"
              label="Title"
              multiline
              maxRows="4"
              value={title}
              onChange={this.handleTextChange}
              variant="outlined"
              fullWidth
              className={classes.textField}
            />
            <KeyboardDatePicker
              inputVariant="outlined"
              required
              margin="normal"
              id="date"
              label="Date"
              value={scheduledAt}
              format="MM/DD/YYYY"
              onChange={this.handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              fullWidth
            />
            <KeyboardTimePicker
              inputVariant="outlined"
              required
              margin="normal"
              id="time"
              label="Time"
              value={scheduledAt}
              onChange={this.handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
              fullWidth
            />
          </div>
          <DialogActions className={classes.actions}>
            <Button
              onClick={this.handleDelete}
              variant="outlined"
              size="small"
              color="default"
            >
              Delete
            </Button>
            <Button
              onClick={this.handleClose}
              variant="outlined"
              size="small"
              color="default"
            >
              Close
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    user: state.user
  })),
  withFirebase
)(EventDialog);
