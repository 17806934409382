import axios from "axios";
import ReportsApi from "../../api/Reports";
import SearchApi from "../../api/Search";
import AlgoliaApi from "../../api/Algolia";
import Firebase from "../Firebase";
import { getIdToken } from "firebase/auth";
import PdfService from "../../api/PdfService";

class HttpClient {
  axios: any;
  reports: any;
  search: any;
  algolia: any;
  pdfService: any;

  constructor(firebase: Firebase) {
    const firebaseInstance = axios.create();
    firebaseInstance.interceptors.request.use(
      async config => {
        if (firebase.auth.currentUser) {
          const token = await getIdToken(firebase.auth.currentUser);
          config.headers.common["Authorization"] = `Bearer ${token}`;
          return config;
        }
        else {
          return Promise.reject('Not Authorized');  
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
    this.axios = axios;
    this.reports = new ReportsApi(firebaseInstance);
    this.search = new SearchApi(firebaseInstance);
    this.algolia = new AlgoliaApi(axios, firebaseInstance);
    this.pdfService = new PdfService(firebaseInstance);
  }
}

export default HttpClient;
