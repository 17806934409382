import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import replace from "react-string-replace";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";

import TermsDialog from "../../Terms";
import TermsDialogAdditional from "../../TermsAdditional";

import SignaturePad from "react-signature-canvas";

import { download } from "../../../../util/FileUtil";

import styles from "../styles";

class Review extends PureComponent {
  downloadTerms = () => {
    if (this.props.application.processor === "i3") {
      Promise.all([
        download(
          "https://firebasestorage.googleapis.com/v0/b/pos-brokers-production.appspot.com/o/terms%2Fen%2Fi3.pdf?alt=media&token=44eab504-370c-4d63-82c5-f9c1dbbe67fb",
          "i3-terms-and-conditions"
        ),
        download(
          "https://firebasestorage.googleapis.com/v0/b/pos-brokers-production.appspot.com/o/terms%2Fen%2Fpos-pros.pdf?alt=media&token=2fb06f9f-12cc-42a6-b464-09bdcb759ea6",
          "pos-pros-terms-and-conditions"
        )
      ]);
    } else {
      Promise.all([
        download(
          "https://firebasestorage.googleapis.com/v0/b/pos-brokers-production.appspot.com/o/terms%2Fen%2Fpaysafe.pdf?alt=media&token=381b0577-80b6-45fe-8d04-cea2c0483f58",
          "paysafe-terms-and-conditions"
        ),
        download(
          "https://firebasestorage.googleapis.com/v0/b/pos-brokers-production.appspot.com/o/terms%2Fen%2Fpos-pros.pdf?alt=media&token=2fb06f9f-12cc-42a6-b464-09bdcb759ea6",
          "pos-pros-terms-and-conditions"
        )
      ]);
    }
  };
  render() {
    const {
      application,
      onAgreeCheckChange,
      onAgreeAdditionalCheckChange,
      signatureRef,
      onClearSignature,
      errors,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.sectionTitle} variant="h5" gutterBottom>
          Review and Submit
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.inputContainer}>
          <FormGroup className={classes.checkboxContainer} row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={application.agree}
                  onChange={onAgreeCheckChange("agree")}
                  value="agree"
                />
              }
              label={replace(
                "I agree to the Terms and Conditions",
                "Terms and Conditions",
                match => (
                  <TermsDialog
                    key={match}
                    application={application}
                    onDownloadClick={this.downloadTerms}
                  />
                )
              )}
            />
          </FormGroup>
        </div>

        <div className={classes.inputContainer}>
          <FormGroup className={classes.checkboxContainer} row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={application.agreeAdditional}
                  onChange={onAgreeAdditionalCheckChange("agreeAdditional")}
                  value="agreeAdditional"
                />
              }
              label={replace(
                "I agree to Equipment Placement Program",
                "Equipment Placement Program",
                match => (
                  <TermsDialogAdditional
                    key={match}
                    application={application}
                    onDownloadClick={this.downloadTermsAdditional}
                  />
                )
              )}
            />
          </FormGroup>
        </div>

        {errors.agree && (
          <div className={classes.termsError}>
            <Typography
              className={classes.error}
              variant="caption"
              gutterBottom
            >
              You must agree to the Terms and Conditions.
            </Typography>
          </div>
        )}
        {errors.agreeAdditional && (
          <div className={classes.termsError}>
            <Typography
              className={classes.error}
              variant="caption"
              gutterBottom
            >
              You must agree to Equipment Placement Program.
            </Typography>
          </div>
        )}
        <Typography className={classes.sectionTitle} variant="h5" gutterBottom>
          Signature
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.caption} variant="caption" gutterBottom>
          Draw your signature with your mouse or finger:
        </Typography>
        <div className={classes.signatureContainer}>
          <SignaturePad
            canvasProps={{ className: classes.signaturePad }}
            ref={signatureRef}
          />
        </div>
        <Button
          variant="outlined"
          size="small"
          onClick={onClearSignature}
          className={classes.clearButton}
        >
          Clear Signature
        </Button>
        {errors.signature && (
          <div className={classes.signatureError}>
            <Typography
              className={classes.error}
              variant="caption"
              gutterBottom
            >
              You must draw your signature.
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Review);
