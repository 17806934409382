import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import AuthUserContext from "./context";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as ROUTES from "../../constants/routes";

const Loading = withStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}))(props => (
  <Backdrop className={props.classes.backdrop} open={true}>
    <CircularProgress color="inherit" />
  </Backdrop>
));

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.user && !prevProps.user) {
        if (!condition(this.props.user)) {
          this.props.history.push(ROUTES.SIGN_IN);
        }
      }
    }
    componentDidMount() {
      const user = this.props.user;
      if (user) {
        if (!condition(user)) {
          this.props.history.push(ROUTES.SIGN_IN);
        }
      }
    }
    render() {
      return (
        <AuthUserContext.Consumer>
          {user =>
            user && condition(user) ? (
              <Component {...this.props} />
            ) : (
              <Loading />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    connect(state => ({ user: state.user }))
  )(WithAuthorization);
};

export default withAuthorization;
