import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../../Session";
import { withLayout } from "../../Layout";
import { withFirebase } from "../../Firebase";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import ReactJson from "react-json-view";
import { createLog } from "../../../util/ActivityUtil";
import Check from "../../../static/images/checkmark.png";
import {
  collection,
  getDoc,
  onSnapshot,
  query,
  addDoc,
  setDoc,
  where,
  orderBy,
  doc
} from "@firebase/firestore";

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: {}
    };
    this.log = createLog(props.firebase, props.user);
  }

  registerTaskListener = applicationId => {
    const firestore = this.props.firebase.firestore;
    const q = query(
      collection(firestore, "tasks"),
      where("applicationId", "==", applicationId),
      orderBy("createdAt", "desc")
    );
    this.unsubscribeTaskListener = onSnapshot(q, snapshot => {
      const tasks = snapshot.docs.map(e => ({ id: e.id, ...e.data() }));
      this.setState({ tasks });
    });
  };

  componentDidMount = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const firestore = this.props.firebase.firestore;
      const application = await getDoc(doc(firestore, "applications", id));
      const data = application.data();
      this.setState({ application: { id: application.id, ...data } });
      this.unregisterTaskListener = this.registerTaskListener(id);
    }
  };

  componentWillUnmount = () => {
    if (this.unregisterTaskListener) {
      this.unregisterTaskListener();
    }
  };

  handleJsonViewEdit = edit => {
    if (edit.namespace.length > 0) {
      return;
    }

    const set = { [edit.name]: edit.new_value };
    const firestore = this.props.firebase.firestore;
    setDoc(doc(firestore, "applications", this.state.application.id), set, {
      merge: true
    });
    this.log("Update Application Field", {
      field: edit.name,
      oldValue: this.state.application[edit.name],
      newValue: edit.new_value
    });
  };

  handleRetryTask = task => async () => {
    const newTask = {
      action: task.action,
      applicationId: task.applicationId,
      description: task.description,
      payload: task.payload,
      queue: task.queue
    };
    await addDoc(collection(this.props.firebase.firestore, "tasks"), newTask);
  };

  handleCancelTask = task => async () => {
    await setDoc(
      doc(this.props.firebase.firestore, "tasks", task.id),
      { status: "Failed" },
      { merge: true }
    );
  };

  jsonView = application => {
    const view = {
      ...application,
      createdAt: application.createdAt.toDate(),
      completedAt: application.completedAt
        ? application.completedAt.toDate()
        : null
    };
    delete view.link;
    delete view.creditCardNumber;
    return view;
  };

  render() {
    const { classes } = this.props;
    const { application, tasks } = this.state;
    return (
      <div>
        <div className={classes.titleBar}>
          <Typography variant="h4">Applications</Typography>
        </div>
        {application.id && (
          <Paper className={classes.jsonPaper}>
            <div className={classes.jsonView}>
              <ReactJson
                name={application.id}
                src={this.jsonView(application)}
                onEdit={this.handleJsonViewEdit}
              />
            </div>
          </Paper>
        )}
        {tasks && (
          <div>
            <Typography component="h1" variant="h5" gutterBottom>
              Tasks
            </Typography>

            {tasks.map(row => (
              <Accordion key={row.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                >
                  <div className={classes.statusContainer}>
                    {row.status === "Complete" ? (
                      <img
                        src={Check}
                        className={classes.check}
                        alt="success"
                      />
                    ) : row.status === "Failed" ? (
                      <ErrorOutlineIcon color="error" />
                    ) : (
                      <CircularProgress size={24} />
                    )}
                  </div>
                  <Typography
                    color="textSecondary"
                    className={classes.taskTime}
                  >
                    {moment(row.createdAt.toDate()).format("L hh:mm:ss a")}
                  </Typography>
                  <Typography color="textSecondary">
                    {row.description}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionDetails}>
                  {row.error && <Alert severity="error">{row.error}</Alert>}
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell>Date Completed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.action}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {moment(row.createdAt.toDate()).format(
                            "L hh:mm:ss a"
                          )}
                        </TableCell>
                        <TableCell>
                          {row.completedAt
                            ? moment(row.completedAt.toDate()).format(
                                "L hh:mm:ss a"
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
                <AccordionActions>
                  {row.status === "Enqueued" && (
                    <Button size="small" onClick={this.handleCancelTask(row)}>
                      Cancel
                    </Button>
                  )}
                  <Button size="small" onClick={this.handleRetryTask(row)}>
                    Retry
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withLayout,
  withStyles(styles),
  withFirebase,
  withAuthorization(condition)
)(Details);
