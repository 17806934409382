import React, { PureComponent, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";

import { Button, CircularProgress, Grid } from "@material-ui/core";

import styles from "./styles";

class Actions extends PureComponent {
  render() {
    const {
      activeStep,
      steps,
      onNext,
      onSave,
      onBack,
      loading,
      classes,
      showSubmit,
      showBack,
      showNext
    } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          {" "}
          <Button
            variant="contained"
            fullWidth
            onClick={onBack}
            disabled={activeStep === 0 || !showBack}
          >
            Back
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={onSave}
            disabled={!showSubmit}
          >
            Submit Application
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          {" "}
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            onClick={onNext}
            disabled={activeStep === steps.length - 1 || loading || !showNext}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color="inherit"
                className={classes.buttonProgress}
              />
            ) : (
              <Fragment>Next</Fragment>
            )}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Actions);
