import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withHttpClient } from "../HttpClient";
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Chip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NoteIcon from "@material-ui/icons/Note";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Popper } from "@material-ui/core";

const styles = theme => ({
  textField: {
    width: "100%",
    paddingRight: theme.spacing(1)
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
});

const WidePopper = function(props) {
  return (
    <Popper {...props} style={{ minWidth: 400 }} placement="bottom-start" />
  );
};

class AutoComplete extends Component {
  state = {
    open: false,
    spinner: false,
    options: [],
    inputValue: ""
  };

  search = AwesomeDebouncePromise(
    this.props.httpClient.algolia.autocompleteSearch(
      this.props.index,
      this.props.publicKey
    ),
    750
  );

  handleChange = async event => {
    this.setState({ spinner: true, loading: true });
    const options = await this.search(event.target.value);

    this.setState({ options, loading: false, spinner: false }, () => {
      this.forceUpdate();
    });
  };

  render = () => {
    const { options, spinner, open } = this.state;
    const { classes, onChange, metadata } = this.props;
    return (
      <Autocomplete
        open={open}
        onOpen={() => {
          this.setState({ open: true });
        }}
        onClose={() => {
          this.setState({ open: false });
        }}
        className={classes.textField}
        disableopenonfocus="true"
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={option => option.id}
        filterSelectedOptions
        filterOptions={x => x}
        options={options}
        loading={spinner}
        onChange={onChange}
        PopperComponent={WidePopper}
        renderInput={params => (
          <TextField
            {...params}
            label="Search"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
            InputProps={{
              ...params.InputProps,
              disableunderline: "true",
              endAdornment: (
                <React.Fragment>
                  {spinner ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={option => {
          return option.index === "prod_LEADS" ? (
            <Grid container>
              <Grid item>
                <ContactPhoneIcon className={classes.icon} />
              </Grid>
              <Grid container item xs direction="row">
                <Grid item xs={3}>
                  <Typography variant="body1" color="textSecondary">
                    <b>ID: </b>
                    {`${option.id.substring(0, 6)}...`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Name: </b>${
                        option._highlightResult.name
                          ? option._highlightResult.name.value
                          : ""
                      }`
                    }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>DBA: </b>${
                        option._highlightResult.dba
                          ? option._highlightResult.dba.value
                          : ""
                      }`
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Phone: </b>${
                        option._highlightResult.phone
                          ? option._highlightResult.phone.value
                          : ""
                      }`
                    }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Email: </b>${
                        option._highlightResult.email
                          ? option._highlightResult.email.value
                          : ""
                      }`
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    label={option.disposition}
                    style={{
                      backgroundColor:
                        metadata.dispositions[option.disposition]
                          .backgroundColor,
                      color: metadata.dispositions[option.disposition].color
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item>
                <NoteIcon className={classes.icon} />
              </Grid>
              <Grid container item xs direction="row">
                <Grid item xs={3}>
                  <Typography variant="body1" color="textSecondary">
                    <b>ID: </b>
                    {`${option.id.substring(0, 6)}...`}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Status: </b>${
                        option._highlightResult.status
                          ? option._highlightResult.status.value
                          : ""
                      }`
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>DBA: </b>${
                        option._highlightResult.doingBusinessAs
                          ? option._highlightResult.doingBusinessAs.value
                          : ""
                      }`
                    }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `<b>Legal Name: </b>${
                        option._highlightResult.legalBusinessName
                          ? option._highlightResult.legalBusinessName.value
                          : ""
                      }`
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{
                        __html: `<b>Name: </b>${
                          option._highlightResult.firstName &&
                          option._highlightResult.lastName
                            ? `${option._highlightResult.firstName.value} ${option._highlightResult.lastName.value}`
                            : ""
                        }`
                      }}
                    ></Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" color="textSecondary">
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{
                        __html: `<b>Phone: </b>${
                          option._highlightResult.businessPhone
                            ? option._highlightResult.businessPhone.value
                            : ""
                        }`
                      }}
                    ></Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{
                        __html: `<b>Email: </b>${
                          option._highlightResult.email
                            ? option._highlightResult.email.value
                            : ""
                        }`
                      }}
                    ></Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
    );
  };
}

export default compose(withStyles(styles), withHttpClient)(AutoComplete);
