const styles = theme => ({
  disabledTextWrapper: {
    display: 'inline-flex',
    width: '100%'
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionTitle: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  caption: {
    display: "block",
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  sectionForm: {
    flexGrow: 2
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  inputContainerRadio: {
    marginLeft: "2rem"
  },
  checkboxContainer: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1)
    }
  },
  differentCheckboxContainer: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1)
    }
  },
  sideMargins: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  },
  signatureContainer: {
    border: "solid 1px rgba(0, 0, 0, 0.87)",
    borderRadius: 4,
    width: "calc(50% - 32px)",
    height: 150,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)"
    }
  },
  signaturePad: {
    width: "100%",
    height: "100%"
  },
  clearButton: {
    marginLeft: theme.spacing(2),
    textTransform: "normal"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  error: {
    color: "red"
  },
  termsError: {
    marginLeft: theme.spacing(2)
  },
  signatureError: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  alignRight: {
    textAlign: "right"
  },
  table: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  option: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
    border: "solid 1px rgba(0, 0, 0, 0.38)",
    borderRadius: 5
  },
  rate: {
    marginLeft: theme.spacing(2)
  }
});

export default styles;
