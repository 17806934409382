import React, { Fragment } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { pospros, paysafe } from "./text.js";
import { jsPDF } from "jspdf";

const downloadPDF = () => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a3'
  });

  const container = document.createElement('div');
  
  let content = `
    <div style="width: 500px; margin-left: 30px">
      <p style="width: 500px; font-size: 12px; margin-left: 30px">Merchant Processing Agreement</p>
      <p style="text-align: justify; width: 500px; font-size: 8px; margin-left: 30px">${paysafe}</p>
      <p style="width: 500px; font-size: 12px; margin-left: 30px margin-top: 20px;">POS Brokers Terms and Conditions</p>
      <p style="text-align: justify; width: 500px; font-size: 8px; margin-left: 30px">${pospros}</p>
    </div>
  `;

  container.innerHTML = content;

  doc.html(container, {
    callback: function (doc) {
      doc.save('terms.pdf');
    },
    x: 10,
    y: 10,
    width: 180 // adjust width to fit your content
  });
}

const TermsDialog = ({ application, onDownloadClick }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    return () => {};
  }, [open]);

  return (
    <Fragment>
      <Link href="#" onClick={handleClickOpen("body")}>
        Terms and Conditions
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        fullScreen={fullScreen}
        aria-labelledby="terms-dialog-title"
        aria-describedby="terms-dialog-description"
      >
        <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="terms-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography variant="h6" component="h3">
              Merchant Processing Agreement
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: paysafe }} />
            <br />
            <br />
            <Typography variant="h6" component="h3">
              POS Brokers Terms and Conditions
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: pospros }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {fullScreen || (
            <Button size="small" onClick={downloadPDF} color="primary">
              Download
            </Button>
          )}
          <Button
            size="small"
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default TermsDialog;
