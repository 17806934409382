export const dataUrlToBlob = dataUrl => {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const download = (url, fileName) => {
  fetch(url).then(async t => {
    const b = await t.blob();
    var a = document.createElement("a");
    a.href = URL.createObjectURL(b);
    a.setAttribute("download", fileName);
    a.click();
  });
};

export const rename = (file, name) => {
  const ext = file.name.split(".").pop();
  const newName = `${name}.${ext}`.toLowerCase();
  const blob = file.slice(0, file.size, file.type);
  let newBlob = new Blob([blob], { type: file.type });
  newBlob.lastModifiedDate = new Date();
  newBlob.name = newName;
  return newBlob;
};
