import {
  Button,
  Link,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Alert } from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import classNames from "classnames";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  receiveLeadsSnapshot,
  setColumnVisibility,
  setSearchCriteria
} from "../../actions/leadActions";
import Google from "../../static/images/google.png";
import AutoComplete from "../AutoComplete";
import AutoCompleteSource from "../AutoCompleteSource";
import DataTable from "../DataTableInfiniteScroll";
import { withLayout } from "../Layout";
import LeadDialog from "../LeadDialog";
import { withAuthorization } from "../Session";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy as orderByFunc,
  query,
  startAfter as startAfterFunc,
  where as whereFunc
} from "@firebase/firestore";
import _ from "lodash";
import LeadExportDialog from "../LeadExportDialog";
import LeadPanel from "../LeadPanel";
import styles from "./styles";
import AutoCompleteCampaign from "../AutoCompleteCampaign";
import AutoCompleteCampaignType from "../AutoCompleteCampaignType";

const COLLECTION = "leads";

class LeadsPage extends Component {
  constructor(props) {
    super(props);
    this.phoneUtil = PhoneNumberUtil.getInstance();
    this.state = {
      row: null,
      leadDialogOpen: false,
      newLeadsCount: 0,
      unassignedCount: 0,
      exportDialogOpen: false,
      emptyMessage: null,
      loading: false,
      hasMore: true,
      isSalesMode: false,
    };
  }

  toggleSalesMode = () => {
    this.setState(prevState => ({ isSalesMode: !prevState.isSalesMode }), () => {
      let {
        order,
        orderBy,
        rowsPerPage,
        page,
        where
      } = this.props.leads;

      if (!this.state.isSalesMode) {
        if (where['uid']) {
          delete where['uid'];
        }
      }

      this.handleRetrieveData(
        {
          order,
          orderBy,
          rowsPerPage,
          page,
          where
        }
        , true);
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.user &&
      !prevProps.user &&
      this.props[COLLECTION].data.length < 1
    ) {
      this.registerListeners();
    }
    if (prevProps.location !== this.props.location) {
      this.registerListeners();
    }
  }

  async loadFiltersMeta() {
    const listOfMeta = ['sources', 'campaigns', 'campaignTypes'];
    const firestore = this.props.firebase.firestore;
    
    const promises = listOfMeta.map(async (meta) => {
      const querySnapshot = await getDocs(collection(firestore, meta));
      return querySnapshot.docs.map(doc => doc.data());
    });
  
    const [sources, campaigns, campaignTypes] = await Promise.all(promises);

    this.setState({ meta: {sources, campaigns, campaignTypes} });
  }

  async componentDidMount() {
    await this.loadFiltersMeta();

    if (this.props.user) {
      this.registerListeners();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeListener) {
      this.unsubscribeListener();
    }
    if (this.unsubscribeNewLeadsListener) {
      this.unsubscribeNewLeadsListener();
    }
    if (this.unsubscribeUnassignedLeadsListener) {
      this.unsubscribeUnassignedLeadsListener();
    }
    if (this.unsubscribeSyncDiagnosticsListener) {
      this.unsubscribeSyncDiagnosticsListener();
    }
  }

  registerListeners = () => {
    const collection = this.props[COLLECTION];
    this.unsubscribeNewLeadsListener = this.registerNewLeadsListener();
    this.unsubscribeUnassignedLeadsListener = this.registerUnassignedLeadsListener();
    this.unsubscribeListener = this.registerSnapshotListener(collection);
    this.unsubscribeSyncDiagnosticsListener = this.registerSyncDiagnosticsListener();
  };

  getQuery = () => {
    const userIsRole = ["support"].includes(this.props.user.role);
    const firestore = this.props.firebase.firestore;
    return this.props.user.isAdmin || userIsRole
      ? query(collection(firestore, COLLECTION))
      : query(
          collection(firestore, COLLECTION),
          whereFunc("uid", "==", this.props.user.uid)
        );
  };

  sendToKlaviyo = async () => {
    const firestore = this.props.firebase.firestore;
    const syncDiagnosticsRef = doc(firestore, "syncDiagnostics", "klaviyo");
  
    try {
      const docSnap = await getDoc(syncDiagnosticsRef);
  
      if (!docSnap.exists()) {
        await setDoc(syncDiagnosticsRef, {
          lastDateTime: null,
          lastStatus: null,
          lastError: null,
          lastSourcesCount: 0,
          lastLeadsCount: 0,
          history: [],
          status: 'done'
        });
        
      } 
      setTimeout( () => {
      updateDoc(syncDiagnosticsRef, {
        status: 'scheduled'
      });},1
      )
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }  

  getQueryWithSearchCriteria = (
    order,
    orderBy,
    rowsPerPage,
    page,
    where,
    reset
  ) => {
    const {
      [COLLECTION]: dataSet,
      [COLLECTION]: { endBefore, startAfter }
    } = this.props;
    const { campaigns } = this.state.meta;
  
    let q = this.getQuery();
    const firestore = this.props.firebase.firestore;
  
    if (where) {
      if (where.id) {
        return doc(firestore, COLLECTION, where.id);
      }
      let sourcesFilters = [];
  
      q = Object.keys(where).reduce((q, key) => {
        console.log(q, key, where[key]);
  
        switch (key) {
          case 'source':
            sourcesFilters.push([where[key]]);
            return q;
          case 'campaign':
            const filteredCampaigns = campaigns.filter(campaign => campaign.name === where[key]);
            const sourceNamesByCampaign = filteredCampaigns.length ? filteredCampaigns[0].sources : [];
            sourcesFilters.push(sourceNamesByCampaign);
            return q;
  
          case 'campaignType':
            const filteredCampaignsByType = campaigns.filter(campaign => campaign.campaignType === where[key]);
            const allSourceNamesByCampaignType = filteredCampaignsByType.flatMap(campaign => campaign.sources).filter(source => !!source);
            sourcesFilters.push(allSourceNamesByCampaignType);
            console.log('allSourceNamesByCampaignType', allSourceNamesByCampaignType);
            return q;
        }
  
        return Array.isArray(where[key])
          ? query(q, whereFunc(key, "in", where[key]))
          : query(q, whereFunc(key, "==", where[key]));
      }, q);
  
      if (sourcesFilters.length) {
        const intersection = sourcesFilters.reduce((a, b) => a.filter(c => b.includes(c)));
        if (intersection.length === 0) {
          // Return a query that will return zero results
          return query(collection(firestore, COLLECTION), whereFunc("nonexistentField", "==", "nonexistentValue"));
        }
  
        q = query(q, whereFunc('source', "in", intersection));
      }
      console.log(sourcesFilters);
    }
  
    const { truthyFilters } = this.state;
    if (truthyFilters === "isMobile") {
      q = query(q, whereFunc("isMobile", "==", true));
    } 
    else if (truthyFilters === "isSocial") {
      q = query(q, whereFunc("isSocial", "==", true));
    }
    else if (truthyFilters === "isWebsite") {
      q = query(q, whereFunc("isMobile", "==", false));
    }
  
    if (!reset) {
      if (page > dataSet.page) {
        q = query(q, orderByFunc(orderBy, order), startAfterFunc(startAfter));
      } else if (page < dataSet.page) {
        q = query(
          q,
          orderByFunc(orderBy, order === "asc" ? "desc" : "asc"),
          startAfterFunc(endBefore)
        );
      } else {
        q = query(q, orderByFunc(orderBy, order));
      }
    } else {
      q = query(q, orderByFunc(orderBy, order));
    }
  
    q = query(q, limit(rowsPerPage));
  
    return q;
  };
  

  registerSnapshotListener = (
    { order, orderBy, rowsPerPage, page, where },
    reset
  ) => {
    const { receiveSnapshot } = this.props;
    const {
      [COLLECTION]: { page: currentPage }
    } = this.props;

    return onSnapshot(
      this.getQueryWithSearchCriteria(
        order,
        orderBy,
        rowsPerPage,
        page,
        where,
        reset
      ),
      snapshot => {
        if (snapshot.id) {
          const row = { ...snapshot.data(), id: snapshot.id };
          receiveSnapshot([row], snapshot, snapshot);
        } else {
          const snapshots = this.maybeReverseSnapshots(
            snapshot,
            page,
            currentPage,
            reset
          );
          const results = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
          const endBefore = snapshots[0];
          const startAfter = snapshots[results.length - 1];
          receiveSnapshot(results, endBefore, startAfter);
        }
      }
    );
  };

  registerSyncDiagnosticsListener = () => {
    const firestore = this.props.firebase.firestore;
    const syncDiagnosticsRef = doc(firestore, "syncDiagnostics", "klaviyo");
  
    return onSnapshot(syncDiagnosticsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const syncData = docSnapshot.data();
        console.log(syncData);
        this.setState({ klaviyoStatus: syncData.status });
      } else {
        console.log("No data found for sync diagnostics.");
      }
    }, (error) => {
      console.error("Error listening to sync diagnostics changes: ", error);
    });
  };
  
  handleSyncDiagnosticsUpdate = (syncData) => {
    console.log("Updated Sync Diagnostics Data: ", syncData);
    this.setState({ klaviyoStatus: syncData.status });
  };
  

  registerNewLeadsListener = () => {
    const userIsRole = ["support"].includes(this.props.user.role);
    const firestore = this.props.firebase.firestore;
    const uid = this.props.user.uid;
    const q =
      this.props.user.isAdmin || userIsRole
        ? query(
            collection(firestore, "leads"),
            whereFunc("disposition", "==", "New")
          )
        : query(
            collection(firestore, "leads"),
            whereFunc("uid", "==", uid),
            whereFunc("disposition", "==", "New")
          );
    return onSnapshot(q, snapshot => {
      this.setState({ newLeadsCount: snapshot.size });
    });
  };

  registerUnassignedLeadsListener = () => {
    const userIsRole = ["support"].includes(this.props.user.role);
    if (this.props.user.isAdmin || userIsRole) {
      const firestore = this.props.firebase.firestore;
      const q = query(
        collection(firestore, "leads"),
        whereFunc("uid", "==", null)
      );
      return onSnapshot(q, snapshot => {
        this.setState({ unassignedCount: snapshot.size });
      });
    }
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    if (this.unsubscribeListener) {
      this.unsubscribeListener();
    }

    if (this.state.isSalesMode) {
      let where = searchCriteria.where || {};
      where['uid'] = this.props.user.uid;
      searchCriteria.where = where;
    }

    this.unsubscribeListener = this.registerSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleNext = () => {
    this.setState({ loading: true });

    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where, startAfter },
      reset,
      receiveSnapshot
    } = this.props;

    let q = this.getQueryWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      where,
      reset
    );

    q = query(q, startAfterFunc(startAfter));
    q = query(q, limit(rowsPerPage));

    getDocs(q).then(snapshot => {
      const results = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshot.docs[0];
      const startAfter = snapshot.docs[results.length - 1];
      this.setState({ loading: false, hasMore: results.length !== 0 });
      receiveSnapshot(
        [...this.props.leads.data, ...results],
        endBefore,
        startAfter
      );
    });
  };

  resetWhere = () => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage },
      setSearchCriteria
    } = this.props;
    const searchCriteria = { order, orderBy, page, rowsPerPage, where: null };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null, emptyMessage: null });
  };

  handleAutoCompleteChange = (event, value) => {
    if (!value) {
      return;
    }
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    const newWhere = value ? { ...where, id: value.id } : where;
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null, emptyMessage: null });
  };

  handleDispositionChange = event => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    const newWhere = { ...where, disposition: event.target.value };
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null, emptyMessage: null });
  };

  handleGroupChange = event => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    const newWhere = { ...where, group: event.target.value };
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null, emptyMessage: null });
  };

  handleUnassignedLeadsClick = () => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    const newWhere = { ...where, uid: null };
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({ row: null, emptyMessage: null });
  };

  handleCampaignChange = campaign => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    let newWhere = campaign ? { ...where, campaign: campaign } : where;
    if (campaign === null) {
      newWhere = _.omit(newWhere, ["campaign"]);
    }
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({
      row: null,
      emptyMessage: "This campaign contains no leads."
    });
  };

  handleCampaignTypeChange = campaignType => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    let newWhere = campaignType ? { ...where, campaignType: campaignType } : where;
    if (campaignType === null) {
      newWhere = _.omit(newWhere, ["campaignType"]);
    }
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({
      row: null,
      emptyMessage: "This campaign type contains no leads."
    });
  };

  handleRowClick = async (row, isAlreadySelected) => {
    this.setState({ row: isAlreadySelected ? null : row });
  };

  handleSetOpen = open => {
    this.setState({ open });
  };

  openLeadDialog = open => () => {
    this.setState({ leadDialogOpen: open });
  };

  handleTruthyFilters = (event, truthyFilters) => {
    this.setState({ truthyFilters, row: null }, () => {
      const {
        [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
        setSearchCriteria
      } = this.props;
      const searchCriteria = {
        order,
        orderBy,
        page,
        rowsPerPage,
        where
      };
      this.handleRetrieveData(searchCriteria);
      setSearchCriteria(searchCriteria);
    });
  };

  handleExportClick = () => {
    this.setState({ exportDialogOpen: true });
  };

  handleSourceChange = source => {
    const {
      [COLLECTION]: { page, order, orderBy, rowsPerPage, where },
      setSearchCriteria
    } = this.props;
    let newWhere = source ? { ...where, source: source } : where;
    if (source === null) {
      newWhere = _.omit(newWhere, ["source"]);
    }
    const searchCriteria = {
      order,
      orderBy,
      page,
      rowsPerPage,
      where: newWhere
    };
    this.handleRetrieveData(searchCriteria);
    setSearchCriteria(searchCriteria);
    this.setState({
      row: null,
      emptyMessage: "This source contains no leads."
    });
  };

  submitLead = lead => {
    const note = lead.note;
    delete lead.note;
    const firestore = this.props.firebase.firestore;

    addDoc(collection(firestore, "leads"), {
      ...lead,
      createdAt: new Date(),
      uid: this.props.user.uid,
      disposition: "New",
      url: window.location.href,
      isMobile: false
    }).then(async doc => {
      const row = { id: doc.id, ...lead };
      this.setState({ row, leadDialogOpen: false });
      if (note) {
        await addDoc(collection(firestore, "notes"), {
          text: note,
          createdAt: new Date(),
          leadId: doc.id,
          uid: this.props.user.uid
        });
      }
    });
  };

  getFormattedPhone = phone => {
    try {
      const number = this.phoneUtil.parseAndKeepRawInput(phone, "US");
      return this.phoneUtil.format(number, PhoneNumberFormat.E164);
    } catch (err) {
      return phone;
    }
  };

  render() {
    const { leads, metadata, user, classes } = this.props;
    const {
      truthyFilters,
      row,
      newLeadsCount,
      unassignedCount,
      exportDialogOpen,
      emptyMessage,
      loading,
      hasMore,
      klaviyoStatus
    } = this.state;
    
    return (
      <div className={classes.root}>
        <div className={classes.page}>
          {newLeadsCount > 0 && (
            <Alert severity="info" className={classes.alert}>
              <b>New Leads</b> - You currently have{" "}
              <Link
                onClick={() =>
                  this.handleDispositionChange({ target: { value: "New" } })
                }
              >
                {newLeadsCount} leads
              </Link>{" "}
              with no assigned disposition.
            </Alert>
          )}
          {unassignedCount > 0 && (
            <Alert severity="info" className={classes.alert}>
              <b>Unassigned Leads</b> - There are currently{" "}
              <Link onClick={this.handleUnassignedLeadsClick}>
                {unassignedCount} unassigned leads
              </Link>
              .
            </Alert>
          )}
          <div className={classes.titleBar}>
            <Typography variant="h4">Leads</Typography>
            <div className={classes.actions}>
            {this.props.user.isAdmin && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.toggleSalesMode}
              >
                {this.state.isSalesMode ? "Switch to Admin View" : "Switch to Sales View"}
              </Button>
            )}
            <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.sendToKlaviyo}
                disabled={klaviyoStatus && klaviyoStatus != "done"}
              >
                Export to Klaviyo {(klaviyoStatus && klaviyoStatus != 'done') && (`(${klaviyoStatus})`)}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.openLeadDialog(true)}
              >
                Create Lead
              </Button>
            </div>
            {this.state.leadDialogOpen && (
              <LeadDialog
                open={this.state.leadDialogOpen}
                handleClose={this.openLeadDialog(false)}
                onSubmit={this.submitLead}
                metadata={metadata}
              />
            )}
          </div>
          <div className={classes.toolbar}>
            <div className={classes.search}>
              <AutoComplete
                index={process.env.REACT_APP_ALGOLIA_LEADS_INDEX}
                publicKey={this.props.user.algoliaPublicKey}
                onChange={this.handleAutoCompleteChange}
                metadata={metadata}
              />
              <AutoCompleteCampaignType
                onChange={this.handleCampaignTypeChange}
                value={
                  leads.where && leads.where.campaignType ? leads.where.campaignType : ""
                }
              />
              <AutoCompleteCampaign
                onChange={this.handleCampaignChange}
                value={
                  leads.where && leads.where.campaign ? leads.where.campaign : ""
                }
              />
              <AutoCompleteSource
                onChange={this.handleSourceChange}
                value={
                  leads.where && leads.where.source ? leads.where.source : ""
                }
              />
              {metadata && metadata.dispositions && (
                <div>
                  <TextField
                    id="disposition"
                    label="Disposition"
                    variant="outlined"
                    placeholder="Filter Disposition"
                    value=""
                    select
                    onChange={this.handleDispositionChange}
                    className={classes.filterDisposition}
                  >
                    {Object.keys(metadata.dispositions).map(e => {
                      return (
                        <MenuItem
                          key={e}
                          value={e}
                          style={{
                            color: metadata.dispositions[e].color,
                            backgroundColor:
                              metadata.dispositions[e].backgroundColor
                          }}
                        >
                          {e}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}

              {metadata && metadata.groups && (
                <div>
                  <TextField
                    id="group"
                    label="Group"
                    variant="outlined"
                    placeholder="Filter Group"
                    value=""
                    select
                    onChange={this.handleGroupChange}
                    className={classes.filterDisposition}
                  >
                    {metadata.groups.map(e => {
                      return (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
              { false /* disabled*/ && (
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                value={truthyFilters}
                onChange={this.handleTruthyFilters}
                aria-label="mobile"
                exclusive
              >
                <ToggleButton
                  className={classes.toggleButton}
                  value="isWebsite"
                  aria-label="website"
                >
                  <img
                    style={{ opacity: 0.38 }}
                    src={Google}
                    alt="PC Campaign"
                  />
                </ToggleButton>
                <ToggleButton
                  className={classes.toggleButton}
                  value="isMobile"
                  aria-label="mobile"
                >
                  <PhoneIphoneIcon />
                </ToggleButton>
                <ToggleButton
                  className={classes.toggleButton}
                  value="isSocial"
                  aria-label="social"
                >

                  <FacebookIcon />
                </ToggleButton>
              </ToggleButtonGroup>
                )
              }
              <Button
                className={classes.filterReset}
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.resetWhere}
                disabled={!leads.where}
              >
                Reset Filters
              </Button>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.handleExportClick}
              >
                export
              </Button>
            </div>
          </div>
          <div className={classes.container}>
            <div
              className={classNames(classes.tableContainer, {
                [classes.tableContainerZoom]: Boolean(row)
              })}
            >
              <DataTable
                title="Leads"
                data={{ ...leads }}
                emptyMessage={emptyMessage}
                onSearchTextChange={this.handleAutocompleteChange}
                onFilterMenuItemClick={() => {}}
                onRetrieveData={this.handleRetrieveData}
                onRowClick={this.handleRowClick}
                onNext={this.handleNext}
                hasMore={hasMore}
                loading={loading}
                metadata={metadata}
                user={user}
                distanceBottom={1500}
              />
            </div>
            {row && (
              <LeadPanel
                lead={row}
                onClose={() => this.setState({ row: null })}
              />
            )}
            {exportDialogOpen && (
              <LeadExportDialog
                open={this.state.exportDialogOpen}
                handleClose={() => this.setState({ exportDialogOpen: false })}
                metadata={metadata}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const condition = user => !!user;

export default compose(
  withLayout,
  withStyles(styles),
  withAuthorization(condition),
  connect(
    state => ({
      user: state.user,
      metadata: state.metadata,
      leads: state.leads
    }),
    {
      setSearchCriteria,
      setColumnVisibility,
      receiveSnapshot: receiveLeadsSnapshot
    }
  )
)(LeadsPage);
