import React from "react";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withLayout } from "../../Layout";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

const Deals = () => {
  return <div>Deals report, to be done...</div>;
};

export default compose(
  withLayout,
  withStyles(styles),
  withAuthorization(user => !!user)
)(Deals);
